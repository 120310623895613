import Select from '../../../../Forms/Fields/select'
import CMSToggle from '../../../../Forms/Fields/toggle'
import CMSInput from '../../../../Forms/Fields/input'

const AdvanceFilterFields = ({ formFieldValue, setFormFieldValue, field, updateFilter }) => {
  const fieldType = field?.component_name

  switch (fieldType) {
    case 'select':
      return (
        <Select
          data={field}
          id={field?.field_id}
          formFieldValue={formFieldValue}
          setFormFieldValue={setFormFieldValue}
          value={formFieldValue[field?.field_id] ? formFieldValue[field?.field_id] : null}
          inputLabel={formFieldValue[`${field?.field_id}_label`] ? formFieldValue[`${field?.field_id}_label`] : null}
          onChangeValue={updateFilter}
          isClearable={true}
          noCache={true}
          onChangeAction={(a, actions, field, v, label) => {
            // setFormFieldValue({
            //   ...formFieldValue,
            //   [field.field_id]: v,
            //   [`${field.field_id}`]: label,
            // })
          }}></Select>
      )
    case 'toggle_switch':
      return (
        <CMSToggle
          data={field}
          id={field?.field_id}
          formFieldValue={formFieldValue}
          setFormFieldValue={setFormFieldValue}
          value={formFieldValue[field?.field_id]}
          inputLabel={formFieldValue[`${field?.field_id}_label`]}
          onChangeValue={updateFilter}></CMSToggle>
      )
    default:
      return (
        <CMSInput
          data={field}
          id={field?.field_id}
          formFieldValue={formFieldValue}
          setFormFieldValue={setFormFieldValue}
          value={formFieldValue[field?.field_id]}
          inputLabel={formFieldValue[`${field?.field_id}_label`]}
          onValueChange={updateFilter}></CMSInput>
      )
  }
}
export default AdvanceFilterFields
