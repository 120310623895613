import moment from 'moment'

import { getTabNameForLogs } from '../../../../Pages/Logs/logHelper'
import { primary_color } from '../../../../Helper/uiHelper'

const getValue = (data) => {
  return data?.name || data?.location?.name
}

const ShowDifference = ({ data, original_data, updated_data, onExpand, difference }) => {
  const { status, ...bookings } = original_data
  return (
    <div>
      <div className='permission-table'>
        <div className='mb-2 cursor-pointer' onClick={() => onExpand()}>
          <i class='fa-sharp fa-solid fa-arrow-left  m-3'></i>
          Go Back
        </div>
        <div className={`permission-header`} style={{ color: primary_color }}>
          <div className='d-flex align-items-center'>
            <div style={{ flex: 1 }}>
              {'Transaction failed while '}
              <span className='font-weight-bold'>{data?.updated_by}</span>
              {` book the locker`}
            </div>
            {data?.updated_at && <div>{moment.utc(data?.updated_at).format('MMM D  YYYY, HH:mm')}</div>}
          </div>
        </div>
        <div className='px-5 py-4'>
          <div className='mb-3'>
            <div className='font-weight-bold body2' style={{ color: primary_color }}>
              Reason
            </div>
            <div className='subtitle1'>{status}</div>
          </div>
        </div>
        <hr></hr>
        <div className='body1 font-weight-bold px-5' style={{ color: primary_color }}>
          Bookings
        </div>
        <hr></hr>
        <div className='px-5 py-4'>
          {Object.keys(bookings)?.map((item) => {
            return (
              <>
                <div className='mb-3'>
                  <div className='font-weight-bold body2' style={{ color: primary_color }}>
                    Locker Number
                  </div>
                  <div className='subtitle1'>{bookings[item]?.item_id_label}</div>
                </div>
                <div className='mb-3'>
                  <div className='font-weight-bold body2' style={{ color: primary_color }}>
                    Block
                  </div>
                  <div className='subtitle1'>{bookings[item]?.location_name}</div>
                </div>
                <div className='mb-3'>
                  <div className='font-weight-bold body2' style={{ color: primary_color }}>
                    Student
                  </div>
                  <div className='subtitle1'>{`${bookings[item]?.first_name} ${bookings[item]?.last_name}`}</div>
                </div>
                <hr></hr>
              </>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const TransactionFailed = ({ data, onExpand, expandedId, parentPageName }) => {
  let updated_data = data?.updated_data && JSON.parse(data?.updated_data)
  let original_data = data?.original_data && JSON.parse(data?.original_data)

  let tab_name = getTabNameForLogs(parentPageName, data?.tab_name)

  if (expandedId) {
    return (
      <ShowDifference
        data={data}
        onExpand={onExpand}
        updated_data={updated_data}
        original_data={original_data && JSON.parse(original_data)}></ShowDifference>
    )
  }
  return (
    <div className='d-flex align-items-center p-3' style={{ borderBottom: '1px solid #e0e0e0' }}>
      <div>
        <i
          class='fa-solid fa-arrow-up-right-from-square mr-2 cursor-pointer'
          style={{ color: primary_color }}
          onClick={() => {
            onExpand(data?.id)
          }}></i>
      </div>
      <div style={{ flex: 1 }}>
        {'Transaction failed while '}
        <span className='font-weight-bold'>{data?.updated_by}</span>
        {` book the locker`}
      </div>
      {data?.updated_at && <div>{moment.utc(data?.updated_at).format('MMM D  YYYY, HH:mm')}</div>}
    </div>
  )
}

export default TransactionFailed
