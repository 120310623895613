import { AvForm } from 'availity-reactstrap-validation'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import { v4 as uuidv4 } from 'uuid'

import Select from '../../CMSComponent/Forms/Fields/select'
import Tab from '../../CMSComponent/tab'
import { checkPermission, getConnectTemplateReduxDataAfterInsert, getUserSecurityOverviewDropdown } from '../../helper'
import { addDataStatusClear, addDataStatusSet, setConnectTemplateDataRedux } from '../../Redux/Reducers/CMS'
import { setQueue } from '../../Redux/Reducers/queue'
import { increaseProgressDataByTabname } from '../../progressHelper'

const getColumnNameBySourceTable = (currentTabData, sourceTable) => {
  const fields = currentTabData?.component_call_form?.[0]?.main_grid?.component_call_cols
    ?.filter((col) => col?.component_name === 'text_data_col')
    ?.filter((col) => col?.field_id !== 'id')?.[0]

  return `${fields?.field_id}_label`
}

const getColumnNameBySourceTableDynamic = (currentTabData) => {
  const fields = currentTabData?.component_call_form?.[0]?.main_grid?.component_call_cols
    ?.filter((col) => col?.component_name === 'text_data_col')
    ?.filter((col) => col?.field_id !== 'field_id')?.[0]

  return `${fields?.field_id}_label`
}

const getDropdownField = (sourceTable) => {
  return {
    component_type: 'fields',
    component_name: 'select',
    name: sourceTable,
    is_layout: 1,
    row_index: 1,
    block_index: 1,
    col: null,
    section_rowspan: null,
    field_setting: {
      import: null,
      export: null,
      depend_fields: [],
      field: 'dropdown',
    },
    cms_value: null,
    permission: 'no_permission',
    field_id: `${sourceTable}_id`,
    cms_key: null,
    cms_tooltip: null,
    component_call_data_connection: {
      component_type: 'data_connection',
      component_name: 'template_data',
      source_table: sourceTable,
      fields: 'id,name',
      clause_data: null,
    },
    selected_value: null,
    disabled: false,
    hidden: false,
    search: false,
    hide_from_grid: false,
    background_color: false,
    component_call_validation: [
      {
        component_type: 'validatation',
        component_name: 'inline_validatation',
        validation_type: 'required',
        message: 'Please complete',
      },
    ],
    component_call_actions: null,
    component_call_fields: null,
    format: {
      size: 12,
      searchable: false,
      is_multi_select: true,
    },
  }
}

const SecurityTab = ({
  content,
  sourceTable = null,
  noTitle = false,
  tabName,
  fullPage = false,
  hideButtons = false,
  pageName,
  parentPageName,
  isDatabaseDropdownVisible = false,
  isModel,
  hideEditIcon,
  gridData,
  cmsTabKey,
  currentTabData,
  addDataDetail,
  setAddDataDetail,
  activeTabNumber,
  isTab = false,
}) => {
  const dispatch = useDispatch()

  const [selectedValue, setSelectedValue] = useState([])

  const connectTemplateDataRedux = useSelector((state) => state.CMS.connectTemplateDataRedux)
  const parentTableDataInRedux = useSelector((state) => state.CMS.parentTableData)
  const currentRow = useSelector((state) => state.CMS.currentRow)
  const domain_id = useSelector((state) => state.CMS.selected_domain_id)

  const userSecurityAccessData = getUserSecurityOverviewDropdown()

  const parent_table = parentTableDataInRedux && parentTableDataInRedux?.[0]?.source_table
  const parent_table_row_id = currentRow[0]?.id

  const onAddData = async (value, label) => {
    let data = {}
    let dataForApi = {}
    let dataToUpdate

    selectedValue?.map((item) => {
      if (sourceTable === 'permissions') {
        data['name'] = item?.label
        data['temporary_id'] = uuidv4()
        dataToUpdate = getConnectTemplateReduxDataAfterInsert(
          connectTemplateDataRedux,
          parentPageName,
          pageName,
          sourceTable,
          currentRow,
          data,
          parent_table ? true : false
        )
        data['id'] = item?.value
        dataForApi[sourceTable] = dataForApi[sourceTable] ? [...dataForApi[sourceTable], data] : [data]
      } else {
        data[getColumnNameBySourceTable(currentTabData)] = item?.label
        data['temporary_id'] = uuidv4()
        dataToUpdate = getConnectTemplateReduxDataAfterInsert(
          connectTemplateDataRedux,
          parentPageName,
          pageName,
          sourceTable,
          currentRow,
          data,
          parent_table ? true : false
        )

        data[getColumnNameBySourceTable(currentTabData)?.replace('_label', '')] = item?.value
        dataForApi[sourceTable] = dataForApi[sourceTable] ? [...dataForApi[sourceTable], { ...data }] : [{ ...data }]
      }
    })
    increaseProgressDataByTabname(currentTabData?.name)

    dispatch(
      setQueue({
        url: 'insert-repeating-grid-data',
        payload: {
          data: {
            data: dataForApi,
            is_nested: true,
            parent_table,
            parent_table_row_id,
            domain_id,
            tab_name: currentTabData?.name,
          },
        },
        parent_table,
        parent_table_row_id,
        source_table: sourceTable,
      })
    )
    dispatch(setConnectTemplateDataRedux(dataToUpdate))
    dispatch(addDataStatusSet())
    setSelectedValue([])
    setTimeout(() => {
      dispatch(addDataStatusClear())
    }, 2000)
  }

  if (userSecurityAccessData && userSecurityAccessData[sourceTable?.replace('security_', '')] === 'grant_all')
    return (
      <div style={{ marginTop: '5rem', fontSize: '1.2rem', textAlign: 'center' }}>All have all access to {sourceTable?.replace('security_', '')}</div>
    )
  return (
    <>
      {sourceTable !== 'security_global' && checkPermission('add', pageName, parentPageName, true) && (
        <AvForm style={{ padding: '1rem', display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <Select
              parentPageName={parentPageName}
              pageName={pageName}
              data={getDropdownField(sourceTable?.replace('security_', ''))}
              onChangeAction={(a, b, c, value, label) => setSelectedValue(value)}
              value={selectedValue}
              // inputLabel={selectedValue?.label}
              isFieldUsedDirectly
              parentRowIdRequiredInPayload={true}
            />
          </div>
          <div>
            <Button
              color='primary'
              onClick={() => onAddData()}
              style={{ marginTop: '2rem', marginLeft: '1rem' }}
              disabled={selectedValue?.length > 0 ? false : true}>
              Add
            </Button>
          </div>
        </AvForm>
      )}

      <Tab
        noTitle={noTitle}
        hideButtons={hideButtons}
        content={content}
        tabName={tabName}
        sourceTable={sourceTable}
        pageName={pageName}
        parentPageName={parentPageName}
        fullPage={fullPage}
        isDatabaseDropdownVisible={isDatabaseDropdownVisible}
        isModel={isModel}
        gridData={gridData}
        hideEditIcon={hideEditIcon}
        showMore={false}
        cmsTabKey={cmsTabKey}
        currentTabData={currentTabData}
        addDataDetail={addDataDetail}
        setAddDataDetail={setAddDataDetail}
        activeTabNumber={activeTabNumber}
        isTab={true}
      />
    </>
  )
}

export default SecurityTab
