import { AvField, AvForm } from 'availity-reactstrap-validation'
import { useEffect, useState } from 'react'
import { Button, Collapse, ListGroup, ListGroupItem } from 'reactstrap'

import CMSRichText from '../richText'
import { request } from '../../../Redux/Sagas/requests/api'
import { updatePublicPageCMS } from '../../../Helper/publicPageHelper'
import { cms, tidyName } from '../../../helper'

const CMSHeroBanner = ({ data, widget_uuid, toggleModal }) => {
  const [state, updateState] = useState({})
  const [formData, setFormData] = useState([])
  const [formFields, setFormFields] = useState()

  const [isCollapseOpen, setIsCollapseOpen] = useState('edit_cms_hero_banner')

  const form = data?.find((item) => item.component_name === 'form')?.forms?.[0]

  let isDataArray = false

  if (Array.isArray(data)) {
    isDataArray = true
  }

  useEffect(() => {
    if (isDataArray) {
      const newState = data
        ?.map((item) => {
          let type = 'rich_text'
          if (item?.component_name === 'button_text') {
            type = 'input'
          }
          if (item?.component_name === 'action_url' || item?.component_name === 'form' || !item?.component_name) {
            return
          }
          return {
            component_name: item?.component_name,
            value: item?.value,
            uuid: item?.uuid,
            type,
            title: tidyName(item?.component_name),
          }
        })
        ?.filter((item) => item)
      setFormData(newState)
      if (form?.component_call_fields?.length > 0) {
        let obj = {}
        form?.component_call_fields?.map((item) => {
          obj[item?.cms_key] = cms(item?.cms_key)
        })
        setFormFields(obj)
      }
    } else {
      let obj = {
        heading: data?.heading,
        text: data?.text,
        button_text: data?.button_text,
        sub_heading: data?.sub_heading,
      }
      setFormData({
        ...obj,
      })
    }
  }, [data])

  const onSave = () => {
    let payload = {
      uuid: widget_uuid,
      data: formData,
      form: formFields,
    }
    updatePublicPageCMS(payload, toggleModal)
  }

  const onChangeValue = (uuid, v) => {
    setFormData((od) => {
      let newData = od?.map((oldData) => {
        if (oldData?.uuid !== uuid) {
          return oldData
        } else {
          return {
            ...oldData,
            value: v,
          }
        }
      })
      return newData
    })
  }

  return (
    <div>
      <div className='d-flex justify-conent-between content-widget'>
        <div className='font-weight-bold'>Edit Content</div>
      </div>
      <hr></hr>
      <AvForm>
        <ListGroup>
          <ListGroupItem id='edit_cms_hero_banner' className='editCMSMainTitle font-weight-bold'>
            Edit Text
          </ListGroupItem>
          <Collapse isOpen={isCollapseOpen === 'edit_cms_hero_banner'} className='p-4'>
            {isDataArray &&
              formData?.map((item) => {
                if (item?.type === 'rich_text') {
                  return (
                    <CMSRichText
                      data={{ field_id: item?.uuid }}
                      id={item?.uuid}
                      title={item?.title}
                      value={item?.value}
                      onChange={(v) => {
                        onChangeValue(item?.uuid, v)
                        // setFormData((val) => ({
                        //   ...val,
                        //   heading: v,
                        // }))
                      }}
                    />
                  )
                } else {
                  return (
                    <AvField
                      name={item?.uuid}
                      label='Button Text'
                      value={item?.value}
                      onChange={(e) => onChangeValue(item?.uuid, e.target.value)}></AvField>
                  )
                }
              })}
            {!isDataArray && (
              <>
                {formData?.heading && (
                  <div className='p-1 mt-2 mb-2'>
                    <div>
                      <CMSRichText
                        data={{ field_id: 'content_heading' }}
                        id='content_heading'
                        title={'Heading'}
                        value={formData?.heading}
                        onChange={(v) => {
                          setFormData((val) => ({
                            ...val,
                            heading: v,
                          }))
                        }}
                      />
                    </div>
                  </div>
                )}
                {formData?.sub_heading && (
                  <div className='p-1 mt-2 mb-2'>
                    <div>
                      <CMSRichText
                        data={{ field_id: 'content_sub_heading' }}
                        id='content_sub_heading'
                        title={'Sub Heading'}
                        value={formData?.sub_heading}
                        onChange={(v) => {
                          setFormData((val) => ({
                            ...val,
                            sub_heading: v,
                          }))
                        }}
                      />
                    </div>
                  </div>
                )}
                {formData?.text && (
                  <div className='p-1 mt-2 mb-2'>
                    <div>
                      <CMSRichText
                        data={{ field_id: 'content_text' }}
                        id='content_text'
                        title={'Sub Heading'}
                        value={formData?.text}
                        onChange={(v) => {
                          setFormData((val) => ({
                            ...val,
                            text: v,
                          }))
                        }}
                      />
                    </div>
                  </div>
                )}
                {formData?.button_text && (
                  <div className='p-1 mt-2 mb-2'>
                    <div>
                      <AvField
                        name='button_text'
                        label='Button Text'
                        value={formData?.button_text}
                        onChange={(e) =>
                          setFormData((val) => ({
                            ...val,
                            button_text: e.target.value,
                          }))
                        }></AvField>
                    </div>
                  </div>
                )}
              </>
            )}
          </Collapse>
          <ListGroupItem className='editCMSMainTitle font-weight-bold'>Edit Field</ListGroupItem>
          {form?.component_call_fields?.map((item) => {
            return (
              <div className='p-1 mt-2 mb-2'>
                <div>
                  <AvField
                    name={item?.field_id?.replaceAll('.', '_')}
                    label={cms(item?.cms_key)}
                    value={formFields?.[item?.cms_key]}
                    onChange={(e) => {
                      setFormFields((values) => {
                        let obj = {
                          ...values,
                        }
                        obj[item.cms_key] = e.target.value
                        return obj
                      })
                    }}></AvField>
                </div>
              </div>
            )
          })}
        </ListGroup>
      </AvForm>
      <div className='d-flex justify-content-end mt-4'>
        <Button
          outline
          color='danger'
          className='mr-2'
          onClick={(e) => {
            e.stopPropagation()
            toggleModal()
          }}>
          Discard
        </Button>
        <Button color='info' onClick={() => onSave()}>
          Save
        </Button>
      </div>
    </div>
  )
}

export default CMSHeroBanner
