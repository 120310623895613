import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'

import { getDependDataPayload } from '../../../CMSComponent/Forms/Fields/select'
import { getLocalStorageItem } from '../../../Helper/cookieHelper'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import { request } from '../../../Redux/Sagas/requests/api'
import { RiDoorLine } from 'react-icons/ri'
import LockerQuestion from './lockerQuestion'
import { primary_color } from '../../../Helper/uiHelper'
import Loader from 'react-loaders'
import useMediaQuery from '../../../CustomHook/useMediaQuery'

const createDefaultAnswersNew = (questions) => {
  const obj = {}
  questions.forEach((e) => (obj[e.uuid] = e.answer || ''))
  return obj
}

const filterAllLocker = (lockers) => {
  const data = JSON.parse(JSON.stringify(lockers))

  const checkCondition = (item) => {
    if (item?.completed || item?.reserved || item?.display == 0) {
      return true
    } else {
      return false
    }
  }

  const maxLength = Math.max(...data.map((row) => row.length))

  const indexToRemove = []

  for (let i = 0; i < maxLength; i++) {
    const shouldRemoveIndex = data.every((row) => {
      return checkCondition(row[i])
    })

    if (shouldRemoveIndex) {
      indexToRemove?.push(i)
    }
  }
  const newData = []
  data.forEach((row) => {
    // return row.splice(i, 1)
    const newRow = []
    row.map((item, index) => {
      if (!indexToRemove?.includes(index)) {
        newRow.push(item)
      }
    })
    newData.push(newRow)
  })

  return newData
}

const LockerBooking = ({
  data,
  formFieldValue = {},
  setFormFieldValue,
  parentData,
  form,
  validationError,
  setValidationError,
  allFormsData,
  unitData,
  setUnitData,
  formIndex,
  setFormData,
  disabledEmptyLocker,
  isBookingPage,
}) => {
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery('(min-width:768px)')

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const payment_id = searchParams.get('payment_id')
  const isEditingLocker = searchParams.get('edit_booking')
  const [selectedLocker, setSelectedLocker] = useState()
  const [lockers, setLockers] = useState([])
  const [filteredLocker, setFilteredLocker] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [questions, setQuestions] = useState([])
  const lockerColWidth = isDesktop ? 131.3 : 99.2
  const [lockerColCount, setLocakerCoCount] = useState(0)

  const [showAllLocker, setShowAllLocker] = useState(false)

  useEffect(() => {
    if (unitData?.length && unitData[formIndex]) setQuestions(unitData[formIndex].questions)
  }, [JSON.stringify(unitData), formIndex])

  const dependent_value = formFieldValue?.[data?.component_call_data_connection?.source_field]

  //* Convert API Data into vertical Array
  const displayVertically = (arr, rowCount) => {
    const output = arr.reduce((rows, item, index) => {
      const rowIndex = index % rowCount
      rows[rowIndex] = rows[rowIndex] || []
      rows[rowIndex].push(item)
      return rows
    }, [])

    return output
  }

  const fetchData = async (payload) => {
    setIsLoading(true)
    try {
      const response = await request('get-template-data-depend', 'POST', { ...payload })
      if (response?.status === 200 || response?.status === 202) {
        // const new_lockers = []
        const formattedData = displayVertically(response?.data?.data, response?.data?.lockerHeight)

        const new_lockers = formattedData?.map((item) => {
          return item?.map((dataItem) => {
            return {
              id: dataItem?.id,
              reserved: dataItem?.completed || dataItem?.reserved ? true : false,
              display: dataItem?.display,
              item_name: dataItem?.name,
              price: dataItem?.rate,
              questions: response?.data?.questions,
            }
          })
        })

        setLocakerCoCount(formattedData[0]?.length)
        setLockers(new_lockers)

        setFilteredLocker(filterAllLocker(new_lockers))
      }
    } catch (e) {
      console.error('Error in get-template-data-depend : ', e)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!dependent_value) {
      setLockers([])
      setFilteredLocker([])
      return
    }
    let payload = getDependDataPayload(form?.component_call_fields, data?.component_call_data_connection, dependent_value)
    fetchData(payload)
  }, [dependent_value])
  const onChangeValue = (reserved, value, item) => {
    let storage_timer = getLocalStorageItem('timer') && JSON.parse(getLocalStorageItem('timer'))
    let current_storage_timer = storage_timer?.[parentData?.id]
    const selected_items = allFormsData?.map((forms) => forms[data?.field_id])
    if (reserved && !unitData.map((e) => e.item_id).includes(item?.id))
      return dispatch(
        setNotification({
          type: 'error',
          message: 'This locker is already reserved.',
        })
      )
    if (selected_items?.includes(item?.id)) {
      dispatch(
        setNotification({
          type: 'error',
          message: 'This locker is already selected.',
        })
      )
      return
    }
    let itemToCheck = {
      ...item,
    }
    if (allFormsData?.[formIndex]) {
      itemToCheck = {
        ...itemToCheck,
        item_date_time_id: allFormsData?.[formIndex]?.item_date_time_id,
        location_id: allFormsData?.[formIndex]?.location_id,
        category_id: allFormsData?.[formIndex]?.category_id,
      }
    }
    let payload = {
      items: [itemToCheck],
      // items: [item?.id],
      session_id: current_storage_timer?.session_id,
      uuid: formFieldValue?.uuid,
      payment_id: payment_id && parseInt(payment_id),
    }
    request('check-item-availability', 'POST', payload).then((res) => {
      if (res.status === 200 || res?.status === 202) {
        setSelectedLocker(value)
        setValidationError(false)
        if (questions?.length === 0) {
          setQuestions(item?.questions)
        }
        let updatedValue = {
          ...formFieldValue,
        }
        updatedValue[data?.field_id] = value
        if (!item?.questions || item?.questions?.length === 0) {
          delete updatedValue['questions']
        }
        setFormData((prevFormsData) => {
          const newData = prevFormsData.map((ele, i) => {
            if (formIndex === i) {
              let newdata = {
                ...ele,
              }
              newdata[data?.field_id] = item?.id
              if (isEditingLocker) {
                newdata['old_item_id'] = unitData?.[formIndex]?.item_id
              }

              newdata['questions'] = createDefaultAnswersNew(questions?.length > 0 ? questions : item?.questions)
              // newdata['questions'] = createDefaultAnswersNew(isEditingLocker ? questions : item?.questions, isEditingLocker)
              return newdata
            } else {
              return ele
            }
          })
          return newData
        })
        // setFormFieldValue({...updatedValue , questions : createDefaultAnswersNew(item?.questions)})
      } else {
        dispatch(
          setNotification({
            type: 'error',
            message: res?.data?.message,
          })
        )
        let payload = getDependDataPayload(form?.component_call_fields, data?.component_call_data_connection, dependent_value)
        fetchData(payload)
      }
    })
  }

  const getBgColor = (item) => {
    const selected_items = allFormsData?.map((forms) => forms[data?.field_id])
    const unitDataItemId = unitData?.map((item) => item?.item_id)

    if (isEditingLocker) {
      if (unitDataItemId?.includes(item?.id) && unitData?.[formIndex]?.item_id === item?.id) {
        return '#059669CC'
      } else if (formFieldValue[data?.field_id] === item?.id) {
        return '#06880BCC'
      } else if (item?.display === 0) {
        return '#FFFFFF'
      } else if ((item?.reserved && !unitData.map((e) => e.item_id).includes(item?.id)) || selected_items?.includes(item?.id)) {
        return '#F87171'
      } else {
        return primary_color
      }
    } else {
      if (formFieldValue[data?.field_id] === item?.id) {
        return '#059669CC'
      } else if (item?.display === 0) {
        return '#FFFFFF'
      } else if ((item?.reserved && !unitData.map((e) => e.item_id).includes(item?.id)) || selected_items?.includes(item?.id)) {
        return '#F87171'
      } else {
        return primary_color
      }
    }
  }

  if (isLoading)
    return (
      <div className='d-flex align-items-center justify-content-center' style={{ height: '10rem' }}>
        <Loader type='ball-pulse' color={primary_color} />
      </div>
    )

  let lockersToShow = filteredLocker
  if (showAllLocker) {
    lockersToShow = lockers
  }

  let isLockerAvailable = false
  filteredLocker?.map((item) => {
    if (item && item?.length > 0) {
      isLockerAvailable = true
    }
  })

  if (!isLockerAvailable && dependent_value)
    return (
      <div className='d-flex align-items-center justify-content-center' style={{ height: '10rem' }}>
        No lockers found.
      </div>
    )
  return (
    <div className='mt-2'>
      {lockersToShow?.length > 0 && (
        <>
          {isEditingLocker ? (
            <div className='d-flex align-items-center mb-5 mt-5 subtitle1'>
              <div className='rounded-pill mr-2 subtitle1' style={{ height: '1rem', width: '1rem', background: '#059669CC' }}></div> Currently Booked
              <div className='rounded-pill mr-2 ml-3 subtitle1' style={{ height: '1rem', width: '1rem', background: primary_color }}></div> Available
              <div className='rounded-pill mr-2 ml-3 subtitle1' style={{ height: '1rem', width: '1rem', background: '#06880BCC' }}></div> Booked
              <div className='rounded-pill mr-2 ml-3 subtitle1' style={{ height: '1rem', width: '1rem', background: '#F87171' }}></div> Not Available
            </div>
          ) : (
            <div className='d-flex align-items-center mb-5 mt-5 subtitle1'>
              <div className='rounded-pill mr-2 subtitle1' style={{ height: '1rem', width: '1rem', background: '#059669CC' }}></div> Selected
              <div className='rounded-pill mr-2 ml-3 subtitle1' style={{ height: '1rem', width: '1rem', background: primary_color }}></div> Available
              <div className='rounded-pill mr-2 ml-3 subtitle1' style={{ height: '1rem', width: '1rem', background: '#F87171' }}></div> Reserved
            </div>
          )}
        </>
      )}
      <div className=' mb-2 !w-100 custom-scrollbar' style={{ overflow: 'auto' }}>
        {lockers?.length > 0 && (
          <div className=''>
            <button
              className='btn rounded-pill mt-2 subtitle1 font-weight-bold mb-3'
              style={{ color: primary_color, width: 'fit-content', paddingInline: '1rem', border: `1px solid ${primary_color}` }}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setShowAllLocker(!showAllLocker)
              }}>
              {showAllLocker ? 'Show Available Lockers' : 'Show All Lockers'}
            </button>
          </div>
        )}

        {lockersToShow?.map((locakerItem, index) => {
          return (
            <div style={{ width: lockerColWidth * lockerColCount + 'px' }} className='d-flex'>
              {locakerItem?.map((item) => {
                const isDisplayZero = item?.display === 0 ? true : false
                return (
                  <div
                    key={index}
                    onClick={() => {
                      if (getBgColor(item) !== primary_color) {
                        return
                      }
                      if (item?.display) {
                        onChangeValue(item?.reserved, item?.id, item)
                      }
                    }}
                    className=' position-relative'
                    style={{
                      width: isDesktop ? '8rem' : '6rem',
                      margin: '0.1rem',
                      height: isDesktop ? '10rem' : '9rem',
                      background: getBgColor(item),
                      cursor: getBgColor(item) == primary_color ? 'pointer' : 'default',
                    }}>
                    {!isDisplayZero ? (
                      <div
                        className='m-1 position-absolute subtitle1'
                        style={{
                          left: '0.5rem',
                          top: '0.25rem',
                          background: 'white',
                          padding: '0rem 0.5rem',
                          borderRadius: '14px',
                          fontWeight: '500',
                        }}>
                        <div>£{item?.price}</div>
                      </div>
                    ) : null}
                    {!!item?.display && (
                      <div className='position-absolute' style={{ top: '50%', left: '50%', transform: 'translate(-50% , -50%)' }}>
                        {/* <i class='fa-thin fa-door-closed' style={{ fontSize: '5rem' }}></i> */}
                        <RiDoorLine style={{ color: '#fff', fontSize: '6rem' }} />
                      </div>
                    )}
                    {!!item?.display && (
                      <div
                        className='text-align-center mt-2 subtitle1'
                        style={{
                          color: '#fff',
                          position: 'absolute',
                          bottom: '1rem',
                          fontWeight: '500',
                          left: '50%',
                          transform: 'translate(-50%)',
                        }}>
                        {item?.item_name}
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
      {/* <div className=' mb-2 !w-100 custom-scrollbar' style={{ overflow: 'auto' }}>
        <div style={{ width: lockerColWidth * lockerColCount + 'px' }} className='d-flex flex-wrap'>
          {lockers?.map((locakerItem, index) => {
            return locakerItem?.map((item) => {
              const isDisplayZero = item?.display === 0 ? true : false
              return (
                <div
                  key={index}
                  onClick={() => {
                    if (item?.display) {
                      onChangeValue(item?.reserved, item?.id, item)
                    }
                  }}
                  className=' position-relative'
                  style={{
                    width: isDesktop ? '8rem' : '6rem',
                    margin: '0.1rem',
                    height: isDesktop ? '10rem' : '9rem',
                    background: getBgColor(item),
                    cursor: !item?.reserved || !isDisplayZero ? 'pointer' : 'default',
                  }}>
                  {!isDisplayZero ? (
                    <div
                      className='m-1 position-absolute subtitle1'
                      style={{
                        left: '0.5rem',
                        top: '0.25rem',
                        background: 'white',
                        padding: '0rem 0.5rem',
                        borderRadius: '14px',
                        fontWeight: '500',
                      }}>
                      <div>£{item?.price}</div>
                    </div>
                  ) : null}
                  {!!item?.display && (
                    <div className='position-absolute' style={{ top: '50%', left: '50%', transform: 'translate(-50% , -50%)' }}>
          
                      <RiDoorLine style={{ color: '#fff', fontSize: '6rem' }} />
                    </div>
                  )}
                  {!!item?.display && (
                    <div
                      className='text-align-center mt-2 subtitle1'
                      style={{
                        color: '#fff',
                        position: 'absolute',
                        bottom: '1rem',
                        fontWeight: '500',
                        left: '50%',
                        transform: 'translate(-50%)',
                      }}>
                      {item?.item_name}
                    </div>
                  )}
                </div>
              )
            })
          })}
        </div>
      </div> */}
      {questions && questions?.length > 0 && (
        <div className='mb-4 mt-5 body1' style={{ color: primary_color, fontWeight: 700 }}>
          Student Details
        </div>
      )}
      {questions && questions?.length > 0 && (
        <LockerQuestion
          validationError={validationError}
          setValidationError={setValidationError}
          questions={questions}
          formFieldValue={formFieldValue}
          setFormFieldValue={setFormFieldValue}
          formIndex={formIndex}
          setFormData={setFormData}
        />
      )}
    </div>
  )
}

export default LockerBooking
