import { useSelector } from 'react-redux'
import { primary_color } from '../../../Helper/uiHelper'
import { useEffect } from 'react'
import { checkCurrentDateBetween, checkIsFutureDate, convertDateInLocalTimezone } from '../../../dateHelper'
import moment from 'moment'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const MaintainanceBanner = () => {
  const history = useHistory()
  const updateApiData = useSelector((state) => state.CMS?.updateApiData)
  const start_date = updateApiData?.site_maintenance?.start_date
  const end_date = updateApiData?.site_maintenance?.end_date

  useEffect(() => {
    if (!checkCurrentDateBetween(convertDateInLocalTimezone(start_date), convertDateInLocalTimezone(end_date)) || !start_date) {
      history.push('/')
    }
  }, [updateApiData])

  return (
    <div style={{ width: '100vw', height: '100vh' }} className='d-flex justify-content-center align-items-center'>
      <div className='d-flex justify-content-center align-items-center flex-column'>
        <img src='maintainance.png' style={{ width: '20rem' }} className='mb-4'></img>
        <div style={{ color: primary_color, textAlign: 'center', fontSize: '1.4rem' }}>{updateApiData?.site_maintenance?.title}</div>
      </div>
    </div>
  )
}

export default MaintainanceBanner
