import Select from 'react-select'
import { cms, editCMS, getSubtitle1ForntSizeFromDOM } from '../../../helper'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { primary_color } from '../../../Helper/uiHelper'

const TagFieldDropdown = ({ data, isDisabled, onChange, options }) => {
  const [selectedValue, setSelectedValue] = useState(null)
  const cmsEditMode = useSelector((state) => state.CMS.editMode)
  const setting = data?.common_setting ? JSON.parse(data?.common_setting)?.[0] : {}

  const [commonSetting, setCommonSetting] = useState({})
  const [subtitleFontSize, setSubtitleFontSize] = useState('16px')

  useEffect(() => {
    setSubtitleFontSize(getSubtitle1ForntSizeFromDOM())
   
  }, [])

  useEffect(() => {
    setCommonSetting(setting)
     if (setting?.Value) {
       setSelectedValue({ value: setting?.Value, label: setting?.Value })
     }
  }, [JSON.stringify(setting)])

  const onChangeValue = (val) => {
    setCommonSetting({
      ...commonSetting,
      Value: val,
    })

    onChange(
      {
        ...commonSetting,
        Value: val,
      },
      data?.uuid
    )
  }

  return (
    <div className='mb-3'>
      <label
        style={{ color: primary_color }}
        className='subtitle1'
        onClick={() => (cmsEditMode && setting.cms_key ? editCMS(setting.cms_key) : null)}>
        {cms(setting?.cms_key) || data?.name}
      </label>
      <Select
        options={options}
        onChange={(e) => {
          onChangeValue(e?.value)
          setSelectedValue(e)
        }}
        value={selectedValue}
        styles={{
          menu: (base) => ({ ...base, zIndex: 15, fontSize: subtitleFontSize }),
          control: (baseStyles, state) => ({
            ...baseStyles,

            fontSize: subtitleFontSize,
          }),
          placeholder: (defaultStyles) => {
            return {
              ...defaultStyles,
              color: 'hsl(0, 0%, 70%)',
              fontSize: subtitleFontSize,
            }
          },
        }}
      />
    </div>
  )
}

export default TagFieldDropdown
