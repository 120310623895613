import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { cms, downloadFile, downloadFiles, printPDFByContent } from '../../../helper'
import { primary_color } from '../../../Helper/uiHelper'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import SendSMS from './SendSMS'
import { request } from '../../../Redux/Sagas/requests/api'
import { useDispatch, useSelector } from 'react-redux'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import { useEffect, useRef, useState } from 'react'
import { formatTimeFromSeconds } from '../../../Helper/paymentHelper'
import useMediaQuery from '../../../CustomHook/useMediaQuery'
import CMSWidgetModal from '../../CMSWidget/cmsWidgetModal'
import CMSHeroSearch from '../../CMSWidget/HeroSearch/cmsHeroSearch'
import CMSPaymentSuccessAction from '../../CMSWidget/PaymentSuccess/cmsPaymentSuccessAction'
import './payment.scss'

const getSuccessMessage = (component_name) => {
  switch (component_name) {
    case 'download_button':
      return 'File downloaded successfully!'
    case 'print_button':
      return null
    case 'email_button':
      return 'Email sent successfully!'
    default:
      return null
  }
}
const PaymentSuccessAction = ({ activePage, setActivePage, data, widget_uuid, bookingDetails }) => {
  const isDesktop = useMediaQuery('(min-width:768px)')
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const editMode = useSelector((state) => state.CMS.editMode)
  // const [emailTimer, setEmailTimer] = useState(180)
  const [showValidation, setShowValidation] = useState(false)

  const sectionRef = useRef(null)

  const [modalPosition, setModalPosition] = useState()

  const handleSectionClick = () => {
    if (!editMode) return
    if (sectionRef.current) {
      const rect = sectionRef.current.getBoundingClientRect()
      setModalPosition({
        //  top: rect.top + window.scrollY - 50,
        top: 200,

        //  left: rect.left + window.scrollX + rect.width / 2 - 10, // Position below the parent element
        //  left: rect.left + window.scrollX, // Align left edge with parent element
        width: '60vw',
      })
    }
  }

  const buttons = [
    {
      icon: 'fa-solid fa-download mr-1',
      title: 'Download PDF',
      disabled: false,
      component_name: 'download_button',
      isOutlined: true,
    },
    {
      icon: 'fa-solid fa-print mr-1',
      title: 'Print',
      disabled: false,
      component_name: 'print_button',
    },
    {
      icon: 'fa-solid fa-envelope-open-text mr-1',
      // title: emailTimer > 0 ? `Resend Email in ${formatTimeFromSeconds(emailTimer)}` : 'Send via Email',
      title: 'Send via Email',
      // disabled: emailTimer > 0 ? true : false,
      component_name: 'email_button',
    },
    {
      icon: 'fa-regular fa-comment-dots',
      title: 'Send Via SMS',
      disabled: false,
      component_name: 'sms_button',
    },
  ]

  // useEffect(() => {
  //   if (emailTimer > 0)
  //     setTimeout(() => {
  //       setEmailTimer((prev) => prev - 1)
  //     }, 1000)
  // }, [emailTimer])

  const [sendSMSModalOpen, setSendSMSModalOpen] = useState(false)
  const [formDetails, setFormDetails] = useState({
    country_code: '+44',
    mobile_number: '',
  })

  const urlParams = new URLSearchParams(location.search)
  const payment_uuid = urlParams.get('payment_uuid')
  const isEditing = urlParams.get('edit_booking')

  const onSendSMS = () => {
    if (!formDetails.mobile_number) return setShowValidation(true)
    let payload = {
      payment_uuid,
      component_name: 'sms_button',
      phone_number: formDetails?.country_code + '-' + formDetails?.mobile_number,
    }
    request('generate-pdf-and-create-message-log', 'POST', payload).then((res) => {
      if (res?.status === 200 || res?.status === 202) {
        dispatch(
          setNotification({
            type: 'success',
            message: 'SMS sent successfully!',
          })
        )
      } else {
        dispatch(
          setNotification({
            type: 'error',
            message: res?.data?.message || 'Something went wrong',
          })
        )
      }
      setSendSMSModalOpen(!sendSMSModalOpen)
      setFormDetails({
        country_code: '',
        mobile_number: '',
      })
    })
  }

  const onButtonClick = (item) => {
    if (item?.component_name === 'sms_button') {
      setSendSMSModalOpen(true)
    } else if (item?.component_name === 'download_button') {
      let payload = {
        payment_uuid,
        component_name: item?.component_name,
      }
      let fileName = 'invoice'
      if (bookingDetails?.bookingReservation?.booking_id){
        fileName += `-${bookingDetails?.bookingReservation?.booking_id}`
      } downloadFiles('generate-pdf-and-create-message-log', `${fileName}.pdf`, payload)
    } else {
      let payload = {
        payment_uuid,
        component_name: item?.component_name,
      }
      request('generate-pdf-and-create-message-log', 'POST', payload, {}, {}, {}, { responseType: 'arraybuffer' }).then((res) => {
        if (res?.status === 200 || res?.status === 202) {
          if (item?.component_name === 'print_button') {
            printPDFByContent(res)
          }
          if (getSuccessMessage(item?.component_name)) {
            dispatch(
              setNotification({
                type: 'success',
                message: getSuccessMessage(item?.component_name),
              })
            )
          }
        } else {
          dispatch(
            setNotification({
              type: 'error',
              message: res?.data?.message || 'Something went wrong',
            })
          )
        }
      })
      if (item?.component_name === 'email_button') {
        // setEmailTimer(180)
        setActivePage(activePage.child_pages[0])
      }
    }
  }

  const onCloseModal = () => {
    let element = sectionRef?.current
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' })
    }
    setModalPosition(null)
  }

  return (
    <div
      className='mt-4'
      style={isDesktop ? { padding: isDesktop ? '0 0 0 20%' : '0' } : { paddingInline: '1rem', marginBottom: '2rem' }}
      onClick={handleSectionClick}
      ref={sectionRef}>
      <div className='mb-2 font-weight-bold body1' style={{ color: primary_color }} dangerouslySetInnerHTML={{ __html: data?.heading }}></div>
      <div className='mb-5 subtitle1 text-muted-light' dangerouslySetInnerHTML={{ __html: data?.text }}></div>
      {/* <div className='mb-2 font-weight-bold subtitle1'>{data?.heading}</div> */}
      {/* <div className='mb-5 font-weight-bold subtitle1 text-muted-light' style={{ textAlign: 'justify' }}>
        {data?.text}
      </div> */}
      <div className='payment-success-action' style={{ display: 'grid' }}>
        {buttons?.map((item, index) => (
          <Button
            key={index}
            disabled={item.disabled}
            color=''
            className='my-2 mr-2 font-weight-bold primary_button subtitle1 rounded-pill'
            style={
              item?.isOutlined
                ? {
                    // backgroundColor: primary_color,
                    color: primary_color,
                    borderColor: primary_color,
                    borderRadius: '30px',
                    paddingInline: '1.5rem',
                    width: isDesktop ? 'auto' : '100%',
                  }
                : {
                    backgroundColor: primary_color,
                    borderColor: primary_color,
                    borderRadius: '30px',
                    paddingInline: '1.5rem',
                    width: isDesktop ? 'auto' : '100%',
                    color: '#fff',
                  }
            }
            onClick={() => onButtonClick(item)}>
            <i className={item?.icon}></i> {item?.title}
          </Button>
        ))}

        {!isEditing && (
          <Button
            className='my-5 mr-2 font-weight-bold primary_button subtitle1 rounded-pill'
            onClick={() => {
              history.push('/home-page-list')
            }}
            style={{
              backgroundColor: primary_color,
              borderColor: primary_color,
              borderRadius: '30px',
              paddingInline: '1.5rem',
              width: isDesktop ? 'auto' : '100%',
              color: '#fff',
            }}>
            Go to Home <i class='fa-solid fa-arrow-right ml-1'></i>
          </Button>
        )}
      </div>

      <Modal
        isOpen={sendSMSModalOpen}
        toggle={() => {
          setSendSMSModalOpen(!sendSMSModalOpen)
          setFormDetails({
            country_code: '',
            mobile_number: '',
          })
          setShowValidation(false)
        }}
        style={{ width: '30rem' }}>
        <ModalHeader
          toggle={() => {
            setSendSMSModalOpen(!sendSMSModalOpen)
            setFormDetails({
              country_code: '',
              mobile_number: '',
            })
            setShowValidation(false)
          }}>
          Send SMS
        </ModalHeader>
        <ModalBody>
          <SendSMS showValidation={showValidation} setShowValidation={setShowValidation} formDetails={formDetails} setFormDetails={setFormDetails} />
        </ModalBody>
        <ModalFooter>
          <Button
            className='my-2 mr-2 primary_button rounded-pill subtitle1'
            style={{ borderRadius: '20px', padding: '0.5rem 1.5rem', background: primary_color }}
            onClick={() => onSendSMS()}>
            <i className={'fa-regular fa-comment-dots mr-3'}></i> Send SMS
          </Button>
        </ModalFooter>
      </Modal>
      {modalPosition && (
        <CMSWidgetModal sx={{ ...modalPosition }} toggleModal={() => onCloseModal()}>
          <CMSPaymentSuccessAction
            data={data}
            toggleModal={() => {
              onCloseModal()
            }}
            widget_uuid={widget_uuid}
          />
        </CMSWidgetModal>
      )}
    </div>
  )
}

export default PaymentSuccessAction
