import { Button, Col, Row } from 'reactstrap'
import { primary_color } from '../../../Helper/uiHelper'
import { useDispatch, useSelector } from 'react-redux'
import { request } from '../../../Redux/Sagas/requests/api'
import { isSuccessResponse } from '../../../helper'
import { setLoader } from '../../../Redux/Reducers/loader'
import { fetchUpdateApiData } from '../../../Redux/Reducers/CMS'

const RateAction = ({ setEditRateTemplateOpen, fetchRateStatus, isRateApproved }) => {
  const dispatch = useDispatch()
  const currentRow = useSelector((state) => state.CMS.currentRow)

  const onResetRate = () => {
    dispatch(setLoader(true))
    request('renewal-locations/update-action', 'POST', {
      item_date_time_id: currentRow?.[0]?.id,
      status: 'Reset',
    }).then((res) => {
      isSuccessResponse(res, 'Rate reset successfully!')
      dispatch(setLoader(false))
      fetchRateStatus()
      dispatch(fetchUpdateApiData())
    })
  }

  const onApproveRate = () => {
    dispatch(setLoader(true))
    request('renewal-locations/approve-rate', 'POST', {
      item_date_time_id: currentRow?.[0]?.id,
    }).then((res) => {
      dispatch(setLoader(false))
      isSuccessResponse(res, 'Rate approved successfully!')
      fetchRateStatus()
    })
  }
  return (
    <div>
      <div className='d-flex'>
        <Button
          className='mr-3'
          color='warning'
          disabled={isRateApproved}
          style={{ color: '#fff' }}
          onClick={() => {
            onResetRate()
          }}>
          <i class='fa-solid fa-rotate-right mr-2'></i>
          Reset Rate
        </Button>
        <Button
          disabled={isRateApproved}
          className='mr-3'
          color='success'
          onClick={() => {
            onApproveRate()
          }}>
          <i class='fa-regular fa-check mr-2'></i>Approve Rate
        </Button>
        <Button

          color='primary'
          style={{ background: primary_color }}
          onClick={() => {
            setEditRateTemplateOpen(true)
          }}>
          {isRateApproved ? <i class='fa-solid fa-eye mr-2'></i> : <i class='fa-solid fa-pen-to-square mr-2'></i>}

          {isRateApproved ? 'View Template' : 'Edit Template'}
        </Button>
      </div>
    </div>
  )
}

export default RateAction
