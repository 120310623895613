import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Button, ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader, Progress, Tooltip } from 'reactstrap'

import { cms, getFromUpdateApi, tidyName } from '../../helper'
import IconSet from '../icon'
import { triggerActions } from '../../Redux/Reducers/CMS'
import { request } from '../../Redux/Sagas/requests/api'
import PdfViewer from '../../Components/PdfViewer/pdfViewer'
import { getDownloadRefValue } from '../../../src/Helper/downloadHelper'
import moment from 'moment'

const select = (state) => ({
  coreData: state.CMS.coreData,
  updateApiData: state.CMS.updateApiData,
})

const RenderRow = ({ row, allCols, getColor, renderDisplay, PreviewIcon, onShowPreview, renderSendEmailStatus, RenderButtons }) => {
  const [progressValue, setProgressValue] = useState(0)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [progressId] = useState(`progress-${row.id}`)

  const finalRow = {
    id: row.id,
    display: [],
    path: row.path,
    icon_buttons: [],
    cms_key: row?.cms_key,
  }

  allCols?.map((item) => {
    finalRow.cms_key = row?.cms_key
    switch (item.component_name) {
      case 'text_data_col':
        finalRow.display = [...finalRow.display, { value: row[item.field_id], name: item.field_id, hidden: item.hidden }]
        break
      case 'icon_col':
        finalRow.icon = row[item.name]
        break
      case 'advanced_field_col':
        if (item.component_call_data_connection.length > 0) {
          finalRow.advanced_data = {
            header: row[item.component_call_data_connection[0].header] || row.header,
            max_value: row[item.component_call_data_connection[0].max_value] || row.max_value,
            min_value: row[item.component_call_data_connection[0].min_value] || row.min_value,
            value: row[item.component_call_data_connection[0].value] || row.value,
          }
        }
        break
      case 'icon_col_button':
        finalRow.icon_buttons = [
          ...finalRow.icon_buttons,
          {
            icon: item.icon,
            name: item.name,
            hidden: item.hidden,
            icon_type: item.icon_type,
            background_color: item.background_color,
            component_call_actions: item.component_call_actions,
          },
        ]
        break
      default:
        break
    }
  })

  let timer

  useEffect(() => {
    if (finalRow.advanced_data && !timer && row?.body_char_count) {
      const count = row.body_char_count
      const { char_count, char_time } = getDownloadRefValue(row?.header)

      const totalTime = (count / char_count) * char_time

      const progressIncrement = 100 / totalTime
      const updatedTime = moment(row.updated_at)
      const currentTime = moment()
      const elapsedTime = currentTime.diff(updatedTime, 'seconds')
      const initialProgress = Math.min((elapsedTime / totalTime) * 100, 100)

      setProgressValue(initialProgress)

      let currentProgress = 0
      timer = setInterval(() => {
        if (currentProgress >= 100) {
          clearInterval(timer)
        } else {
          currentProgress += progressIncrement
          setProgressValue((prev) => Math.min(prev + progressIncrement, 100))
        }
      }, 1000) // Update progress every second

      return () => clearInterval(timer)
    }
  }, [row?.body_char_count])

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen)
  return (
    <ListGroupItem className='pt-2 pb-2 pr-2' color={getColor(finalRow.display)}>
      <div className='widget-content p-0'>
        <div className='widget-content-wrapper'>
          <div className='widget-content-left opacity-6 fsize-2 mr-3 text-primary center-elem'>
            <IconSet icon={finalRow.icon || 'fas fa-file'} type={finalRow.icon_type} color={row.background_color || 'primary'} fontWeight={400} />
          </div>
          <div className='widget-content-left'>
            {finalRow.advanced_data ? (
              <>
                <p style={{ marginBottom: 0 }} className='subtitle1'>
                  {finalRow.advanced_data.header}
                  {finalRow?.cms_key && <div className='subtitle1'>({finalRow?.cms_key ? cms(finalRow?.cms_key) : ''})</div>}
                </p>
                <div style={{ width: finalRow.icon_buttons && finalRow.icon_buttons.length > 0 ? '200px' : '330px' }} className='subtitle1'>
                  {progressValue === 0 ? (
                    <div className='subtitle1'>Data compiling.</div>
                  ) : (
                    <>
                      <Progress
                        className='mb-3 subtitle1'
                        id={progressId}
                        animated
                        color={finalRow.background_color || 'primary'}
                        value={progressValue}>
                        {progressValue === 100 ? '99.95' : Math.floor(progressValue)}%
                      </Progress>
                      <Tooltip placement='top' isOpen={tooltipOpen} target={progressId} toggle={toggleTooltip}>
                        {progressValue == 100 ? '99.95' : progressValue?.toFixed(3)}%
                      </Tooltip>
                    </>
                  )}
                </div>
              </>
            ) : (
              <div className='widget-heading font-weight-normal subtitle1'>
                <p style={{ marginBottom: 0 }} className='subtitle1'>
                  {tidyName(cms(finalRow?.cms_key))}
                </p>
                <span className='subtitle1'>{renderDisplay(finalRow.display)}</span>
                {!!row?.failed && <div className='text-danger subtitle1'>Download Failed!</div>}

                {renderSendEmailStatus(finalRow.display)}
              </div>
            )}
          </div>
          <div className='widget-content-right widget-content-actions subtitle1'>
            {renderDisplay(finalRow.display)?.includes('pdf') && !!!row?.failed && <PreviewIcon onShowPreview={onShowPreview} finalRow={finalRow} />}
       
            <RenderButtons buttons={finalRow.icon_buttons} isFailed={!!row?.failed} display={finalRow.display} id={finalRow.id} />
          </div>
        </div>
      </div>
    </ListGroupItem>
  )
}

const CMSBasicGrid = ({ type, allCols, coreData, dataConnection, updateApiData }) => {
  const [dataCon, updateData] = useState([])
  const dispatch = useDispatch()

  const [base64Content, setBase64Content] = useState()

  const renderDisplay = (display) => {
    let display_name = ''
    display.map((item) => {
      if (!item.hidden && item.name === 'display_name') display_name += item.value + ' '
    })
    return display_name
  }

  const getColor = (display) => {
    let send = false
    let email = false
    display.map((item) => {
      if (!item.hidden) {
        switch (item.name) {
          case 'send':
            send = item.value
            break
          case 'email':
            email = true
            break
        }
      }
    })
    if (email) {
      return send == 1 ? 'success' : 'warning'
    }
    return ''
  }

  const renderSendEmailStatus = (display) => {
    let send = false
    let email = false
    display.map((item) => {
      if (!item.hidden) {
        switch (item.name) {
          case 'send':
            send = item.value
            break
          case 'email':
            email = true
            break
        }
      }
    })
    if (email) {
      return send == 1 ? <p style={{ marginBottom: 0 }}>Sent successfully</p> : <p style={{ marginBottom: 0 }}>Email in progress.</p>
    }
    return null
  }

  const RenderButtons = ({ buttons, display, id, isFailed }) => {
    const [tooltipOpen, setTooltipOpen] = useState({})

    const toggleTooltip = (tooltipId) => {
      setTooltipOpen((prevState) => ({
        ...prevState,
        [tooltipId]: !prevState[tooltipId],
      }))
    }
 
    return buttons.map((item, key) => {
      if (item.hidden) return null
      if (isFailed && item?.name !== 'delete_downloads') return
      const tooltipId = `tooltip-${id}-${key}`
      return (
        <Button
          size='sm'
          key={key}
          className='btn-icon btn-icon-only subtitle1'
          color='link'
          id={tooltipId}
          onClick={() => handleButtonClick(item.component_call_actions, display, id)}>
          <IconSet icon={item.icon || 'fas fa-file'} type={item.icon_type} color={item.background_color || 'primary'} fontWeight={600} />
          <Tooltip placement='top' isOpen={tooltipOpen[tooltipId]} target={tooltipId} toggle={() => toggleTooltip(tooltipId)}>
            {tidyName(item?.name)}
          </Tooltip>
        </Button>
      )
    })
  }

  const onShowPreview = (id) => {
    request('downloads-file/' + id, 'POST', {}, {}, {}, {}, { responseType: 'arraybuffer' }).then((res) => {
      let binary = ''
      const bytes = new Uint8Array(res?.data)
      const len = bytes.byteLength
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i])
      }
      const a = window.btoa(binary)
      setBase64Content(a)
    })
  }
  const handleButtonClick = (actions, data, id) => {
    // request(updateData.url, 'POST', {}, {}, {}, {}, { responseType: 'arraybuffer' })

    let preview_action = actions?.find((item) => item?.component_name === 'export_preview')
    if (preview_action) {
      request('downloads-file/' + id, 'POST', {}, {}, {}, {}, { responseType: 'arraybuffer' }).then((res) => {
        setBase64Content(res?.data)
      })
    } else {
      triggerActions(actions, data, id)
    }
  }

  const PreviewIcon = ({ onShowPreview, finalRow }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false)
    const toggle = () => setTooltipOpen(!tooltipOpen)
    return (
      <Button
        size='sm'
        id='preview-icon'
        // key={key}
        className='btn-icon btn-icon-only subtitle1'
        color='link'
        onClick={() => onShowPreview(finalRow.id)}
        // onClick={() => handleButtonClick(item.component_call_actions, display, id)}
      >
        <IconSet icon={'fas fa-file'} color={'primary'} fontWeight={600} />
        <Tooltip placement='top' isOpen={tooltipOpen} target={'preview-icon'} toggle={toggle}>
          Preview
        </Tooltip>
      </Button>
    )
  }

  useEffect(() => {
    updateData(getFromUpdateApi(dataConnection))
  }, [coreData, updateApiData])

  return (
    <div className='drawer-section p-0'>
      <div className='files-box'>
        <ListGroup flush>
          {dataCon.length > 0 &&
            dataCon?.map((item, key) => {
              return (
                <React.Fragment key={key}>
                  <RenderRow
                    row={item}
                    allCols={allCols}
                    getColor={getColor}
                    renderDisplay={renderDisplay}
                    PreviewIcon={PreviewIcon}
                    onShowPreview={onShowPreview}
                    renderSendEmailStatus={renderSendEmailStatus}
                    RenderButtons={RenderButtons}
                  />
                </React.Fragment>
              )
              // return <React.Fragment key={key}>{renderRow(item)}</React.Fragment>
            })}
        </ListGroup>
        <Modal isOpen={base64Content} toggle={() => setBase64Content()}>
          <ModalHeader toggle={() => setBase64Content()}>Preview</ModalHeader>
          <ModalBody style={{ overflow: 'auto' }}>{base64Content && <PdfViewer base64Content={base64Content} />}</ModalBody>
        </Modal>
      </div>
    </div>
  )
}
export default connect(select)(CMSBasicGrid)
