import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap'
import { capitalize, cms, editCMS, tidyName } from '../../../helper'
import IconSet from '../../icon'

const select = (state) => ({
  editMode: state.CMS.editMode,
})

const checkIsDropdownDisabled = (button, selectedRow) => {
  let isDisabled = false

  if (button?.component_call_actions?.[0]?.payload?.data?.includes('selected_id')) {
    if (selectedRow?.length === 0 || !selectedRow) {
      isDisabled = true
    }
  }
  return isDisabled
}

const CMSButton = ({
  item,
  triggerActions = () => {},
  editMode,
  gridSelectedRow,
  disabled,
  singleRow = false,
  noConfirmation,
  onClickButton = () => {},
}) => {
  const [dropdown, updateDropdown] = useState([])
  const [dropdownOpen, updateDropdownOpen] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  useEffect(() => {
    if (item.drop_down_options) {
      updateDropdown(item.drop_down_options)
    } else {
      updateDropdown([])
    }
  }, [item])

  if (dropdown.length > 0) {
    return (
      <>
        <ButtonDropdown
          isOpen={dropdownOpen}
          direction='left'
          toggle={() => updateDropdownOpen(!dropdownOpen)}
          className='border-0 btn-icon btn-icon-only fsize-1 m-2'
          color={item.background_color === 'link' ? 'secondary' : item.background_color || 'secondary'}
          disabled={disabled}>
          <DropdownToggle
            caret
            id={'id' + item?.uuid?.replaceAll('-', '') || item.name}
            onClick={() => {
              if (editMode) {
                editCMS(item.cms_key)
              }
            }}>
            <IconSet icon={item.icon || 'fas fa-file'} type={item.icon_type} color={item.color || 'none'} fontWeight={400} />
          </DropdownToggle>
          <UncontrolledTooltip placement='bottom' target={'id' + item?.uuid?.replaceAll('-', '') || item.name}>
            {cms(item.cms_tooltip) || cms(item.cms_key_tooltip)}
          </UncontrolledTooltip>
          <DropdownMenu>
            {dropdown.map((item1, key) => {
              return (
                <DropdownItem
                  key={key}
                  onClick={() => (editMode ? editCMS(item1.cms_key) : triggerActions(item1.component_call_actions))}
                  className={`${checkIsDropdownDisabled(item1, gridSelectedRow) && 'pointer-event-none opacity-half'}`}>
                  <IconSet
                    icon={item1.icon || 'fas fa-file'}
                    type={item1.icon_type}
                    color={item1.color || 'light'}
                    fontWeight={400}
                    className='mr-2'
                  />
                  {'  ' + capitalize(cms(item1.cms_key))}
                </DropdownItem>
              )
            })}
          </DropdownMenu>
        </ButtonDropdown>
      </>
    )
  }
  if (
    item.component_call_actions &&
    item.component_call_actions?.length !== 0 &&
    (item.component_call_actions[0]?.component_name === 'connect_delete_item_template' ||
      item.component_call_actions[0]?.component_name === 'connect_delete_trash_item_template')
  ) {
    return (
      <>
        <Button
          className='border-0 btn-icon btn-icon-only fsize-1 m-2'
          id={'id' + item?.uuid?.replaceAll('-', '') || item.name}
          color={item.background_color || 'secondary'}
          onClick={() => {
            if (editMode) {
              editCMS(item.cms_key_tooltip)
            } else {
              if (!noConfirmation) setDeleteModal(true)
            }
          }}
          disabled={gridSelectedRow?.length > 0 ? false : singleRow ? false : true}>
          <IconSet icon={item.icon || 'fas fa-file'} type={item.icon_type} color={item.color || 'none'} fontWeight={400} />
          {/* {' ' + cms(item.cms_key)} */}
        </Button>
        <UncontrolledTooltip placement='bottom' target={'id' + item?.uuid?.replaceAll('-', '') || item.name}>
          {cms(item.cms_key_tooltip)}
        </UncontrolledTooltip>
        <Modal isOpen={deleteModal} toggle={() => setDeleteModal(false)} style={{ width: 300 }}>
          <ModalBody>
            <div>Are you sure you want to delete ?</div>
          </ModalBody>
          <ModalFooter style={{ justifyContent: 'center' }}>
            <Button
              onClick={() => {
                setDeleteModal(false)
                triggerActions(item.component_call_actions)
              }}>
              Delete
            </Button>
          </ModalFooter>
        </Modal>
      </>
    )
  } else {
    return (
      <>
        <Button
          className='border-0 btn-icon btn-icon-only fsize-1 m-2'
          id={'id' + item?.uuid?.replaceAll('-', '') || item.name?.replace('(', '')?.replace(')', '')}
          color={item.background_color || 'secondary'}
          onClick={() => {
            if (editMode) {
              editCMS(item.cms_key_tooltip)
            } else {
              triggerActions(item.component_call_actions)
              onClickButton()
            }
          }}
          disabled={disabled}>
          <IconSet icon={item.icon || 'fas fa-file'} type={item.icon_type} color={item.color || 'none'} fontWeight={400} />
        </Button>
        <UncontrolledTooltip placement='bottom' target={'id' + item?.uuid?.replaceAll('-', '') || item.name?.replace('(', '')?.replace(')', '')}>
          {tidyName(cms(item.cms_key_tooltip))}
        </UncontrolledTooltip>
      </>
    )
  }
}

export default connect(select)(CMSButton)
