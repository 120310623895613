import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AvForm } from 'availity-reactstrap-validation'

import RenderFields from '../../../CMSComponent/Forms/renderFields'
import { primary_color } from '../../../Helper/uiHelper'
import { request } from '../../../Redux/Sagas/requests/api'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import { displayVertically } from './manageLocker'
import LockerListing from './lockerListing'

const filterAllLocker = (lockers) => {
  const data = JSON.parse(JSON.stringify(lockers))

  const checkCondition = (item) => {
    if (item?.is_maintenance || item?.offSale) {
      return true
    } else {
      return false
    }
  }

  const maxLength = Math.max(...data.map((row) => row.length))

  const indexToRemove = []

  for (let i = 0; i < maxLength; i++) {
    const shouldRemoveIndex = data.some((row) => {
      return checkCondition(row[i])
    })

    if (shouldRemoveIndex) {
      indexToRemove?.push(i)
    }
  }

  const newData = []
  data.forEach((row) => {
    // return row.splice(i, 1)
    const newRow = []
    row.map((item, index) => {
      if (indexToRemove?.includes(index)) {
        newRow.push(item)
      }
    })
    newData.push(newRow)
  })

  return newData
}

const BlockMaintanance = ({ setBlocketails, blockDetails, form, onCloseForm, isPlannedBlock }) => {
  const dispatch = useDispatch()
  const [formFieldValue, setFormFieldValue] = useState()
  const [loading, setLoading] = useState(false)
  const [lockers, setLockers] = useState()
  const [filteredLockers,setFilteredLockers] = useState()
  const [showAllLocker, setShowAllLocker] = useState(false)
  const [selectedLockerIds, setSelectedLockerIds] = useState([])
  const [selectedLockers, setSelectedLockers] = useState([])

  useEffect(() => {
    const newLockers = displayVertically(blockDetails?.lockers, blockDetails?.lockerHeight)
    setLockers(newLockers)
    setFilteredLockers(filterAllLocker(newLockers))
  }, [blockDetails])

  const onAddMaintanance = (values) => {
    if (selectedLockerIds?.length === 0) {
      dispatch(setNotification({ type: 'error', message: 'Please select atleast one locker!' }))
      return
    }
    let payload = {
      ...values,
      item_id: selectedLockerIds,
      is_planned_block: isPlannedBlock,
    }
    setLoading(true)
    request('maintenance-locker', 'POST', payload).then((res) => {
      if (res.status === 200 || res.status === 202 || res.status === 201) {
        dispatch(setNotification({ type: 'success', message: 'Locker successfully placed on maintenance.' }))
        request('get-location-data', 'POST', { location_id: blockDetails?.id, is_planned_block: isPlannedBlock }).then((res) => {
          if (res?.status === 200 || res?.status === 201 || res?.status === 202) {
            setBlocketails(res?.data)
          }
          setLoading(false)
        })
      } else {
        dispatch(setNotification({ type: 'error', message: res?.data?.message || 'Something went wrong!' }))
        setLoading(false)
      }
    })
    setSelectedLockerIds([])
    setSelectedLockers([])
  }

  const onRelease = () => {
    if (selectedLockerIds?.length === 0) {
      dispatch(setNotification({ type: 'error', message: 'Please select atleast one locker!' }))
      return
    }
    let payload = {
      item_id: selectedLockerIds,
      is_planned_block: isPlannedBlock,
    }
    setLoading(true)
    request('maintenance-locker-release', 'POST', payload).then((res) => {
      if (res.status === 200 || res.status === 202 || res.status === 201) {
        dispatch(setNotification({ type: 'success', message: 'Locker released successfully!' }))
        request('get-location-data', 'POST', { location_id: blockDetails?.id, is_planned_block: isPlannedBlock }).then((res) => {
          if (res?.status === 200 || res?.status === 201 || res?.status === 202) {
            setBlocketails(res?.data)
          }
          setLoading(false)
        })
      } else {
        dispatch(setNotification({ type: 'error', message: res?.data?.message || 'Something went wrong!' }))
        setLoading(false)
      }
    })
    setSelectedLockerIds([])
    setSelectedLockers([])
  }

  let lockersToShow = filteredLockers
  if (showAllLocker){
    lockersToShow = lockers
  }
    return (
      <div className='mt-5'>
        {/* <div className='text-muted body1 mb-4'>Maintenance</div> */}
        <div>
          <AvForm
            onValidSubmit={(e, values) => {
              setLoading(true)
              onAddMaintanance(values)
              // onSubmitBlockDetails(values)
            }}>
            <RenderFields
              allForm={[form]}
              content={[form]}
              twoCol={true}
              // defaultFormValue={defaultSingleFormValue}
              formFieldValue={formFieldValue}
              setFormFieldValue={setFormFieldValue}
              noDefaultValue={true}
              noCache={true}
            />
            <div>
              <button
                className='btn rounded-pill mt-2 text-danger border-danger mr-3'
                type='button'
                id='close-btn'
                onClick={(e) => {
                  onCloseForm()
                  e.preventDefault()
                  e.stopPropagation()
                }}
                style={{ fontWeight: 700, width: '12rem' }}>
                Close
              </button>
              <button
                className='btn rounded-pill mt-2 text-success border-success mr-3'
                type='button'
                disabled={!!loading || selectedLockerIds?.length === 0}
                id='close-btn'
                onClick={(e) => {
                  onRelease()
                  e.preventDefault()
                  e.stopPropagation()
                }}
                style={{ fontWeight: 700, width: '12rem' }}>
                Release
              </button>
              <button
                className='btn text-white rounded-pill mt-2'
                disabled={!!loading || selectedLockerIds?.length === 0 || !formFieldValue?.item_type_id}
                type='submit'
                style={{ background: primary_color, fontWeight: 700, width: '14rem' }}>
                {loading ? 'Saving...' : 'Add'}
              </button>
            </div>
          </AvForm>
        </div>
        <div>
          {lockers?.length > 0 && (
            <div className='mt-5'>
              <LockerListing
                lockers={lockersToShow}
                isLockerSelectable={true}
                setSelectedLockerIds={setSelectedLockerIds}
                selectedLockerIds={selectedLockerIds}
                setSelectedLockers={setSelectedLockers}
                selectedLockers={[]}
                // isSelectSingleLocker={true}
                isPlannedBlock={isPlannedBlock}
                isMaintainance={true}
                setShowAllLocker={setShowAllLocker}
                showAllLocker={showAllLocker}
                hideAddRemoveColumn={true}></LockerListing>
            </div>
          )}
        </div>
      </div>
    )
}

export default BlockMaintanance
