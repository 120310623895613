import moment from 'moment'
import { useSelector } from 'react-redux'

const MaintainanceNotice = () => {
  const updateApiData = useSelector((state) => state.CMS?.updateApiData)
  const start_date = updateApiData?.site_maintenance?.start_date
  const end_date = updateApiData?.site_maintenance?.end_date

  const localTimeStartDate = moment.utc(start_date).local().format('Do, MMM YYYY HH:mm')
  const localTimeEndDate = moment.utc(end_date).local().format('Do, MMM YYYY HH:mm')

  return (
    <div className='bg-danger subtitle1 text-light text-center py-2'>
      Our site will be unavailable from {localTimeStartDate} to {localTimeEndDate} for scheduled maintenance. We apologize for the inconvenience and
      appreciate your patience.
    </div>
  )
}

export default MaintainanceNotice
