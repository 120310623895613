import { useEffect, useState } from 'react'
import { request } from '../../../Redux/Sagas/requests/api'
import { useDispatch, useSelector } from 'react-redux'
import { primary_color } from '../../../Helper/uiHelper'
import Loader from 'react-loaders'
import { Table } from 'reactstrap'
import { isSuccessResponse, tidyName } from '../../../helper'
import { fetchUpdateApiData } from '../../../Redux/Reducers/CMS'

const getTierData = (data) => {
  const tiers = []
  data?.map((location) => {
    location?.tiers?.map((tier) => {
      tiers?.push({
        location_id: location?.id,
        location_name: location?.location_name,
        tier: tier?.tier,
        rate: tier?.rate,
      })
    })
  })
  return tiers
}

const EditRateBySchool = ({ domain_id, onClose }) => {
  const dispatch = useDispatch()
  const currentRow = useSelector((state) => state.CMS.currentRow)

  const [loading, setLoading] = useState(false)
  const [tierData, setTierData] = useState([])
  const [editableCell, setEditableCell] = useState({})
  const [submitting, setSubmitting] = useState(false)

  const [searchName, setSearchName] = useState('')
  const [searchTier, setSearchTier] = useState('')
  const [searchPrice, setSearchPrice] = useState('')

  useEffect(() => {
    setLoading(true)
    request('renewal-locations/get-block-tier-rate', 'POST', { item_date_time_id: currentRow?.[0]?.id, domain_id }).then((res) => {
      setLoading(false)
      if (res?.data) {
        setTierData(getTierData(res?.data?.locations))
      }
    })
  }, [])

  const handleDoubleClick = (rowIndex, colIndex) => {
    setEditableCell({ row: rowIndex, col: colIndex })
  }
  const handleChange = (index, value) => {
    setTierData((oldData) => {
      const newData = oldData?.map((item, i) => {
        if (index === i) {
          return {
            ...item,
            rate: value,
          }
        } else {
          return item
        }
      })
      return newData
    })
  }

  const onSubmitForm = (close) => {
    setSubmitting(true)
    request('renewal-locations/update-rate-tier-wise', 'POST', { item_date_time_id: currentRow?.[0]?.id, domain_id, data: tierData }).then((res) => {
      setSubmitting(false)
      isSuccessResponse(res, 'Rate updated successfully!')
      if (close) {
        onClose()
      }
      dispatch(fetchUpdateApiData())
    })
  }

  if (loading)
    return (
      <div className='d-flex align-items-center justify-content-center' style={{ height: '10rem' }}>
        <Loader type='ball-pulse' color={primary_color} />
      </div>
    )

  return (
    <div>
      <div style={{ height: '35rem', overflow: 'auto' }}>
        <Table bordered style={{ height: '10rem', overflow: 'auto' }}>
          <thead>
            <tr>
              <th className='subtitle1 font-weight-bold py-3' style={{ color: primary_color }}>
                Block Name
              </th>
              <th className='subtitle1 font-weight-bold py-3' style={{ color: primary_color }}>
                Tier
              </th>
              <th className='subtitle1 font-weight-bold py-3' style={{ color: primary_color }}>
                Rate
              </th>
            </tr>
          </thead>
          <tbody>
            {tierData?.map((item, rowIndex) => {
              return (
                <tr key={item.title}>
                  <td className='subtitle1'>{item?.location_name}</td>
                  <td className='subtitle1'>{item.tier}</td>
                  <td
                    className=' subtitle1'
                    onClick={() => handleDoubleClick(rowIndex, 0)}
                    style={{
                      backgroundColor: editableCell.row === rowIndex && editableCell.col === 0 ? '#049FDB1A' : 'transparent',
                      width: '7rem',
                    }}>
                    {editableCell.row === rowIndex && editableCell.col === 0 ? (
                      <input
                        type='text'
                        value={item.rate}
                        onChange={(e) => handleChange(rowIndex, e.target.value)}
                        onBlur={() => setEditableCell({ row: null, col: null })}
                        // disabled={isBlockEdit}
                        autoFocus
                        style={{
                          // backgroundColor: '#049FDB1A',
                          border: 'none',
                          outline: 'none',
                          width: '100%',
                        }}
                      />
                    ) : (
                      item?.rate
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
      <div className='d-flex justify-content-end mt-5'>
        {' '}
        <button
          className='btn rounded-pill mt-2 text-danger border-danger mr-3'
          type='button'
          id='close-btn'
          onClick={(e) => {
            onClose()
            e.preventDefault()
            e.stopPropagation()
          }}
          style={{ fontWeight: 700, width: '12rem' }}>
          Close
        </button>
        <button
          className='btn text-white rounded-pill mt-2 mr-3'
          disabled={!!submitting}
          type='submit'
          id='save-button'
          onClick={() => {
            onSubmitForm()
          }}
          style={{ background: primary_color, fontWeight: 700, width: '14rem' }}>
          {submitting ? 'Saving...' : 'Save'}
        </button>
        <button
          className='btn text-white rounded-pill mt-2'
          disabled={!!submitting}
          onClick={() => {
            onSubmitForm(true)
          }}
          type='submit'
          style={{ background: primary_color, fontWeight: 700, width: '14rem' }}>
          Save & Close
        </button>
      </div>
    </div>
  )
}

export default EditRateBySchool
