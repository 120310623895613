import { Col, Input, InputGroup, InputGroupAddon, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign, faPoundSign } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import { getLocalStorageItem } from '../../../Helper/cookieHelper'
import { request } from '../../../Redux/Sagas/requests/api'
import useMediaQuery from '../../../CustomHook/useMediaQuery'
import { primary_color } from '../../../Helper/uiHelper'
import AdminDirectEditSuccess from './adminDirectEditSuccess'
import { isSuccessResponse } from '../../../helper'

export const InputForAmount = ({ onChangeValue, value, id, isDisabled, label }) => {
  return (
    <div className='mb-3'>
      {' '}
      <Label>{label}</Label>
      <InputGroup>
        <InputGroupAddon addonType='prepand' style={{ cursor: 'pointer' }}>
          <div className='input-dollar-icon input-group-text'>
            <FontAwesomeIcon icon={faPoundSign} />
          </div>
        </InputGroupAddon>
        <Input
          type={'number'}
          onChange={(e) => {
            onChangeValue(e.target.value)
          }}
          id={id}
          value={value}
          disabled={isDisabled}
        />
      </InputGroup>
    </div>
  )
}

const AdminPaymentDetails = ({
  paymentDetails,
  setExtraPaymentAmount,
  extraPaymentAmount,
  pageData,
  setIsPaymentRequestPreviewOpen,
  setPaymentLinkData,
}) => {
  const location = useLocation()
  const history = useHistory()
  const searchParams = new URLSearchParams(location.search)
  const parent_id = searchParams.get('parent_id')
  const payment_id = searchParams.get('payment_id')
  const isDesktop = useMediaQuery('(min-width:768px)')

  let storage_timer = getLocalStorageItem('timer') && JSON.parse(getLocalStorageItem('timer'))
  let current_storage_timer = storage_timer?.[parent_id]

  let totalAmountSum = paymentDetails?.total_amount && parseInt(paymentDetails?.total_amount)
  extraPaymentAmount?.map((item) => {
    if (item?.amount) {
      totalAmountSum += parseInt(item?.amount)
    }
  })

  const onSendPaymentLink = () => {
    const additionalPayment = extraPaymentAmount?.map((item) => {
      return {
        transaction_type_id: item?.name?.value,
        amount: parseInt(item?.amount),
      }
    })
    const next_page = pageData?.child_pages?.find((item) => item?.name === 'locker_payment')

    request('send-payment-link', 'POST', {
      additional_payment: additionalPayment,
      session_key: current_storage_timer?.session_id,
      page_id: next_page?.uuid,
      parent_id: parseInt(parent_id),
      url: window.location.origin,
      payment_id: parseInt(payment_id),
    }).then((res) => {
      history.push('/' + res.data + '&isShowOnlyContent=true&is_admin=true&refresh_link=true')
    })
  }

  const onDirectEditBooking = () => {
    request('update-direct-booking-by-payment-id', 'POST', {
      session_key: current_storage_timer?.session_id,
      parent_id: parseInt(parent_id),
      payment_id: parseInt(payment_id),
    }).then((res) => {
      if (isSuccessResponse(res)) {
        history.push('/admin-direct-edit-success')
      } else {
        history.push('/admin-direct-edit-fail')
      }
    })
  }

  let isEditDirect = false
  if (totalAmountSum === 0) {
    isEditDirect = true
  }

  return (
    <>
      <Row>
        <Col md={6} sm={12}>
          {extraPaymentAmount?.map((item, i) => {
            return (
              <InputForAmount
                id={item?.value}
                label={item?.name?.label}
                value={item?.amount}
                onChangeValue={(v) => {
                  setExtraPaymentAmount((data) => {
                    let newData = data?.map((item, index) => {
                      if (index === i) {
                        return {
                          ...item,
                          amount: v,
                        }
                      } else {
                        return item
                      }
                    })

                    return newData
                  })
                }}></InputForAmount>
            )
          })}
          <InputForAmount id={'total'} label={'Total Amount'} value={paymentDetails?.total_amount} isDisabled={true}></InputForAmount>
        </Col>
      </Row>
      <div className='d-flex mt-4'>
        <button
          className='btn text-danger '
          style={{
            borderRadius: '30px',
            background: 'transparent',
            border: '1px solid red',
            padding: isDesktop ? '0.4rem 5.5rem' : '0.6rem 3rem',
          }}
          onClick={() => {
            history.goBack()
            // onGoBackToHomePage()
          }}>
          Cancel
        </button>
        {isEditDirect ? (
          <button
            className='btn ml-3 d-flex justify-content-center py-3'
            style={{ color: 'white', borderRadius: '30px', background: primary_color, padding: isDesktop ? '0.4rem 4.5rem' : '0.6rem 2rem' }}
            onClick={onDirectEditBooking}
            type='submit'>
            <span>Edit Locker</span>
            <i class='fa-sharp fa-solid fa-arrow-right ml-2' style={{ marginTop: 4 }}></i>
          </button>
        ) : (
          <>
            {' '}
            <button
              className='btn ml-3 d-flex justify-content-center py-3'
              style={{ color: 'white', borderRadius: '30px', background: primary_color, padding: isDesktop ? '0.4rem 4.5rem' : '0.6rem 2rem' }}
              onClick={onSendPaymentLink}
              type='submit'>
              <span>Send payment Link</span>
              <i class='fa-sharp fa-solid fa-arrow-right ml-2' style={{ marginTop: 4 }}></i>
            </button>
            <button
              className='btn ml-3 d-flex justify-content-center py-3'
              style={{ color: 'white', borderRadius: '30px', background: primary_color, padding: isDesktop ? '0.4rem 4.5rem' : '0.6rem 2rem' }}
              onClick={() => {
                setIsPaymentRequestPreviewOpen(true)
              }}
              type='submit'>
              <span>Preview Email</span>
              <i class='fa-sharp fa-solid fa-arrow-right ml-2' style={{ marginTop: 4 }}></i>
            </button>
          </>
        )}
      </div>
    </>
  )
}

export default AdminPaymentDetails
