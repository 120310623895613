import { AvField } from 'availity-reactstrap-validation'
import { useDispatch, useSelector } from 'react-redux'
import { clearAllFieldInForm, setDomain, setDomains, setDropdownOptionsByPage } from '../../Redux/Reducers/CMS'
import { request } from '../../Redux/Sagas/requests/api'

const SelectDomainField = () => {
  const dispatch = useDispatch()
  const domain_id = useSelector((state) => state.CMS.selected_domain_id)
  const coreData = useSelector((state) => state.CMS.coreData)
  const domains = useSelector((state) => state.CMS.coreData.domains)

  const onToggleDomain = (id) => {
    let newDomains = domains?.map((domain) => {
      if (domain?.domain_id === id) {
        return {
          ...domain,
          active: true,
        }
      } else {
        return {
          ...domain,
          active: false,
        }
      }
    })
    dispatch(setDomains(newDomains))
    request('set-active-domain', 'POST', { domain_id: id })
  }
  return
  if (coreData.domains?.length < 1) return
  return (
    <AvField
      type='select'
      label='Select domain'
      name='domain'
      onChange={(e) => {
        onToggleDomain(parseInt(e.target.value))
        dispatch(setDomain(parseInt(e.target.value)))
        dispatch(setDropdownOptionsByPage({}))
        dispatch(clearAllFieldInForm(true))
        setTimeout(() => {
          dispatch(clearAllFieldInForm(false))
        }, 1000)
      }}
      value={domain_id}>
      {coreData.domains
        ?.filter((domain) => domain.selected)
        ?.map((option, key) => {
          return (
            <option value={option.domain_id} key={key}>
              {option.domain_name}
            </option>
          )
        })}
    </AvField>
  )
}

export default SelectDomainField
