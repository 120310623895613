import { useSelector } from 'react-redux'
import Loader from 'react-loaders'
import { useState } from 'react'
import { useFetchLogsByTable } from '../../../../Queries/Logs/useFetchLogsByTable'
import CreateVoucherLog from './createVoucherLog'
import CreateBlockLog from './createBlockLog'
import CreateLockers from './createLockersLog'
import DeleteLockerLog from './deleteLockerLog'
import RestoreLockerLog from './restoreLockerLog'
import RenumberLocker from './reNumberLocker'
import LockerMaintananceLog from './lockerMaintainanceLog'
import UpdateLog from './updateLogs'
import MoveWithinBlock from './moveWithinBlock'
import LockerReorder from './lockerReorder'
import EmptyLockerLog from './emptyLockerLog'
import AddLockerPriceLog from './addPrice'
import LockerReleaseLog from './lockerReleaseLog'
import EditBlockLog from './editBlockLog'
import CreateNotesLog from './createNotesLog'
import AssignVoucherLog from './assignVoucherLog'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import DeleteDomainLogs from './deleteDomainLogs'
import ReservePupilPremium from './reservePupilPremium'
import CreateUserLog from './createUserLog'

const renderLog = (type, data, onExpand, expandedId, parentPageName) => {
  switch (type) {
    case 'create_voucher':
      return <CreateVoucherLog data={data} onExpand={onExpand} expandedId={expandedId}></CreateVoucherLog>
    case 'create_block':
      return <CreateBlockLog data={data} onExpand={onExpand} expandedId={expandedId}></CreateBlockLog>
    case 'create_lockers':
      return <CreateLockers data={data} onExpand={onExpand} expandedId={expandedId}></CreateLockers>
    case 'locker_delete':
      return <DeleteLockerLog data={data} onExpand={onExpand} expandedId={expandedId}></DeleteLockerLog>
    case 'locker_restore':
      return <RestoreLockerLog data={data} onExpand={onExpand} expandedId={expandedId}></RestoreLockerLog>
    case 'locker_renumber':
      return <RenumberLocker data={data} onExpand={onExpand} expandedId={expandedId}></RenumberLocker>
    case 'locker_maintanance':
      return <LockerMaintananceLog data={data} onExpand={onExpand} expandedId={expandedId}></LockerMaintananceLog>
    case 'move_same_block':
      return <MoveWithinBlock data={data} onExpand={onExpand} expandedId={expandedId}></MoveWithinBlock>
    case 'empty_lockers':
      return <EmptyLockerLog data={data} onExpand={onExpand} expandedId={expandedId}></EmptyLockerLog>
    case 'locker_reorder':
      return <LockerReorder data={data} onExpand={onExpand} expandedId={expandedId}></LockerReorder>
    case 'add_price':
      return <AddLockerPriceLog data={data} onExpand={onExpand} expandedId={expandedId}></AddLockerPriceLog>
    case 'locker_maintenance':
      return <LockerMaintananceLog data={data} onExpand={onExpand} expandedId={expandedId}></LockerMaintananceLog>
    case 'release_maintenance':
      return <LockerReleaseLog data={data} onExpand={onExpand} expandedId={expandedId}></LockerReleaseLog>
    case 'edit_block':
      return <EditBlockLog data={data} onExpand={onExpand} expandedId={expandedId}></EditBlockLog>
    case 'update':
      return <UpdateLog data={data} onExpand={onExpand} expandedId={expandedId} parentPageName={parentPageName}></UpdateLog>
    case 'create_notes':
      return <CreateNotesLog data={data} onExpand={onExpand} expandedId={expandedId} parentPageName={parentPageName}></CreateNotesLog>
    case 'assign_voucher':
      return <AssignVoucherLog data={data} onExpand={onExpand} expandedId={expandedId} parentPageName={parentPageName}></AssignVoucherLog>
    case 'delete':
      return <DeleteDomainLogs data={data} onExpand={onExpand} expandedId={expandedId} parentPageName={parentPageName}></DeleteDomainLogs>
    case 'reserve_pupil_locker':
      return <ReservePupilPremium data={data} onExpand={onExpand} expandedId={expandedId} parentPageName={parentPageName}></ReservePupilPremium>
    case 'create_user':
      return <CreateUserLog data={data} onExpand={onExpand} expandedId={expandedId} parentPageName={parentPageName}></CreateUserLog>
    default:
      return
  }
}

const DomainLogs = ({ parentPageName }) => {
  const currentRow = useSelector((state) => state.CMS.currentRow)
  let role_id = currentRow?.[currentRow?.length - 1]?.uuid
  const [page, setPage] = useState()

  const { data, isLoading } = useFetchLogsByTable(role_id, 'domains',page)
  const [expandedId, setExpandedId] = useState()

  const onExpand = (id) => {
    setExpandedId(id)
  }

  if (!isLoading && data?.data?.length === 0)
    return (
      <div className='d-flex align-items-center justify-content-center'>
        <img src='not_found.png' style={{ width: '20rem' }}></img>
      </div>
    )
  if (data?.data?.length !== 0 && isLoading)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', height: '10rem', alignItems: 'center' }}>
        <Loader type='ball-pulse' color='blue' />
      </div>
    )

  return (
    <div>
      <div style={{ minHeight: '55vh' }}>
        {data?.data?.map((item, index) => {
          if (!expandedId) {
            return (
              <div key={index} className='p-1'>
                {renderLog(item?.type, item, onExpand, null, parentPageName)}
              </div>
            )
          } else {
            if (expandedId === item?.id) {
              return (
                <div key={index} className='p-1'>
                  {renderLog(item?.type, item, onExpand, expandedId, parentPageName)}
                </div>
              )
            } else {
              return <></>
            }
          }
        })}
      </div>

      <div className='d-flex justify-content-center mt-4'>
        <Pagination aria-label='Page navigation example' style={{ flexWrap: 'wrap' }}>
          {data?.links?.map((item, key) => {
            return (
              <PaginationItem active={item.active} disabled={item.url === null} key={key}>
                <PaginationLink
                  first
                  className='subtitle2'
                  onClick={() => {
                    if (item?.label?.toLowerCase()?.includes('previous')) {
                      setPage(parseInt(data?.current_page) - 1)
                    } else if (item?.label?.toLowerCase()?.includes('next')) {
                      setPage(parseInt(data?.current_page) + 1)
                    } else {
                      setPage(parseInt(item?.label))
                    }
                  }}>
                  {item.label.replace('&laquo;', '').replace('&raquo;', '')}
                </PaginationLink>
              </PaginationItem>
            )
          })}
        </Pagination>
      </div>
    </div>
  )
}

export default DomainLogs
