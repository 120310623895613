import { useDispatch, useSelector } from 'react-redux'
import { getNavFilterList } from '../../helper'
import AccessDenied from '../AccessDenied/accessDenied'
import PublicPageWrapper from '../../Components/publicPageWrapper'
import PublicPage from '../../CMSComponent/PageVariation/PublicPage/publicPage'
import CMSPage from '../../CMSComponent/page'
import { ErrorBoundary } from '../ErrorPage/errorBoundry'
import LoadingOverlayWrapper from '../loadingOverlay'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import Loader from 'react-loaders'
import { useEffect, useState } from 'react'
import { request } from '../../Redux/Sagas/requests/api'
import { setNav } from '../../Redux/Reducers/navigations'
import { setLocalStorageItem } from '../../Helper/cookieHelper'

const checkIsAuth = (navigation) => {
  if (window.location.pathname === '/') return false
  const nav = getNavFilterList(navigation?.InnerSidebarNav)?.map((item) => item.path)
  if (nav?.includes(window.location.pathname?.slice(1))) {
    return true
  } else {
    return false
  }
}

const checkPathExist = (navigation) => {
  const nav = getNavFilterList(navigation?.InnerSidebarNav)?.map((item) => item.path)
  navigation?.HeaderNav?.map((item) => nav?.push(item?.route))
  navigation?.FooterNav?.map((item) => nav?.push(item?.route))
  if (window.location.pathname === '/') return true
  if (nav?.includes(window.location.pathname?.slice(1))) {
    return true
  } else {
    return false
  }
}

const getPublicPageId = (navigation) => {
  let id
  navigation?.HeaderNav?.map((item) => {
    if (item?.route === window.location.pathname?.slice(1)) {
      id = item?.page_uuid
    }
  })
  navigation?.FooterNav?.map((item) => {
    if (item?.route === window.location.pathname?.slice(1)) {
      id = item?.page_uuid
    }
  })
  return id
}
const DynamicRoute = () => {
  const navigation = useSelector((state) => state.Navigations)
  const { id: page_url } = useParams()

  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)

  // const { isLoading } = useFetchNav(navigation.lastUpdated === null)

  useEffect(() => {
    setIsLoading(true)

    request('get-navigation').then((res) => {
      setIsLoading(false)
      if (res?.data) {
        dispatch(setNav(res?.data))
      }
    })
  }, [])

  useEffect(() => {
    setLocalStorageItem('currentURL', window.location.pathname + window.location.search)
  }, [])

  const defaultRoute = navigation?.HeaderNav?.find((item) => item.main_page)

  if (isLoading) {
    return <Loader />
  }

  if (window.location.pathname === '/' && defaultRoute) {
    return (
      <ErrorBoundary key={page_url}>
        <PublicPageWrapper>
          <PublicPage page_uuid={defaultRoute.page_uuid} />
        </PublicPageWrapper>
      </ErrorBoundary>
    )
  }

  if (checkPathExist(navigation)) {
    if (checkIsAuth(navigation)) {
      return (
        <ErrorBoundary key={page_url}>
          <LoadingOverlayWrapper>
            <CMSPage url={page_url} key={page_url} />
          </LoadingOverlayWrapper>
        </ErrorBoundary>
      )
    } else {
      return (
        <PublicPageWrapper>
          <ErrorBoundary key={page_url}>
            <PublicPage page_uuid={getPublicPageId(navigation)} />
          </ErrorBoundary>
        </PublicPageWrapper>
      )
    }
  } else {
    return <AccessDenied />
  }
}

export default DynamicRoute
