import { useEffect, useState } from 'react'
import { request } from '../../../Redux/Sagas/requests/api'
import { Col, Input, InputGroup, InputGroupAddon, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPoundSign } from '@fortawesome/free-solid-svg-icons'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { primary_color } from '../../../Helper/uiHelper'
import { isSuccessResponse } from '../../../helper'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUpdateApiData } from '../../../Redux/Reducers/CMS'

const MassRateChange = ({ isRateApproved }) => {
  const dispatch = useDispatch()
  const currentRow = useSelector((state) => state.CMS.currentRow)
  const [rateOptions, setRateOptions] = useState([])
  const [rate, setRate] = useState('0')
  const [rateType, setRateType] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    request('renewal-locations/get-rate-types').then((res) => {
      if (res?.data) {
        const options = res?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          }
        })
        setRateOptions(options)
      }
    })
  }, [])

  const onSubmit = () => {
    setLoading(true)
    request('renewal-locations/update-bulk-rate', 'POST', {
      rate_type_id: rateType ? parseInt(rateType) : undefined,
      rate: rate,
      item_date_time_id: currentRow?.[0]?.id,
    }).then((res) => {
      isSuccessResponse(res, 'Rate updated successfully')
      dispatch(fetchUpdateApiData())
      setLoading(false)
      setRate('')
    })
  }

  return (
    <Row>
      <Col md={2}>
        <AvForm
          value={rateType}
          onChange={(e) => {
            setRateType(e.target.value)
          }}>
          <label className='subtitle1'>Rate type</label>
          <AvField type='select' name='rate_type' disabled={isRateApproved}>
            <option value={''}>All School</option>
            {rateOptions?.map((item) => {
              return <option value={item?.value}>{item?.label}</option>
            })}
          </AvField>
        </AvForm>
      </Col>
      <Col md={2}>
        <label className='subtitle1'>Mass change by</label>
        <InputGroup>
          <InputGroupAddon addonType='prepand' style={{ cursor: 'pointer' }}>
            <div className='input-dollar-icon input-group-text'>
              <FontAwesomeIcon icon={faPoundSign} />
            </div>
          </InputGroupAddon>
          <Input
            type={'number'}
            className='subtitle1'
            value={rate}
            disabled={isRateApproved}
            onChange={(e) => {
              setRate(e.target.value)
            }}
          />
        </InputGroup>
  
      </Col>
      <div className='d-flex justify-content-start align-items-center' style={{ marginTop: '0.8rem' }}>
        <button
          className='btn text-white'
          disabled={loading || isRateApproved || parseFloat(rate) == 0 || !parseFloat(rate)}
          type='submit'
          onClick={() => {
            onSubmit()
          }}
          style={{ background: primary_color, fontWeight: 700 }}>
          Apply Rate
        </button>
      </div>
    </Row>
  )
}

export default MassRateChange
