import { store } from './Redux/Config/configureStore'

export const getAssignedManageRole = () => {
  const manage_role = store.getState()?.User?.user?.role_permission?.manage_roles
  return manage_role?.filter((item) => item?.enable)?.map((item) => item?.role_name)
}

export const getAssignedChangeRole = () => {
  const change_role = store.getState()?.User?.user?.role_permission?.assign_roles_to_users
  return change_role?.filter((item) => item?.enable)?.map((item) => item?.role_name?.toLowerCase())
}
