import { useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'
import { primary_color } from '../../Helper/uiHelper'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getNavFilterList } from '../../helper'
import CenteredLoader from '../../Components/loader'

const AccessDenied = () => {
  const navigation = useSelector((state) => state.Navigations)
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const navs = getNavFilterList(navigation?.InnerSidebarNav)
      const allowedPath = []
      navs?.map((item) => allowedPath?.push('/' + item.path))
      navigation?.HeaderNav?.map((item) => allowedPath?.push('/' + item?.route))
      navigation?.FooterNav?.map((item) => allowedPath?.push('/' + item?.route))
      if (!allowedPath?.includes(window?.location?.pathname)) {
        history.push('/logout')
      }
    }, 5000)

    // Cleanup function to clear the timeout on unmount
    return () => clearTimeout(timeoutId)
  }, [navigation, history])
  return <CenteredLoader />
  return (
    <div style={{ marginTop: '10rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ textAlign: 'center', fontSize: '1.3rem', marginBottom: '2rem' }}>
        You don't have permission to access. Please contact your admin!
      </div>
      <Button color='danger' onClick={() => history.push('/logout')}>
        Log out
      </Button>
    </div>
  )
}

export default AccessDenied
