import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import PageHeader from '../../Components/Permissions/PageHeader'
import { primary_color } from '../../Helper/uiHelper'

const PaymentRejected = () => {
  const location = useLocation()
  const history = useHistory()
  const searchParams = new URLSearchParams(location.search)
  const status = searchParams.get('status_text')
  return (
    <div>
      <div className='d-flex justify-content-center align-items-center' style={{ minHeight: '100vh' }}>
        <div style={{ width: '30rem' }}>
          <div className='rejected-pulse mb-5'>
            <i className={'position-absolute ' + 'fa-solid fa-check'} style={{ color: 'white', scale: '0.45' }}></i>
          </div>
          <div className='font-weight-bold body1 text-center mb-3 text-danger'>Payment Failed!</div>
          <div className='text-muted text-center subtitle1'>{status}</div>
          <div className='d-flex justify-content-center align-items-center mt-4'>
            <button
              className='btn text-white rounded-pill mt-2'
              type='submit'
              style={{ background: primary_color, fontWeight: 700, width: '14rem' }}
              onClick={() => {
                history.push('/')
              }}>
              Go Back
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentRejected
