import { store } from '../Config/configureStore'
import { request } from '../Sagas/requests/api'

export const EDIT_MODE = 'EDIT_MODE'
export const EDIT_PAGE_MODE = 'EDIT_PAGE_MODE'
export const SET_CMS = 'SET_CMS'
export const GET_CMS = 'GET_CMS'
export const CLEAR_CMS = 'CLEAR_CMS'
export const MODAL_VALUE = 'MODAL_VALUE'
export const EDIT_MODAL = 'EDIT_MODAL'
export const UPDATE_CMS = 'UPDATE_CMS'
export const CLOSE_FORM = 'CLOSE_FORM'
export const SUBMIT_CMS_FORM = 'SUBMIT_CMS_FORM'
export const FETCH_CORE_DATA = 'FETCH_CORE_DATA'
export const SET_CORE_DATA = 'SET_CORE_DATA'
export const UPDATE_CORE_DATA = 'UPDATE_CORE_DATA'
export const CLEAR_CORE_DATA = 'CLEAR_CORE_DATA'
export const SUBMIT_API = 'SUBMIT_API'
export const SUBMIT_COMMON_API = 'SUBMIT_COMMON_API'
export const SET_UPDATE_API_DATA = 'SET_UPDATE_API_DATA'
export const FETCH_UPDATE_API_DATA = 'FETCH_UPDATE_API_DATA'
export const SET_SCHEDULE_API_DATA = 'SET_SCHEDULE_API_DATA'
export const FETCH_SCHEDULE_API_DATA = 'FETCH_SCHEDULE_API_DATA'
export const SUBMIT_API_STRING = 'SUBMIT_API_STRING'
export const OPEN_MODAL = 'OPEN_MODAL'
export const SET_OPEN_MODAL = 'SET_OPEN_MODAL'
export const CLOSE_OPEN_MODAL = 'CLOSE_OPEN_MODAL'
export const CALL_CONNECT_API = 'CALL_CONNECT_API'
export const SET_CONNECT_API_DATA = 'SET_CONNECT_API_DATA'
export const CLEAR_CONNECT_API_DATA = 'CLEAR_CONNECT_API_DATA'
export const CALL_CONNECT_TEMPLATE_API = 'CALL_CONNECT_TEMPLATE_API'
export const SET_CONNECT_TEMPLATE_API_DATA = 'SET_CONNECT_TEMPLATE_API_DATA'
export const CLEAR_CONNECT_TEMPLATE_API_DATA = 'CLEAR_CONNECT_TEMPLATE_API_DATA'
export const CALL_CONNECT_TEMPLATE_RESTORE_API = 'CALL_CONNECT_TEMPLATE_RESTORE_API'
export const SET_LAST_DATA_CONNECTION = 'SET_LAST_DATA_CONNECTION'
export const CLEAR_LAST_DATA_CONNECTION = 'CLEAR_LAST_DATA_CONNECTION'
export const CALL_FREE_API = 'CALL_FREE_API'
export const SET_MENU_LIST = 'SET_MENU_LIST'
export const CLEAR_MENU_LIST = 'CLEAR_MENU_LIST'
export const SUBMIT_TOP_NAV_BUTTON = 'SUBMIT_TOP_NAV_BUTTON'
export const SUBMIT_PAGE = 'SUBMIT_PAGE'
export const SUBMIT_PAGE_CONTENT = 'SUBMIT_PAGE_CONTENT'
export const REMOVE_ITEM_BY_NAME = 'REMOVE_ITEM_BY_NAME'
export const REMOVE_TAB_BY_NAME = 'REMOVE_TAB_BY_NAME'
export const CALL_SUBMIT_REQUEST_API = 'CALL_SUBMIT_REQUEST_API'
export const SET_CURRENT_ROW_DATA = 'SET_CURRENT_ROW_DATA'
export const CLEAR_CURRENT_ROW_DATA = 'CLEAR_CURRENT_ROW_DATA'
export const CLEAR_CURRENT_ROW_ALL_DATA = 'CLEAR_CURRENT_ROW_ALL_DATA'
export const ADD_GRID_DATA_MODAL_OPEN = 'ADD_GRID_DATA_MODAL_OPEN'
export const ADD_GRID_DATA_MODAL_CLOSE = 'ADD_GRID_DATA_MODAL_CLOSE'
export const SET_SELECTED_DOMAIN = 'SET_SELECTED_DOMAIN'
export const SET_DOMAINS = 'SET_DOMAINS'
export const SET_TABLE_DROPDOWN_OPTION = 'SET_TABLE_DROPDOWN_OPTION'
export const CLEAR_TABLE_DROPDOWN_OPTION = 'CLEAR_TABLE_DROPDOWN_OPTION'
export const SET_GRID_SETTING = 'SET_GRID_SETTING'
export const SAVE_CURRENT_GRID_DATA_FOR_PRINT = 'SAVE_CURRENT_GRID_DATA_FOR_PRINT'
export const SET_CURRENT_URL = 'SET_CURRENT_URL'
export const UPDATE_CMS_VALUE = 'UPDATE_CMS_VALUE'
export const SET_TABLE_DROPDOWN_OPTION_FULL_DATA = 'SET_TABLE_DROPDOWN_OPTION_FULL_DATA'
export const ADD_DATA_ADDED = 'ADD_DATA_ADDED'
export const ADD_DATA_CLEARED = 'ADD_DATA_CLEARED'
export const SET_PARENT_TABLE_DATA = 'SET_PARENT_TABLE_DATA'
export const CLEAR_PARENT_TABLE_DATA = 'CLEAR_PARENT_TABLE_DATA'
export const CLEAR_PARENT_TABLE_ALL_DATA = 'CLEAR_PARENT_TABLE_ALL_DATA'
export const FIELD_CREATED = 'FIELD_CREATED'
export const CMS_EDIT_DEFAULT_VALUE = 'CMS_EDIT_DEFAULT_VALUE'
export const UPDATE_PAGE_DATA_REDUX = 'UPDATE_PAGE_DATA_REDUX'
export const REMOVE_PAGE_DATA_REDUX = 'REMOVE_PAGE_DATA_REDUX'
export const CLEAR_ALL_FIELDS_IN_FORM = 'CLEAR_ALL_FIELDS_IN_FORM'
export const SET_SELECTED_DROPDWON_DATA = 'SET_SELECTED_DROPDOWN_DATA'
export const CLEAR_ALL_CURRENT_ROW = 'CLEAR_ALL_CURRENT_ROW'
export const CLEAR_ALL_ROW_CLEARED_STATUS = 'CLEAR_ALL_ROW_CLEARED_STATUS'
export const SET_GRID_NOTE = 'SET_GRID_NOTE'
export const SET_TABLE_ROW_DATA = 'SET_TABLE_ROW_DATA'
export const SET_CONNECT_TEMPLATE_DATA_REDUX = 'SET_CONNECT_TEMPLATE_DATA_REDUX'
export const CLEAR_CONNECT_TEMPLATE_DATA_REDUX = 'CLEAR_CONNECT_TEMPLATE_DATA_REDUX'
export const STORE_DELETED_ROW_ID_REDUX = 'SET_STORE_DELETED_ROWID_REDUX'
export const SET_DROPDOWN_OPTIONS_DATA = 'SET_DROPDOWN_OPTIONS_DATA'
export const SET_GRID_FORM = 'SET_GRID_FORM'
export const CLEAR_GRID_FORM = 'CLEAR_GRID_FORM'
export const ADD_CHANGED_FIELD_ID_BY_FORM_NAME = 'ADD_CHANGED_FIELD_ID_BY_FORM_NAME'
export const REMOVE_CHANGED_FIELD_ID_BY_FORM_NAME = 'REMOVE_CHANGED_FIELD_ID_BY_FORM_NAME'
export const UPDATE_SUBMITTED_CHANGED_FIELD = 'UPDATE_SUBMITTED_CHANGED_FIELD'
export const CLEAR_SUBMITTED_CHANGED_FIELD = 'CLEAR_SUBMITTED_CHANGED_FIELD'
export const UPDATE_SYNC_ROW_ID_IN_REDUX = 'UPDATE_SYNC_ROW_ID_IN_REDUX'
export const CLEAR_SYNC_ROW_ID_IN_REDUX = 'CLEAR_SYNC_ROW_ID_IN_REDUX'
export const SET_COMPLETED_QUESTIONNAIRE_ROW = 'SET_COMPLETED_QUESTIONNAIRE_ROW'
export const SET_USER_SECURITY_OVERVIEW_DROPDOWN = 'SET_USER_SECURITY_OVERVIEW_DROPDOWN'
export const SAVED_FILTERS = 'SAVED_FILTERS'
export const SET_PERMISSION_PAGE_DATA_BY_USER_ID = 'SET_PERMISSION_PAGE_DATA_BY_USER_ID'
export const SET_PERMISSION_UPDATED_TIME = 'SET_PERMISSION_UPDATED_TIME'
export const SET_USER_PERMISSION_BY_ID_IN_REDUX = 'SET_USER_PERMISSION_BY_ID_IN_REDUX'
export const SET_FILTER_BY = 'SET_FILTER_BY'
export const SET_EXPAND_TABLE_DATA_BY_FILTER = 'SET_EXPAND_TABLE_DATA_BY_FILTER'
export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA'
export const SET_DASHBOARD_CMS_KEY = 'SET_DASHBOARD_CMS_KEY'
export const UPDATE_NAV_GROUP_REDUX = 'UPDATE_NAV_GROUP_REDUX'
export const ENABLE_OR_DESABLE_METHOD_TAB = 'ENABLE_OR_DISABLED_METHOD_TAB'
export const DROPDOWN_OPTIONS_REFETCH_TABLE = 'DROPDOWN_OPTIONS_REFETCH_TABLE'
export const SET_THEME_COLORS = 'SET_THEME_COLORS'
export const ADD_THEME_COLOR = 'ADD_THEME_COLOR'
export const REMOVE_THEME_COLOR = 'REMOVE_THEME_COLOR'

export const SET_ALL_SIDEBAR = 'SET_ALL_SIDEBAR'
export const ADD_SIDEBAR = 'ADD_SIDEBAR'
export const DELETE_SIDEBAR_BY_ID = 'DELETE_SIDEBAR_BY_ID'
export const PAGE_CRASHED = 'PAGE_CRASHED'
export const UPDATE_PROGRESS_DATA_REDUX = 'UPDATE_PROGRESS_DATA_REDUX'
export const UPDATE_PUBLIC_PAGE = 'UPDATE_PUBLIC_PAGE'
export const UPDATE_PUBLIC_PAGE_DATA_BY_UUID = 'UPDATE_PUBLIC_PAGE_DATA_BY_UUID'
export const REFETCH_PUBLIC_PAGE = 'REFETCH_PUBLIC_PAGE'
export const FETCH_PERMISSIONS = 'FETCH_PERMISSIONS'
export const CLEAR_PUBLIC_PAGE_DATA = 'CLEAR_PUBLIC_PAGE_DATA'
export const EDIT_PERMISSION_BY_PAGE = 'EDIT_PERMISSION_BY_PAGE'
export const SET_GRID_ADVANCED_FILTER = 'SET_GRID_ADVANCED_FILTER'
export const UPDATE_COLOR_PILL_BY_PAGE_NAME = 'UPDATE_COLOR_PILL_BY_PAGE_NAME'

const initialSate = {
  editMode: false,
  editPageMode: false,
  editPermissionByPage: false,
  lastDataConnectionData: {
    data: undefined,
    name: '',
    updatedAt: '',
    notFound: false,
    notFoundMessage: '',
  },
  openModal: {
    active: false,
    action: null,
    pageData: {},
  },
  cmsData: [],
  connectApiData: undefined,
  editModal: false,
  coreData: {},
  cmsModalValue: {},
  updateApiData: undefined,
  scheduleApiData: undefined,
  menuList: {},
  currentRow: [],
  addGridDataModal: false,
  tableDropDown: {},
  tableDropDownFullData: {},
  gridSetting: {},
  gridDataForPrint: {},
  currentUrl: '',
  addDataAdded: false,
  parentTableData: [],
  fieldCreatedStatus: false,
  cmsEditDefaultValue: {},
  clearAllFieldInForm: false,
  setSelectedDropdownData: {},
  allRowCleared: false,
  gridNote: [],
  tableRowData: {},
  connectTemplateDataRedux: {},
  deletedRowIdRedux: {},
  dropdownOptionsByPage: {},
  gridForm: {},
  changedField: {},
  submittedChangedField: {},
  updatedRowSyncId: {},
  completedQuestionnaireRow: {},
  userSecurityOverviewDropdown: {},
  savedFilters: {},
  permissionPageDataByUserId: {},
  permissionUpdatedTime: '',
  permissionByUserId: {},
  filterBy: {},
  expandTableDataByFilter: {},
  dashboardData: {},
  dashboardCMSKey: {},
  enabledOrDisabledMethodTab: [],
  dropdownOptionRefetchTable: [],
  themeColors: [],
  pageCrashed: false,
  progressData: {},
  publicPage: null,
  publicPageDataByUUID: null,
  refetchPublicPage: true,
  permissions: null,
  gridAdvancedFilter: {},
  colorPill: {},
}

export const setGridNote = (data) => ({
  type: SET_GRID_NOTE,
  data,
})
export const clearAllRowClearedStatus = () => ({
  type: CLEAR_ALL_ROW_CLEARED_STATUS,
})
export const clearAllCurrentRowFromRedux = (data) => ({
  type: CLEAR_ALL_CURRENT_ROW,
  data,
})
export const fetchCoreData = (cache = true) => ({
  type: FETCH_CORE_DATA,
  cache,
})

export const callConnectApi = (data) => ({
  type: CALL_CONNECT_API,
  data,
})

export const setDomain = (id) => ({
  type: SET_SELECTED_DOMAIN,
  id,
})

export const setDomains = (domains) => ({
  type: SET_DOMAINS,
  domains,
})

export const callConnectTemplateApi = (data) => ({
  type: CALL_CONNECT_TEMPLATE_API,
  data,
})

export const callConnectTemplateRestoreApi = (data) => ({
  type: CALL_CONNECT_TEMPLATE_RESTORE_API,
  data,
})

export const submitTopNavButton = (data) => ({
  type: SUBMIT_TOP_NAV_BUTTON,
  data,
})

export const submitPage = (data) => ({
  type: SUBMIT_PAGE,
  data,
})

export const submitPageContent = (data) => ({
  type: SUBMIT_PAGE_CONTENT,
  data,
})

export const removeItemByName = (data) => ({
  type: REMOVE_ITEM_BY_NAME,
  data,
})

export const removeTabByName = (data) => ({
  type: REMOVE_TAB_BY_NAME,
  data,
})

export const setMenuList = (menuList) => ({
  type: SET_MENU_LIST,
  menuList,
})

export const setCurrentRow = (row) => ({
  type: SET_CURRENT_ROW_DATA,
  row,
})

export const clearCurrentRowData = () => ({
  type: CLEAR_CURRENT_ROW_DATA,
})

export const setParentTableData = (data) => ({
  type: SET_PARENT_TABLE_DATA,
  data,
})

export const clearParentTableData = () => ({
  type: CLEAR_PARENT_TABLE_DATA,
})

export const clearParentTableAllData = () => ({
  type: CLEAR_PARENT_TABLE_ALL_DATA,
})

export const clearCurrentRowAllData = () => ({
  type: CLEAR_CURRENT_ROW_ALL_DATA,
})

export const clearMenuList = () => ({
  type: CLEAR_MENU_LIST,
})

export const callFreeApi = (data) => ({
  type: CALL_FREE_API,
  data,
})

export const submitRequest = (data) => ({
  type: CALL_SUBMIT_REQUEST_API,
  data,
})

export const setConnectApiData = (connectApiData) => ({
  type: FETCH_CORE_DATA,
  connectApiData,
})

export const clearConnectApiData = () => ({
  type: CLEAR_CONNECT_API_DATA,
})
export const setConnectTemplateApiData = (connectApiData) => ({
  type: FETCH_CORE_DATA,
  connectApiData,
})

export const clearConnectTemplateApiData = () => ({
  type: CLEAR_CONNECT_TEMPLATE_API_DATA,
})

export const setCurrentUrl = (url) => ({
  type: SET_CURRENT_URL,
  url,
})

export const addDataStatusSet = () => ({
  type: ADD_DATA_ADDED,
})

export const addDataStatusClear = () => ({
  type: ADD_DATA_CLEARED,
})

export const fieldCreatedStatus = (data) => ({
  type: FIELD_CREATED,
  data,
})
export const updatePageDataRedux = (data) => ({
  type: UPDATE_PAGE_DATA_REDUX,
  data,
})

export const removePageDataRedux = (pageName) => ({
  type: REMOVE_PAGE_DATA_REDUX,
  pageName,
})
export const clearAllFieldInForm = (data) => ({
  type: CLEAR_ALL_FIELDS_IN_FORM,
  data,
})

export const setSelectedDropdownData = (data) => ({
  type: SET_SELECTED_DROPDWON_DATA,
  data,
})

export const setTableRowDataInRedux = (data) => ({
  type: SET_TABLE_ROW_DATA,
  data,
})

export const setConnectTemplateDataRedux = (data) => ({
  type: SET_CONNECT_TEMPLATE_DATA_REDUX,
  data,
})
export const clearConnectTemplateReduxData = () => ({
  type: CLEAR_CONNECT_TEMPLATE_DATA_REDUX,
})

export const setDeletedRowIdInRedux = (data) => ({
  type: STORE_DELETED_ROW_ID_REDUX,
  data,
})

export const setDropdownOptionsByPage = (data) => ({
  type: SET_DROPDOWN_OPTIONS_DATA,
  data,
})

export const setGridFormInRedux = (data) => ({
  type: SET_GRID_FORM,
  data,
})

export const clearGridFormInRedux = () => ({
  type: CLEAR_GRID_FORM,
})

export const addChangedFieldIdByFormName = (data) => ({
  type: ADD_CHANGED_FIELD_ID_BY_FORM_NAME,
  data,
})
export const clearChangedFieldIdByFormName = (data) => ({
  type: REMOVE_CHANGED_FIELD_ID_BY_FORM_NAME,
  data,
})
export const updateSubmittedChangedField = (data) => ({
  type: UPDATE_SUBMITTED_CHANGED_FIELD,
})
export const clearSubmittedChangedField = (data) => ({
  type: CLEAR_SUBMITTED_CHANGED_FIELD,
})

export const setCompletedQuestionnaireRow = (data) => ({
  type: SET_COMPLETED_QUESTIONNAIRE_ROW,
  data,
})
export const setUserSecurityOverviewDropdown = (data) => ({
  type: SET_USER_SECURITY_OVERVIEW_DROPDOWN,
  data,
})

export const setPermissionUpdatedTime = () => ({
  type: SET_PERMISSION_UPDATED_TIME,
})

export const setPermissionByUserId = (data) => ({
  type: SET_USER_PERMISSION_BY_ID_IN_REDUX,
  data,
})

export const setExpandTableDataByFilter = (data) => ({
  type: SET_EXPAND_TABLE_DATA_BY_FILTER,
  data,
})

export const setDashboardDataInRedux = (data) => ({
  type: SET_DASHBOARD_DATA,
  data,
})

export const setDashboardCMSKey = (data) => ({
  type: SET_DASHBOARD_CMS_KEY,
  data,
})

export const updateNavGroupRedux = (data) => ({
  type: UPDATE_NAV_GROUP_REDUX,
  data,
})

export const updateProgressDataRedux = (data) => ({
  type: UPDATE_PROGRESS_DATA_REDUX,
  data,
})

export const refetchPublicPage = (data) => ({
  type: REFETCH_PUBLIC_PAGE,
  data,
})

export const savePermissionsInStore = (data) => ({
  type: FETCH_PERMISSIONS,
  data,
})

export const updateGridAdvancedFilter = (data) => ({
  type: SET_GRID_ADVANCED_FILTER,
  data,
})

const getBlob = () => {}

export const triggerActions = (actions, data, id) => { 
  const dispatch = store.dispatch
  if (!actions) return {}
  
  for (let i = 0; i < actions.length; i++) {
    switch (actions[i].component_name) {
      case 'submit_api_string':
        let updateData = actions[i]
        const oldUrl = updateData.url
        if (updateData.url) {
          const urlParam = updateData.url.match(/{([^}]+)}/)
          if (urlParam && urlParam.length > 1) {
            if (data.length > 0) {
              data.map((item) => {
                if (item.name == urlParam[1] && item.value) {
                  updateData.url = updateData.url.replace('{' + urlParam[1] + '}', item.value)
                } else {
                  updateData.url = updateData.url.replace('{' + urlParam[1] + '}', id)
                }
              })
            } else {
              updateData.url = updateData.url.replace('{' + urlParam[1] + '}', id)
            }
          }
          request(updateData.url, 'POST', {}, {}, {}, {}, { responseType: 'arraybuffer' }).then((response) => {
            if (updateData.url?.includes('downloads-file')) {
              const blob = new Blob([response.data])
              const blobUrl = window.URL.createObjectURL(blob)
              const link = document.createElement('a')
              link.href = blobUrl
              link.download = response?.headers?.filename
              document.body.appendChild(link)
              link.click()
            }
            store.dispatch(fetchUpdateApiData())
          })

          updateData.url = oldUrl
          return
        } else {
          return { type: SUBMIT_API_STRING, data: updateData }
        }
      case 'open_modal':
        return dispatch(setOpenModal({
          active: true,
          pageData: data,
          action: actions[i],
        }))
      default:
        break
    }
  }
}

export const setCMS = (cmsData) => ({
  type: SET_CMS,
  cmsData,
})

export const setLastDataConnectionData = (lastDataConnectionData) => ({
  type: SET_LAST_DATA_CONNECTION,
  lastDataConnectionData,
})

export const clearLastDataConnectionData = () => ({
  type: CLEAR_LAST_DATA_CONNECTION,
})

export const setOpenModal = (openModal) => ({
  type: SET_OPEN_MODAL,
  openModal,
})

export const closeOpenModal = () => ({
  type: CLOSE_OPEN_MODAL,
})

export const fetchUpdateApiData = (cache = true) => ({
  type: FETCH_UPDATE_API_DATA,
  cache,
})

export const setUpdateApiData = (updateApiData) => ({
  type: SET_UPDATE_API_DATA,
  updateApiData,
})

export const fetchScheduleApiData = () => ({
  type: FETCH_SCHEDULE_API_DATA,
})

export const setScheduleApiData = (scheduleApiData) => ({
  type: SET_SCHEDULE_API_DATA,
  scheduleApiData,
})

export const submitApi = (data) => ({
  type: SUBMIT_API,
  data,
})

export const submitCommonApi = (data) => ({
  type: SUBMIT_COMMON_API,
  data,
})

export const setCoreData = (coreData) => ({
  type: SET_CORE_DATA,
  coreData,
})

export const updateCoreData = (coreData) => ({
  type: UPDATE_CORE_DATA,
  coreData,
})

export const clearCoreData = () => ({
  type: CLEAR_CORE_DATA,
})

export const setModalValue = (cmsModalValue) => ({
  type: MODAL_VALUE,
  cmsModalValue,
})

export const setCMSDefaultValue = (value) => ({
  type: CMS_EDIT_DEFAULT_VALUE,
  value,
})

export const editModal = (editModal) => ({
  type: EDIT_MODAL,
  editModal,
})

export const submitCmsModal = () => ({
  type: SUBMIT_CMS_FORM,
})

export const closeModal = () => ({
  type: CLOSE_FORM,
})

export const editMode = (editMode) => ({
  type: EDIT_MODE,
  editMode,
})

export const editPermissionMode = (editPermissionByPage) => ({
  type: EDIT_PERMISSION_BY_PAGE,
  editPermissionByPage,
})

export const updateCMSKey = (data) => ({
  type: UPDATE_CMS_VALUE,
  data,
})

export const toggleEditPageMode = (editPageMode) => ({
  type: EDIT_PAGE_MODE,
  editPageMode,
})

export const getCMS = (cache) => ({
  type: GET_CMS,
  cache,
})

export const clearCMS = () => ({
  type: CLEAR_CMS,
})

export const addGridDataModalOpen = (mainGrid) => ({
  type: ADD_GRID_DATA_MODAL_OPEN,
  mainGrid,
})

export const addGridDataModalClose = () => ({
  type: ADD_GRID_DATA_MODAL_CLOSE,
})

export const setTableDropDown = (data) => ({
  type: SET_TABLE_DROPDOWN_OPTION,
  data,
})
export const setTableDropDownFullData = (data) => ({
  type: SET_TABLE_DROPDOWN_OPTION_FULL_DATA,
  data,
})

export const clearTableDropDown = () => ({
  type: CLEAR_TABLE_DROPDOWN_OPTION,
})

export const setGridSetting = (data) => ({
  type: SET_GRID_SETTING,
  data,
})

export const saveGridDataForPrint = (data) => ({
  type: SAVE_CURRENT_GRID_DATA_FOR_PRINT,
  data,
})
export const updateRowSyncIdInRedux = (data) => ({
  type: UPDATE_SYNC_ROW_ID_IN_REDUX,
  data,
})

export const clearSyncRowInInRedux = () => ({
  type: CLEAR_SYNC_ROW_ID_IN_REDUX,
})
export const saveFilter = (savedFilters) => ({
  type: SAVED_FILTERS,
  savedFilters,
})

export const setPagePermissionDataByUserId = (data) => ({
  type: SET_PERMISSION_PAGE_DATA_BY_USER_ID,
  data,
})

export const setFilterBy = (data) => ({
  type: SET_FILTER_BY,
  data,
})

export const enabledOrDisabledMethodTabAction = (data) => ({
  type: ENABLE_OR_DESABLE_METHOD_TAB,
  data,
})

export const setDropdownOptionRefetchTable = (data) => ({
  type: DROPDOWN_OPTIONS_REFETCH_TABLE,
  data,
})

export const setThemeColors = (data) => ({
  type: SET_THEME_COLORS,
  data,
})

export const addThemeColor = (data) => ({
  type: ADD_THEME_COLOR,
  data,
})

export const removeThemeColor = (uuid) => ({
  type: REMOVE_THEME_COLOR,
  uuid,
})

export const setAllSidebar = (sidebar) => ({
  type: SET_ALL_SIDEBAR,
  sidebar,
})

export const addSidebar = (data) => ({
  type: ADD_SIDEBAR,
  data,
})

export const deleteSidebarById = (id) => ({
  type: DELETE_SIDEBAR_BY_ID,
  id,
})

export const changePageCrashedStatus = (status) => ({
  type: PAGE_CRASHED,
  status,
})

export const updatePublicPage = (data) => ({
  type: UPDATE_PUBLIC_PAGE,
  data,
})

export const updatePublicPageDataByUUID = (data) => ({
  type: UPDATE_PUBLIC_PAGE_DATA_BY_UUID,
  data,
})

export const clearPublicPageData = () => ({
  type: CLEAR_PUBLIC_PAGE_DATA,
})

export const updateColorPillInRedux = (data) => ({
  type: UPDATE_COLOR_PILL_BY_PAGE_NAME,
  data,
})

export default (state = initialSate, action) => {
  switch (action.type) {
    case SET_CMS:
      const { cmsData } = action
      return { ...state, cmsData }
    case SET_CORE_DATA:
      const { coreData } = action
      return { ...state, coreData }
    case CLEAR_CORE_DATA:
      return { ...state, coreData: {} }
    case EDIT_MODE:
      const { editMode } = action
      return { ...state, editMode }
    case EDIT_PERMISSION_BY_PAGE:
      const { editPermissionByPage } = action
      return { ...state, editPermissionByPage }
    case EDIT_PAGE_MODE:
      const { editPageMode } = action
      return { ...state, editPageMode }
    case SET_OPEN_MODAL:
      const { openModal } = action
      return { ...state, openModal }
    case CLOSE_OPEN_MODAL:
      return {
        ...state,
        openModal: {
          active: false,
          action: null,
        },
      }
    case EDIT_MODAL:
      const { editModal } = action
      return { ...state, editModal }
    case SET_CONNECT_API_DATA:
      const { connectApiData } = action
      return { ...state, connectApiData }
    case CLEAR_CONNECT_API_DATA:
      return { ...state, connectApiData: undefined }
    case SET_CONNECT_TEMPLATE_API_DATA:
      const { connectApiData: data } = action
      return { ...state, data }
    case CLEAR_CONNECT_TEMPLATE_API_DATA:
      return { ...state, connectApiData: undefined }
    case MODAL_VALUE:
      const { cmsModalValue } = action
      return { ...state, cmsModalValue }
    case CMS_EDIT_DEFAULT_VALUE:
      return { ...state, cmsEditDefaultValue: action.value }
    case SET_UPDATE_API_DATA:
      const { updateApiData } = action
      return { ...state, updateApiData }
    case SET_LAST_DATA_CONNECTION:
      const { lastDataConnectionData } = action
      return { ...state, lastDataConnectionData }
    case CLEAR_LAST_DATA_CONNECTION:
      return {
        ...state,
        lastDataConnectionData: initialSate.lastDataConnectionData,
      }
    case SET_SCHEDULE_API_DATA:
      const { scheduleApiData } = action
      return { ...state, scheduleApiData }
    case SET_MENU_LIST:
      const { menuList } = action
      return { ...state, menuList }
    case CLEAR_MENU_LIST:
      return { ...state, menuList: {} }
    case CLOSE_FORM:
      return { ...state, editModal: false, cmsModalValue: {} }
    case SET_CURRENT_ROW_DATA:
      const { row } = action
      const oldCurrentRow = state.currentRow ? (JSON.stringify(state.currentRow)?.length > 2 ? state.currentRow : []) : []
      return { ...state, currentRow: [...oldCurrentRow, { ...row }] }
    case SET_PARENT_TABLE_DATA:
      return { ...state, parentTableData: [...state.parentTableData, { ...action.data }] }
    case CLEAR_PARENT_TABLE_DATA:
      const oldData = [...state.parentTableData]
      oldData.pop()
      return { ...state, parentTableData: oldData }
    case CLEAR_CURRENT_ROW_DATA:
      const oldState = state.currentRow && JSON.stringify(state.currentRow)?.length > 2 ? [...state.currentRow] : []
      oldState.pop()
      return { ...state, currentRow: oldState }
    case CLEAR_CURRENT_ROW_ALL_DATA:
      return { ...state, currentRow: [], allRowCleared: true }
    case CLEAR_PARENT_TABLE_ALL_DATA:
      return { ...state, parentTableData: [] }
    case CLEAR_CMS:
      return {
        ...initialSate,
        publicPage: state.publicPage,
        publicPageFetched: state.publicPageFetched,
        publicPageDataByUUID: state.publicPageDataByUUID,
      }

    case ADD_GRID_DATA_MODAL_OPEN:
      return { ...state, addGridDataModal: true, addMainGridData: action.mainGrid }
    case ADD_GRID_DATA_MODAL_CLOSE:
      return { ...state, addGridDataModal: false, addMainGridData: {} }
    case SET_SELECTED_DOMAIN:
      return { ...state, selected_domain_id: action.id }
    case SET_DOMAINS:
      return {
        ...state,
        coreData: {
          ...state.coreData,
          domains: action.domains,
        },
      }
    case SET_TABLE_DROPDOWN_OPTION:
      return {
        ...state,
        tableDropDown: { ...state.tableDropDown, ...action.data },
      }
    case SET_TABLE_DROPDOWN_OPTION_FULL_DATA:
      return {
        ...state,
        tableDropDownFullData: { ...state.tableDropDownFullData, ...action.data },
      }
    case CLEAR_TABLE_DROPDOWN_OPTION:
      return { ...state, tableDropDown: {}, tableDropDownFullData: {} }
    case SET_GRID_SETTING:
      return {
        ...state,
        gridSetting: { ...state.gridSetting, ...action.data },
      }
    case SAVE_CURRENT_GRID_DATA_FOR_PRINT:
      return {
        ...state,
        gridDataForPrint: { ...state.gridDataForPrint, ...action.data },
      }
    case SET_CURRENT_URL:
      return {
        ...state,
        currentUrl: action.url,
      }
    case UPDATE_CMS_VALUE:
      return {
        ...state,
        cmsData: action.data,
      }
    case ADD_DATA_ADDED:
      return {
        ...state,
        addDataAdded: true,
      }
    case ADD_DATA_CLEARED:
      return {
        ...state,
        addDataAdded: false,
      }
    case FIELD_CREATED:
      return {
        ...state,
        fieldCreatedStatus: action.data,
      }
    case CLEAR_ALL_FIELDS_IN_FORM:
      return {
        ...state,
        clearAllFieldInForm: action.data,
      }
    case SET_SELECTED_DROPDWON_DATA:
      return {
        ...state,
        selectedDropdownData: action.data,
      }
    case REMOVE_PAGE_DATA_REDUX: {
      const newPageData = { ...state.coreData.page }
      delete newPageData[action.pageName]
      return {
        ...state,
        coreData: {
          ...state.coreData,
          page: newPageData,
        },
      }
    }
    case UPDATE_PAGE_DATA_REDUX: {
      return {
        ...state,
        coreData: {
          ...state.coreData,
          page: {
            ...state.coreData.page,
            ...action.data,
          },
        },
      }
    }
    case CLEAR_ALL_ROW_CLEARED_STATUS: {
      return {
        ...state,
        allRowCleared: false,
      }
    }
    case CLEAR_ALL_CURRENT_ROW:
      return {
        ...state,
        clearAllCurrentRow: action.data,
      }
    case SET_GRID_NOTE:
      return {
        ...state,
        gridNote: action?.data,
      }
    case SET_TABLE_ROW_DATA:
      return {
        ...state,
        tableRowData: action.data,
      }
    case SET_CONNECT_TEMPLATE_DATA_REDUX:
      return {
        ...state,
        connectTemplateDataRedux: action.data,
      }
    case CLEAR_CONNECT_TEMPLATE_DATA_REDUX:
      return {
        ...state,
        connectTemplateDataRedux: {},
      }
    case STORE_DELETED_ROW_ID_REDUX:
      return {
        ...state,
        deletedRowIdRedux: action.data,
      }
    case SET_DROPDOWN_OPTIONS_DATA:
      return {
        ...state,
        dropdownOptionsByPage: action.data,
      }
    case SET_GRID_FORM:
      return {
        ...state,
        gridForm: action.data,
      }
    case CLEAR_GRID_FORM:
      return {
        ...state,
        gridForm: {},
      }
    case ADD_CHANGED_FIELD_ID_BY_FORM_NAME:
      return {
        ...state,
        changedField: action.data,
      }
    case REMOVE_CHANGED_FIELD_ID_BY_FORM_NAME:
      return {
        ...state,
        changedField: {},
      }
    case UPDATE_SUBMITTED_CHANGED_FIELD:
      return {
        ...state,
        submittedChangedField: { ...state.changedField },
        changedField: {},
      }
    case CLEAR_SUBMITTED_CHANGED_FIELD:
      return {
        ...state,
        submittedChangedField: {},
      }
    case UPDATE_SYNC_ROW_ID_IN_REDUX:
      return {
        ...state,
        updatedRowSyncId: action.data,
      }
    case CLEAR_SYNC_ROW_ID_IN_REDUX:
      return {
        ...state,
        updatedRowSyncId: {},
      }
    case SET_COMPLETED_QUESTIONNAIRE_ROW:
      return {
        ...state,
        completedQuestionnaireRow: action.data,
      }
    case SET_USER_SECURITY_OVERVIEW_DROPDOWN:
      return {
        ...state,
        userSecurityOverviewDropdown: action.data,
      }
    case SAVED_FILTERS:
      const { savedFilters } = action
      return { ...state, savedFilters }
    case SET_PERMISSION_PAGE_DATA_BY_USER_ID:
      return { ...state, permissionPageDataByUserId: action.data }
    case SET_PERMISSION_UPDATED_TIME:
      return { ...state, permissionUpdatedTime: new Date() }
    case SET_USER_PERMISSION_BY_ID_IN_REDUX:
      return { ...state, permissionByUserId: action.data }
    case SET_FILTER_BY:
      return { ...state, filterBy: action.data }
    case SET_EXPAND_TABLE_DATA_BY_FILTER:
      return { ...state, expandTableDataByFilter: action.data }
    case SET_DASHBOARD_DATA:
      return { ...state, dashboardData: action.data }
    case SET_DASHBOARD_CMS_KEY:
      return { ...state, dashboardCMSKey: action.data }
    case UPDATE_NAV_GROUP_REDUX:
      return { ...state, coreData: { ...state.coreData, nav: action.data } }
    case ENABLE_OR_DESABLE_METHOD_TAB:
      return { ...state, enabledOrDisabledMethodTab: action.data }
    case DROPDOWN_OPTIONS_REFETCH_TABLE:
      return { ...state, dropdownOptionRefetchTable: action.data }
    case SET_THEME_COLORS:
      return { ...state, themeColors: action.data }
    case ADD_THEME_COLOR:
      return { ...state, themeColors: [...state.themeColors, action.data] }
    case REMOVE_THEME_COLOR:
      return { ...state, themeColors: state.themeColors.filter((item) => item.uuid != action.uuid) }
    case SET_ALL_SIDEBAR:
      const { sidebar } = action
      return { ...state, coreData: { ...state.coreData, brands: sidebar } }
    case ADD_SIDEBAR:
      const { data: newSidebarData } = action
      const sidebars = [...state.coreData?.brands]
      sidebars?.push(newSidebarData)
      return { ...state, coreData: { ...state.coreData, brands: sidebars } }
    case DELETE_SIDEBAR_BY_ID:
      const { id } = action
      const old_sidebar = [...state.coreData?.brands]
      const new_sidebar = old_sidebar?.filter((item) => item?.uuid !== id)
      return { ...state, coreData: { ...state.coreData, brands: new_sidebar } }
    case PAGE_CRASHED:
      const { status } = action
      return { ...state, pageCrashed: status }
    case UPDATE_PROGRESS_DATA_REDUX:
      return { ...state, progressData: action.data }
    case UPDATE_PUBLIC_PAGE:
      return { ...state, publicPage: action?.data, publicPageFetched: true }
    case UPDATE_PUBLIC_PAGE_DATA_BY_UUID:
      return {
        ...state,
        publicPageDataByUUID: action.data,
      }
    case REFETCH_PUBLIC_PAGE:
      return {
        ...state,
        refetchPublicPage: action.data,
      }
    case FETCH_PERMISSIONS:
      return {
        ...state,
        permissions: action.data,
      }
    case CLEAR_PUBLIC_PAGE_DATA:
      return {
        ...state,
        publicPage: null,
        publicPageDataByUUID: null,
      }
    case SET_GRID_ADVANCED_FILTER:
      return { ...state, gridAdvancedFilter: action.data }
    case UPDATE_COLOR_PILL_BY_PAGE_NAME:
      return { ...state, colorPill: action.data }
    default:
      return state
  }
  return state
}
