import { AvField, AvForm } from 'availity-reactstrap-validation'
import moment from 'moment'
import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { Button, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker'
import { useDispatch } from 'react-redux'
import { setNotification } from '../../../../Redux/Reducers/notificationHandling'

const getDatePickerFormat = (type) => {
  switch (type) {
    case 'date':
      return
    case 'datetime':
      return
  }
}
const DateTimeTableFilter = ({ value, updateValue, triggerSearch, type }) => {
  const [openModal, setOpenModal] = useState()
  const [selectedDate, setSelectedDate] = useState()
  const [dateType, setDateType] = useState()

  const dispatch = useDispatch()

  useEffect(() => {
    if (value?.includes('<>')) {
      const [date1, date2] = value?.split(' <> ')
      const dates = []
      if (date1 && moment(date1).isValid()) {
        dates?.push(new Date(date1))
      }
      if (date2 && moment(date2).isValid()) {
        dates?.push(new Date(date2))
      }
      setSelectedDate(dates)
      setDateType('<>')
    } else if (value?.includes('=')) {
      const date = value?.replace(' =', '')
      if (date && moment(date).isValid()) {
        setSelectedDate(new Date(date))
        setDateType('=')
      }
    } else if (value?.includes('<')) {
      const date = value?.replace(' <', '')
      if (date && moment(date).isValid()) {
        setSelectedDate(new Date(date))
        setDateType('<')
      }
    } else if (value?.includes('>')) {
      const date = value?.replace(' >', '')
      if (date && moment(date).isValid()) {
        setSelectedDate(new Date(date))
        setDateType('>')
      }
    }
  }, [value])
  const onSave = () => {
    if (!dateType) {
      dispatch(setNotification({ type: 'error', message: 'Please select the type!' }))
    }
    if (!selectedDate) {
      dispatch(setNotification({ type: 'error', message: 'Please select the date!' }))
    }

    let date
    if (selectedDate) {
      if (dateType === '<>') {
        const [date1, date2] = selectedDate || []
        const date1Formatted = date1 && moment(date1)?.format('YYYY-MM-DD HH:mm')
        const date2Formatted = date2 && moment(date2)?.format('YYYY-MM-DD HH:mm')
        date = date1Formatted + ' <> ' + date2Formatted
      } else {
        let currentDate = moment(selectedDate)
        let formattedDate = currentDate.format('YYYY-MM-DD HH:mm')
        date = formattedDate + ' ' + dateType
      }
      triggerSearch(date)
      updateValue(date)
      setOpenModal(!openModal)
    }
  }

  const onClear = () => {
    setDateType()
    setSelectedDate(null)
    triggerSearch()
    updateValue()
    setOpenModal(!openModal)
  }
  let date_value = null
  if (value) {
    const date = moment(value)

    if (date.isValid()) {
      date_value = new Date(value)
    }
  }
  return (
    <div>
      <Dropdown
        isOpen={openModal}
        direction='bottom'
        toggle={() => {
          setOpenModal(!openModal)
          // setDateType()
        }}
        addonType={'prepend'}
        // className='border-0 btn-icon btn-icon-only fsize-1 m-2'
        id={'text_time_dropdown_date'}
        className='text-time-filter-button-container'>
        <DropdownToggle className='text-time-filter-button subtitle1'>{value ? 'change..' : 'search..'}</DropdownToggle>
        <DropdownMenu className='text-time-dropdown-menu p-3' style={{ width: '380px' }}>
          <AvForm>
            <label className='mb-2 mt-1 subtitle1'>Type</label>
            <AvField
              type='select'
              name='type'
              // label='type'
              className='subtitle1'
              onChange={(e) => {
                setDateType(e.target.value)
                setSelectedDate()
              }}
              value={dateType}>
              <option>Select Type</option>
              <option value='='>Equal</option>
              <option value='<'>Less than</option>
              <option value={'>'}>Greater than</option>
              <option value='<>'>Between</option>
            </AvField>
          </AvForm>
          <div className='mt-2'>
            {dateType === '<>' ? (
              <>
                <label className='mb-2 mt-1 subtitle1'>Select Range</label>{' '}
                <DateTimeRangePicker
                  onChange={(v) => {
                    if (Array.isArray(v)) {
                      setSelectedDate(v)
                    }
                  }}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode='select'
                  value={selectedDate}
                  popperPlacement='left'
                  className='subtitle1'
                />
              </>
            ) : (
              <>
                {' '}
                <label className='mb-2 mt-1 subtitle1'>Select Date</label>
                <DatePicker
                  selected={selectedDate}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode='select'
                  className='header-filter-date remove-outline subtitle1'
                  dateFormat='d MMMM , yyyy HH:mm a'
                  placeholderText='Search date...'
                  amPmAriaLabel={false}
                  showTimeInput={true}
                  // showTimeInput={type === 'datetime'|| type==='date-time'}
                  popperPlacement='left'
                  // readOnly={!inlineEditActive}
                  onChange={(e) => {
                    setSelectedDate(e)
                    // updateValue(e)
                    // triggerSearch(e)
                  }}
                />
              </>
            )}
          </div>

          <div style={{ marginTop: '1rem', display: 'flex', flexDirection: 'row-reverse' }}>
            <Button color='primary' className='subtitle1' onClick={() => onSave()}>
              Save
            </Button>
            <Button className='subtitle1' onClick={() => onClear()} style={{ marginRight: '1rem' }}>
              Clear
            </Button>
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  )

  switch (type) {
    case 'datetime':
    case 'date-time':
      return (
        <DatePicker
          selected={value}
          className='header-filter-date remove-outline'
          dateFormat='d MMMM , yyyy HH:mm a'
          placeholderText='Search date...'
          amPmAriaLabel={false}
          showTimeInput
          // readOnly={!inlineEditActive}
          onChange={(e) => {
            updateValue(e)
            triggerSearch(e)
          }}
        />
      )
    case 'date':
      return (
        <DatePicker
          selected={value}
          className='header-filter-date remove-outline'
          dateFormat='d MMMM , yyyy HH:mm a'
          placeholderText='Search date...'
          // readOnly={!inlineEditActive}
          onChange={(e) => {
            updateValue(e)
            triggerSearch(e)
          }}
        />
      )
    default:
      return (
        <DatePicker
          selected={value}
          className='header-filter-date remove-outline'
          dateFormat='d MMMM , yyyy HH:mm a'
          placeholderText='Search date...'
          // readOnly={!inlineEditActive}
          onChange={(e) => {
            updateValue(e)
            triggerSearch(e)
          }}
        />
      )
  }
}

export default DateTimeTableFilter
