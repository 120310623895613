import React, { useRef, useState } from 'react'
import useMediaQuery from '../../../CustomHook/useMediaQuery'
import { primary_color } from '../../../Helper/uiHelper'
import { cms } from '../../../helper'
import { useSelector } from 'react-redux'
import CMSWidgetModal from '../../CMSWidget/cmsWidgetModal'
import CMSNumberPoint from '../../CMSWidget/NumberPoint/cmsNumberPoint'

const PolicyBlock = ({ data, widget_uuid }) => {
  const isDesktop = useMediaQuery('(min-width:768px)')
  const editMode = useSelector((state) => state.CMS.editMode)

  const [modalPosition, setModalPosition] = useState()
  const sectionRef = useRef(null)

  const handleSectionClick = () => {
    if (!editMode) return
    if (sectionRef.current) {
      const rect = sectionRef.current.getBoundingClientRect()
      setModalPosition({
        // top: rect.top + window.scrollY - 50,
        top: rect.height + 150,

        //  left: 100, // Position below the parent element
        left: 0, // Align left edge with parent element
        width: '100%',
      })
    }
  }

  const onCloseModal = () => {
    let element = sectionRef?.current
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' })
    }
    setModalPosition(null)
  }
  return (
    <>
      {' '}
      <section style={{ marginTop: 10 }} ref={sectionRef} onClick={handleSectionClick} className={`m-2 mb-5 ${modalPosition && 'active-cms-edit'}`}>
        <div>
          {data?.map((item, index) => {
            let heading = item?.child_widget_data?.find((i) => i.component_name === 'heading')?.value
            let text = item?.child_widget_data?.find((i) => i.component_name === 'text')?.value

            let nextHeading = data[index + 1]?.child_widget_data?.find((i) => i.component_name === 'heading')?.value
            let nextText = data[index + 1]?.child_widget_data?.find((i) => i.component_name === 'text')?.value
            return (
              <>
                <div>
                  {heading && (
                    <h5 className='h5 font-weight-bold mb-3' style={{ color: primary_color }}>
                      {heading}
                    </h5>
                  )}
                  {text && (
                    <div
                      className='w-100 mb-0 number-list body1 text-muted'
                      dangerouslySetInnerHTML={{ __html: text }}
                      style={{ fontWeight: 300, maxWidth: 'calc(100% - 36px)', width: '100%' }}></div>
                  )}
                </div>
                {(nextHeading || nextText) && <hr className='mt-5 mb-5' style={{ marginLeft: '10vw', marginRight: '10vw' }}></hr>}
              </>
            )
          })}
        </div>
      </section>{' '}
      {modalPosition && (
        <CMSWidgetModal sx={{ ...modalPosition }} toggleModal={() => onCloseModal()}>
          <CMSNumberPoint data={data} toggleModal={() => onCloseModal()} widget_uuid={widget_uuid} />
        </CMSWidgetModal>
      )}
    </>
  )
}

export default PolicyBlock
