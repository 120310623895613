import moment from 'moment'
import { extractYears } from '../../../../helper'

const RenderColumnByFieldId = ({ field_id, row, gridRelatedData }) => {
  const { globalUIFilter } = gridRelatedData
  switch (field_id) {
    case 'domain_id':
      return row?.domain_name
    case 'block_id':
      const blocks = row?.block_id?.split(',')
      if (
        globalUIFilter?.block_reports?.acadamic_year?.value &&
        globalUIFilter?.block_reports?.acadamic_year?.value !== '5' &&
        globalUIFilter?.block_reports?.acadamic_year?.value !== 5
      )
        return
      return (
        <div className=''>
          {blocks?.map((item, i) => (
            <div key={i} className='mb-2 line-clamp-1'>
              {item}
            </div>
          ))}
        </div>
      )
    case 'status':
      const blocks_status = row?.status?.split('|')
      if (
        globalUIFilter?.block_reports?.acadamic_year?.value &&
        globalUIFilter?.block_reports?.acadamic_year?.value !== '5' &&
        globalUIFilter?.block_reports?.acadamic_year?.value !== 5
      )
        return
      return (
        <div>
          {blocks_status?.map((item, i) => (
            <div key={i} className='mb-2'>
              {item}
            </div>
          ))}
        </div>
      )
    case 'details':
      if (
        globalUIFilter?.block_reports?.acadamic_year?.value &&
        globalUIFilter?.block_reports?.acadamic_year?.value !== '5' &&
        globalUIFilter?.block_reports?.acadamic_year?.value !== 5
      )
        return

    const details = row?.details?.split('|')
      return (
        <div>
          {details?.map((item, i) => (
            <div key={i} className='mb-2'>
              {item}
            </div>
          ))}
        </div>
      )
    case 'item_date_time_id':
    case 'units_item_date_time':
    case 'booking_reservations.item_date_time_id':
      const item_date_time_id = row?.item_date_time_id_label || row?.locations?.[0]?.item_date_time?.title
      return <>{item_date_time_id && <div>{extractYears(item_date_time_id)}</div>}</>
    default:
      return
  }
}

const BlockReportColumn = ({ cell, row, gridRelatedData }) => {
  return (
    <td
      {...cell.getCellProps()}
      style={{ ...cell.column.commonStyle, alignItems: 'start' }}
      className={`rt-td py-4 bg-${cell.background_color}`}

      // className={'rt-td bg-' + cell.background_color + isCellInSync ? ' blue-border' : ''}
    >
      <div>
        <RenderColumnByFieldId field_id={cell.column.colData?.field_id} row={row} gridRelatedData={gridRelatedData}></RenderColumnByFieldId>
      </div>
    </td>
  )
}

export default BlockReportColumn
