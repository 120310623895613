import { useSelector } from 'react-redux'
import IconSet from '../../CMSComponent/icon'
import { primary_color } from '../../Helper/uiHelper'
import RoleBadge from './RoleBadge'

const AddNewRoleBlock = ({ text, setIsCreatingNewRole, setActiveRole, setActiveTab }) => {
  const isManagePermissionRoleEnabled = useSelector((state) => state.User?.user?.cmsSettingPermission?.add_role)?.[0]?.enabled
  if (!isManagePermissionRoleEnabled) return
  return (
    <div
      className={`d-flex w-12 role position-relative border flex align-items-center justify-content-center mt-sm-5`}
      style={{ fontSize: 16, fontWeight: 400, color: primary_color, borderRadius: 10 }}
      onClick={() => {
        setActiveRole()
        setIsCreatingNewRole(true)
        setActiveTab('0')
      }}>
      <IconSet icon='far fa-plus' color={'blue'} className='subtitle1' />
      <span style={{ marginLeft: 16 }} className='subtitle1'>
        {text}
      </span>
    </div>
  )
}

export default AddNewRoleBlock
