import { store } from '../Redux/Config/configureStore'
import { primary_color } from './uiHelper'
import { setQueueAfterCRUDops } from '../Redux/Reducers/queue'
import { getAssignedManageRole } from '../roleHelper'
export const getRolesFromPermissions = (data) => {
  const assignedRole = getAssignedManageRole()
  const roles = data
    ?.map((item) => {
      return {
        role: item?.role || item?.name,
        role_id: item?.role_id || item?.uuid,
        is_guest: item?.is_guest,
        is_default: item?.is_default,
        description: item?.description,
      }
    })
    ?.filter((item) => assignedRole?.includes(item?.role || item?.name))

  return roles
}

export const getPermissionBlockColor = (item, viewPermission, isMainPageViewEnabled, role_id, isCreatingNewRole, selectedPermissionToCreateRole) => {
  const reduxQueue = store.getState().Queue.queues
  let addPermissionArray = []
  const removePermissionArray = []

  if (isCreatingNewRole) {
    addPermissionArray = [...selectedPermissionToCreateRole]
  } else {
    reduxQueue?.map((queue) => {
      if (queue.url === 'update-role-wise-permission') {
        queue.payload?.data?.permissions?.map((permission) => {
          if (permission?.add_ids) {
            permission?.add_ids?.map((item) => {
              addPermissionArray?.push(item)
            })
          }
          if (permission?.remove_ids) {
            permission?.remove_ids?.map((item) => {
              removePermissionArray?.push(item)
            })
          }
        })
      }
    })
  }

  let bgColor = 'transparent'
  let textColor = '#FFF'
  let border = 'none'
  let isItemDisabled = false
  let isDisabled = false

  if (!isMainPageViewEnabled) {
    bgColor = '#EAEAEA'
    isDisabled = true
    return {
      bgColor,
      textColor,
      border,
      isDisabled,
    }
  }

  let isViewPermissionEnabled = false

  if (viewPermission && viewPermission?.enabled && !removePermissionArray?.includes(viewPermission?.permission_id)) {
    isViewPermissionEnabled = true
  }
  if (viewPermission && !viewPermission?.enabled && addPermissionArray?.includes(viewPermission?.permission_id)) {
    isViewPermissionEnabled = true
  }

  if (item?.permission !== 'view' && !isViewPermissionEnabled) {
    isItemDisabled = true
  }

  if (
    (item?.enabled || addPermissionArray?.includes(item?.permission_id)) &&
    item?.permission !== 'view' &&
    !removePermissionArray?.includes(item?.permission_id) &&
    !isItemDisabled
  ) {
    bgColor = primary_color
  } else if (isItemDisabled) {
    bgColor = '#EAEAEA'
    isDisabled = true
  } else if (
    (item?.enabled || addPermissionArray?.includes(item?.permission_id)) &&
    item?.permission === 'view' &&
    !removePermissionArray?.includes(item?.permission_id)
  ) {
    bgColor = '#059669'
  } else {
    textColor = '#8E8E8E'
    border = '1px solid #E3E3E3'
  }

  return {
    bgColor,
    textColor,
    border,
    isDisabled,
  }
}

export const addPermission = (role_id, permission_id) => {
  const reduxQueue = store.getState().Queue.queues

  const newQueue = []

  let isQueueFound = false
  reduxQueue?.map((queue) => {
    if (queue.url === 'update-role-wise-permission') {
      isQueueFound = true

      let data = JSON.parse(JSON.stringify(queue?.payload?.data))

      let isSameRoleFound = false
      let newPermissions = data?.permissions?.map((permission) => {
        if (permission?.role_id === role_id) {
          isSameRoleFound = true
          if (permission?.remove_ids?.includes(permission_id)) {
            let arr = permission?.remove_ids
            let index = arr.indexOf(permission_id)
            arr.splice(index, 1)
            permission['remove_ids'] = arr
          } else {
            permission?.add_ids?.push(permission_id)
          }
          return permission
        } else {
          return permission
        }
      })
      if (!isSameRoleFound) {
        newPermissions?.push({
          role_id,
          add_ids: [permission_id],
        })
      }
      data['permissions'] = newPermissions
      newQueue?.push({
        payload: {
          data: {
            permissions: newPermissions,
          },
        },
        url: 'update-role-wise-permission',
      })
    } else {
      newQueue?.push(queue)
    }
  })
  if (!isQueueFound) {
    newQueue?.push({
      url: 'update-role-wise-permission',
      payload: {
        data: {
          permissions: [
            {
              role_id,
              add_ids: [permission_id],
            },
          ],
        },
      },
    })
  }

  store.dispatch(setQueueAfterCRUDops(newQueue))
}

export const removePermission = (role_id, permission_id, type) => {
  const reduxQueue = store.getState().Queue.queues

  const newQueue = []

  let isQueueFound = false
  reduxQueue?.map((queue) => {
    if (queue.url === 'update-role-wise-permission') {
      isQueueFound = true

      let data = JSON.parse(JSON.stringify(queue?.payload?.data))

      let isSameRoleFound = false
      let newPermissions = data?.permissions?.map((permission) => {
        if (permission?.role_id === role_id) {
          isSameRoleFound = true
          if (permission?.add_ids?.includes(permission_id)) {
            let arr = permission?.add_ids
            let index = arr.indexOf(permission_id)
            arr.splice(index, 1)
            permission['add_ids'] = arr
          } else {
            if (permission?.remove_ids) {
              permission?.remove_ids?.push(permission_id)
            } else {
              permission['remove_ids'] = [permission_id]
            }
          }

          return permission
        } else {
          return permission
        }
      })
      if (!isSameRoleFound) {
        newPermissions?.push({
          role_id,
          remove_ids: [permission_id],
        })
      }
      data['permissions'] = newPermissions
      newQueue?.push({
        payload: {
          data: {
            permissions: newPermissions,
          },
        },
        url: 'update-role-wise-permission',
      })
    } else {
      newQueue?.push(queue)
    }
  })
  if (!isQueueFound) {
    newQueue?.push({
      url: 'update-role-wise-permission',
      payload: {
        data: {
          permissions: [
            {
              role_id,
              remove_ids: [permission_id],
            },
          ],
        },
      },
    })
  }
  store.dispatch(setQueueAfterCRUDops(newQueue))
}

export const updatePermission = (role_id, permission_id, enabled) => {
  const reduxQueue = store.getState().Queue.queues
  let isFound = false
  reduxQueue?.map((item) => {
    if (item?.url === 'update-role-wise-permission') {
      item?.payload?.data?.permissions?.map((p) => {
        if (p.role_id === role_id) {
          if (p.remove_ids?.includes(permission_id)) {
            isFound = true
            addPermission(role_id, permission_id)
          } else if (p.add_ids?.includes(permission_id)) {
            isFound = true
            removePermission(role_id, permission_id)
          }
        }
      })
    }
  })
  if (!isFound) {
    if (enabled) {
      removePermission(role_id, permission_id)
    } else {
      addPermission(role_id, permission_id)
    }
  }
}

export const addRolePermission = (role_id, permission_id, type) => {
  const reduxQueue = store.getState().Queue.queues

  const newQueue = []

  let isQueueFound = false
  reduxQueue?.map((queue) => {
    if (queue.url === 'update-role-wise-permission' && queue?.payload?.data?.type === type) {
      isQueueFound = true

      let data = JSON.parse(JSON.stringify(queue?.payload?.data))

      let isSameRoleFound = false
      let newPermissions = data?.permissions?.map((permission) => {
        if (permission?.role_id === role_id) {
          isSameRoleFound = true
          if (permission?.remove_ids?.includes(permission_id)) {
            let arr = permission?.remove_ids
            let index = arr.indexOf(permission_id)
            arr.splice(index, 1)
            permission['remove_ids'] = arr
          } else {
            permission?.add_ids?.push(permission_id)
          }
          return permission
        } else {
          return permission
        }
      })
      if (!isSameRoleFound) {
        newPermissions?.push({
          role_id,
          add_ids: [permission_id],
        })
      }
      data['permissions'] = newPermissions

      newQueue?.push({
        payload: {
          data: {
            permissions: newPermissions,
            type,
          },
        },
        url: 'update-role-wise-permission',
      })
    } else {
      newQueue?.push(queue)
    }
  })
  if (!isQueueFound) {
    newQueue?.push({
      url: 'update-role-wise-permission',
      payload: {
        data: {
          permissions: [
            {
              role_id,
              add_ids: [permission_id],
            },
          ],
          type,
        },
      },
    })
  }

  store.dispatch(setQueueAfterCRUDops(newQueue))
}

export const removeRolePermission = (role_id, permission_id, type) => {
  const reduxQueue = store.getState().Queue.queues

  const newQueue = []

  let isQueueFound = false
  reduxQueue?.map((queue) => {
    if (queue.url === 'update-role-wise-permission' && queue?.payload?.data?.type === type) {
      isQueueFound = true

      let data = JSON.parse(JSON.stringify(queue?.payload?.data))

      let isSameRoleFound = false
      let newPermissions = data?.permissions?.map((permission) => {
        if (permission?.role_id === role_id) {
          isSameRoleFound = true
          if (permission?.add_ids?.includes(permission_id)) {
            let arr = permission?.add_ids
            let index = arr.indexOf(permission_id)
            arr.splice(index, 1)
            permission['add_ids'] = arr
          } else {
            if (permission?.remove_ids) {
              permission?.remove_ids?.push(permission_id)
            } else {
              permission['remove_ids'] = [permission_id]
            }
          }

          return permission
        } else {
          return permission
        }
      })
      if (!isSameRoleFound) {
        newPermissions?.push({
          role_id,
          remove_ids: [permission_id],
        })
      }
      data['permissions'] = newPermissions
      newQueue?.push({
        payload: {
          data: {
            permissions: newPermissions,
            type,
          },
        },
        url: 'update-role-wise-permission',
      })
    } else {
      newQueue?.push(queue)
    }
  })
  if (!isQueueFound) {
    newQueue?.push({
      url: 'update-role-wise-permission',
      payload: {
        data: {
          permissions: [
            {
              role_id,
              remove_ids: [permission_id],
            },
          ],
          type,
        },
      },
    })
  }
  store.dispatch(setQueueAfterCRUDops(newQueue))
}

export const updateRolePermission = (role_id, permission_id, enabled, type) => {
  const reduxQueue = store.getState().Queue.queues
  let isFound = false
  reduxQueue?.map((item) => {
    if (item?.url === 'update-role-wise-permission' && item?.payload?.data?.type === type) {
      item?.payload?.data?.permissions?.map((p) => {
        if (p.role_id === role_id) {
          if (p.remove_ids?.includes(permission_id)) {
            isFound = true
            addRolePermission(role_id, permission_id, type)
          } else if (p.add_ids?.includes(permission_id)) {
            isFound = true
            removeRolePermission(role_id, permission_id, type)
          }
        }
      })
    }
  })
  if (!isFound) {
    if (enabled) {
      removeRolePermission(role_id, permission_id, type)
    } else {
      addRolePermission(role_id, permission_id, type)
    }
  }
}
