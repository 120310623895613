import Loader from 'react-loaders'
import { useFetchRoleWisePermission } from '../../../Queries/Permission/useGetRoleWisePermission'
import { ListGroup, ListGroupItem, UncontrolledCollapse } from 'reactstrap'
import PermissionOverviewRole from './permissionOverviewRole'

const PermissionOverview = () => {
  const { data, isLoading } = useFetchRoleWisePermission()

  if (isLoading) {
    return (
      <div className='d-flex justify-content-center' style={{ marginTop: '4rem', marginBottom: '8rem' }}>
        <Loader type='ball-pulse' color='blue' />
      </div>
    )
  }

  return (
    <div>
      <ListGroup>
        {data?.map((item) => {
          return (
            <div>
              <ListGroupItem
                className='subtitle1 cursor-pointer'
                id={'item' + item?.role_id?.replaceAll('-', '')}
                style={{ background: '#00000010' }}>
                {item?.role}
              </ListGroupItem>
              <UncontrolledCollapse toggler={'#' + 'item' + item?.role_id?.replaceAll('-', '')}>
                <PermissionOverviewRole permissions={item?.permissions}></PermissionOverviewRole>
              </UncontrolledCollapse>
            </div>
          )
        })}
      </ListGroup>
    </div>
  )
}

export default PermissionOverview
