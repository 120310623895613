import moment from 'moment'

import { getTabNameForLogs } from '../../../../Pages/Logs/logHelper'
import { primary_color } from '../../../../Helper/uiHelper'

const getValue = (data) => {
  return data?.name || data?.location?.name
}

const ShowDifference = ({ data, original_data, updated_data, onExpand, difference }) => {
  return (
    <div>
      <div className='permission-table'>
        <div className='mb-2 cursor-pointer' onClick={() => onExpand()}>
          <i class='fa-sharp fa-solid fa-arrow-left  m-3'></i>
          Go Back
        </div>
        <div className={`permission-header`} style={{ color: primary_color }}>
          <div className='d-flex align-items-center'>
            <div style={{ flex: 1 }}>
              <span className='font-weight-bold'>{data?.updated_by}</span>
              {`${data?.tab_name === 'planned_block' ? ' has created the Planned block' : ' has created the block'} `}{' '}
              <span className='font-weight-bold'>{original_data?.name}</span>
            </div>
            {data?.updated_at && <div>{moment.utc(data?.updated_at).format('MMM D  YYYY, HH:mm')}</div>}
          </div>
        </div>

        <div className='px-5 py-4'>
          <div className='mb-3'>
            <div className='font-weight-bold body2' style={{ color: primary_color }}>
              Name
            </div>
            <div className='subtitle1'>{original_data?.name}</div>
          </div>
          <div className='mb-3'>
            <div className='font-weight-bold body2' style={{ color: primary_color }}>
              Order
            </div>
            <div className='subtitle1'>{original_data?.order}</div>
          </div>
          <div className='mb-3'>
            <div className='font-weight-bold body2' style={{ color: primary_color }}>
              Block Type
            </div>
            <div className='subtitle1'>{original_data?.block_type_label}</div>
          </div>
          <div className='mb-3'>
            <div className='font-weight-bold body2' style={{ color: primary_color }}>
              Description
            </div>
            <div className='subtitle1'>{original_data?.locker_description}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

const CreateBlockLog = ({ data, onExpand, expandedId, parentPageName, isTemplate }) => {
  let updated_data = data?.updated_data ? JSON.parse(data?.updated_data) : {}
  let original_data = data?.original_data ? JSON.parse(data?.original_data) : {}

  if (expandedId) {
    return (
      <ShowDifference
        data={data}
        onExpand={onExpand}
        updated_data={updated_data}
        original_data={original_data ? JSON.parse(original_data) : {}}></ShowDifference>
    )
  }
  return (
    <div className='d-flex align-items-center p-3' style={{ borderBottom: '1px solid #e0e0e0' }}>
      <div>
        <i
          class='fa-solid fa-arrow-up-right-from-square mr-2 cursor-pointe'
          style={{ color: primary_color }}
          onClick={() => {
            onExpand(data?.id)
          }}></i>
      </div>
      <div style={{ flex: 1 }}>
        <span className='font-weight-bold'>{data?.updated_by}</span>
        {`${data?.tab_name === 'planned_block' ? ' has created the Planned block' : ' has created the block'} `}{' '}
        <span className='font-weight-bold'>{JSON.parse(original_data)?.name}</span>
      </div>
      {data?.updated_at && <div>{moment.utc(data?.updated_at).format('MMM D  YYYY, HH:mm')}</div>}
    </div>
  )
}

export default CreateBlockLog
