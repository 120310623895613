import ReactSwitch from 'react-switch'
import { primary_color } from '../../Helper/uiHelper'
import { useSelector } from 'react-redux'
import { updatePermission, updateRolePermission } from '../../Helper/permissionHelper'

const RenderItem = ({ item, addPermissionArray, removePermissionArray, type, role_id }) => {
  let isItemActive = false

  if (!item?.enable && addPermissionArray?.includes(item?.assigned_role_id)) {
    isItemActive = true
  }
  if (item?.enable && !removePermissionArray?.includes(item?.assigned_role_id)) {
    isItemActive = true
  }
  return (
    <div className='d-flex mb-4'>
      <ReactSwitch
        checked={isItemActive}
        onChange={() => {
          updateRolePermission(role_id, item?.assigned_role_id, item?.enable, type)
          // if (!isItemActive) {
          //   warningItem.current = item
          //   setIsWarning(true)
          // } else {
          //   updatePermission(role_id, item?.permission_id, item?.enabled)
          // }
        }}
        onColor={primary_color}
        uncheckedIcon={<i className='fa-thin fa-xmark switch-icon'></i>}
        checkedIcon={<i className='fa-thin fa-check switch-icon'></i>}
        className='d-inline'
      />
      <div className='ml-4 font-weight-bold subtitle1'>{item?.role_name}</div>
    </div>
  )
}

const ManagePermissionRole = ({ roles, type, role_id }) => {
  const reduxQueue = useSelector((state) => state.Queue.queues)
  const addPermissionArray = []
  const removePermissionArray = []
  reduxQueue?.map((queue) => {
    if (queue.url === 'update-role-wise-permission') {
      queue.payload?.data?.permissions?.map((permission) => {
        if (permission?.add_ids) {
          permission?.add_ids?.map((item) => {
            addPermissionArray?.push(item)
          })
        }
        if (permission?.remove_ids) {
          permission?.remove_ids?.map((item) => {
            removePermissionArray?.push(item)
          })
        }
      })
    }
  })
  return (
    <div className='content'>
      {roles?.map((item) => {
        return (
          <RenderItem
            item={item}
            addPermissionArray={addPermissionArray}
            removePermissionArray={removePermissionArray}
            type={type}
            role_id={role_id}
          />
        )
      })}
    </div>
  )
}

export default ManagePermissionRole
