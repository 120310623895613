import React, { Fragment, useEffect, useState } from 'react'
import cx from 'classnames'
import { connect, useDispatch, useSelector } from 'react-redux'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import { Button, DropdownMenu, DropdownToggle, Nav, NavItem, UncontrolledButtonDropdown } from 'reactstrap'

import HeaderLogo from '../AppLogo'
import SearchBox from './Components/searchBox'
import HeaderRightDrawer from './Components/headerRightDrawer'
import TopNavButtons from './Components/topNavButtons'
import IconSet from '../../CMSComponent/icon'
import { clearPageTab } from '../../Redux/Reducers/page'
import {
  can,
  checkCMSPermission,
  checkEditLabelPermission,
  checkPermissionToEditPermission,
  cms,
  fetchPermissions,
  renderBlockColorClass,
  renderBlockStyle,
  tidyName,
} from '../../helper'
import {
  toggleEditPageMode,
  editMode as updateCMSEditMode,
  fetchScheduleApiData,
  fetchUpdateApiData,
  closeOpenModal,
  clearParentTableAllData,
  clearCurrentRowAllData,
  setCoreData,
  clearChangedFieldIdByFormName,
  clearSubmittedChangedField,
  clearSyncRowInInRedux,
  setPermissionByUserId,
  setDashboardCMSKey,
  editPermissionMode,
} from '../../Redux/Reducers/CMS'
import { submitRequest } from '../../Redux/Reducers/CMS'
import { clearCommonQueue, clearGridQueue } from '../../Redux/Reducers/queue'
import bg4 from '../../assets/utils/images/dropdown-header/abstract4.jpg'
import { clearCurrentRowData } from '../../Redux/Reducers/CMS'
import useInterval from '@use-it/interval'
import { reduxLoad, reduxUnload } from '../../Redux/Reducers'
import { request } from '../../Redux/Sagas/requests/api'
import { useHistory } from 'react-router-dom'
import { setPublicUser } from '../../Redux/Reducers/user'
import { primary_color } from '../../Helper/uiHelper'

const select = (state) => ({
  enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
  editMode: state.CMS.editMode,
  editPermissionByPage: state.CMS.editPermissionByPage,
  editPageMode: state.CMS.editPageMode,
  queue: state.Queue,
  isPageFull: state.Page.isPageFull,
})

const getTitle = (queue) => {
  if (queue?.url === 'cms-update') {
    let key = queue?.payload?.data?.key
    return `CMS value not updated for ${cms(key)}...`
  } else if (queue?.url === 'save-default-form-data') {
    return `${cms(queue?.payload?.data?.form_name)} form not updated...`
  } else if (queue?.url === 'email-download') {
    return `Email is sending to ${queue?.payload?.data?.email}`
  } else if (queue?.url === 'insert-repeating-grid-data') {
    const source_table = queue?.payload?.data && Object.keys(queue.payload.data?.data)?.[0]
    const id = queue?.payload?.data?.data[source_table]?.id
    const parentTable = queue?.payload?.data?.parent_table
    if (!id) {
      return `Row added in ${tidyName(source_table)} ${parentTable ? `inside ${tidyName(parentTable)}` : ''}`
    } else {
      return `Row updated in ${tidyName(source_table)} which has id = ${id}  ${parentTable ? `inside ${tidyName(parentTable)}` : ''}`
    }
  } else if (queue?.url === 'connect-template-delete') {
    const source_table = queue?.payload?.data?.source_table
    const parentTable = queue?.payload?.data?.parent_table
    const ids = queue?.payload?.data?.ids?.join(', ')
    return `Row deleted in ${tidyName(source_table)} which has id = ${ids}  ${parentTable ? `inside ${tidyName(parentTable)}` : ''}`
  } else if (queue?.url === 'profile-save') {
    return 'Profile is updating'
  } else if (queue?.url === 'update-permissions') {
    return `Permission updating which has id=${queue?.payload?.data?.parent_row_id}`
  } else if (queue?.url === 'update-user-permissions') {
    return 'Updating User permission'
  } else if (queue?.url === 'send-email') {
    return `Sending reports to ${queue?.payload?.email}`
  } else if (queue?.url === 'send-grid-email-report') {
    return 'Sending Email Reports'
  }
}

const RenderLink = ({ hasQueue, queue }) => {
  const dispatch = useDispatch()
  const domain_id = useSelector((state) => state.CMS.selected_domain_id)
  if (hasQueue?.queues && hasQueue?.queues?.length > 0) {
    return (
      <>
        {hasQueue?.queues?.map((queue, i) => {
          return (
            <a className='nav-link' key={i}>
              <span>{getTitle(queue)}</span>
            </a>
          )
        })}
        <div
          className='mr-auto ml-auto badge badge-pill badge-info'
          onClick={() => {
            if (hasQueue?.queues && hasQueue?.queues?.length > 0) {
              hasQueue?.queues?.map((queue, index) => {
                setTimeout(() => {
                  dispatch(
                    submitRequest({
                      url: queue?.url,
                      data: queue?.payload?.data,
                      queue: true,
                    })
                  )
                }, 800 * index)
              })
              dispatch(clearCommonQueue())
              // dispatch(clearChangedFieldIdByFormName())
              dispatch(clearSubmittedChangedField())
              dispatch(clearSyncRowInInRedux())
              setTimeout(() => {
                // dispatch(fetchUpdateApiData())
                fetchPermissions()
                // dispatch(setPermissionByUserId({}))
              }, 1500)
            }
          }}>
          Click here to update
        </div>
      </>
    )
  } else {
    return (
      <a
        className='nav-link'
        onClick={() => {
          if (hasQueue?.queues && hasQueue?.queues?.length > 0) {
            hasQueue?.queues?.map((queue, index) => {
              setTimeout(() => {
                dispatch(
                  submitRequest({
                    url: queue?.url,
                    data: queue?.data,
                    queue: true,
                  })
                )
              }, 800 * index)
            })
          } else {
            dispatch(
              submitRequest({
                url: 'update-grid-column',
                data: { queue: queue.grid, domain_id },
                queue: true,
              })
            )
          }
          dispatch(clearCommonQueue())
          dispatch(clearGridQueue())
        }}>
        <span>Grid not synced.......</span>
        <div className='ml-auto badge badge-pill badge-info'>Click here to update</div>
      </a>
    )
  }
}

const AppHeader = ({
  queue,
  headerBackgroundColor,
  enableMobileMenuSmall,
  enableHeaderShadow,
  editMode,
  isPageFull,
  editPageMode,
  newNav,
  editPermissionByPage,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const Queue = useSelector((state) => state.Queue)

  const [hasQueue, setHasQueue] = useState({
    grid: false,
    queues: [],
    loading: false,
  })
  const coreData = useSelector((state) => state.CMS.coreData)
  const downLoadInProgress = useSelector((state) => state?.CMS?.updateApiData?.downloads?.downloads_in_progress)
  const user = useSelector((state) => state.User.user)

  const updateApiData = useSelector((state) => state.CMS?.updateApiData?.updated_data) || {}
  const lastUpdatedTimeInRedux = useSelector((state) => state.CMS.permissionUpdatedTime)
  const dashboardCMSKey = useSelector((state) => state.CMS.dashboardCMSKey) || {}

  let updateApiTime = downLoadInProgress?.length > 0 ? 1000 * 5 : 1000 * 60

  useEffect(() => {
    if (JSON.stringify(dashboardCMSKey || {}) === '{}') {
      request('get-dashboard-widgets-list', 'GET').then((res) => {
        dispatch(setDashboardCMSKey(res.data))
      })
    }
  }, [])

  useInterval(() => {
    if (localStorage.getItem('IS_INTERNET_ACTIVE')) {
    } else {
      dispatch(fetchScheduleApiData())
    }
  }, 1000 * 90)
  useInterval(() => {
    if (localStorage.getItem('IS_INTERNET_ACTIVE')) {
    } else {
      dispatch(fetchUpdateApiData())
    }
  }, updateApiTime)

  useEffect(() => {
    if (updateApiData['permissions'] && updateApiData['permissions']?.length > 0 && lastUpdatedTimeInRedux) {
      const gridUpdatedDate = lastUpdatedTimeInRedux && new Date(lastUpdatedTimeInRedux)
      var offset = gridUpdatedDate.getTimezoneOffset()
      const utcGridUpdated = new Date(gridUpdatedDate.getTime() + offset * 60000)
      const lastSyncDate = lastUpdatedTimeInRedux && new Date(utcGridUpdated)
      const lastUpdatedDateInDB = new Date(updateApiData['permissions'])
      if (lastSyncDate - lastUpdatedDateInDB < 0) {
        fetchPermissions()
        dispatch(reduxLoad())
      }
    }
  }, [updateApiData, lastUpdatedTimeInRedux])

  useEffect(() => {
    setHasQueue(queue)
  }, [queue])

  const redirectToPublic = () => {
    dispatch(setPublicUser(user))
    dispatch(reduxUnload())
    history.push('/')
  }
  const goToPublicPage = () => {
    const queues = Queue.queues
    if (queues?.length > 0) {
      queues?.map((queue, index) => {
        setTimeout(() => {
          dispatch(
            submitRequest({
              url: queue?.url,
              data: queue?.payload?.data,
              queue: true,
            })
          )
        }, 800 * index)
      })
      setTimeout(() => {
        redirectToPublic()
      }, 800 * queues.length + 1)
    } else {
      redirectToPublic()
    }
  }

  return (
    <Fragment>
      <CSSTransitionGroup
        component='div'
        className={cx('app-header', renderBlockColorClass(headerBackgroundColor), {
          'header-shadow': enableHeaderShadow,
          'edit-mode': editMode,
        })}
        transitionName='HeaderAnimation'
        transitionAppear={true}
        transitionAppearTimeout={1500}
        transitionEnter={false}
        transitionLeave={false}
        style={renderBlockStyle(headerBackgroundColor)}>
        <HeaderLogo />
        <div
          className={cx('app-header__content', {
            'header-mobile-open': enableMobileMenuSmall,
          })}>
          <div className='app-header-left'>
            <SearchBox />
            {checkCMSPermission() && (
              <Button
                color={editPageMode ? 'success' : 'primary'}
                outline={true}
                className={`btn btn-shadow  btn-wide float-right ml-3 ${editPageMode ? 'btn-outline-success' : 'btn-outline-primary'} py-2`}
                onClick={() => {
                  if (editPageMode && editMode) {
                    dispatch(updateCMSEditMode(!editMode))
                  }
                  dispatch(toggleEditPageMode(!editPageMode))
                }}>
                <IconSet icon={'far fa-pen-to-square'} className='mr-2' /> Edit Page
              </Button>
            )}

            {editPageMode && checkEditLabelPermission() && (
              <Button
                onClick={() => {
                  dispatch(updateCMSEditMode(!editMode))
                }}
                outline={true}
                className='btn btn-shadow  btn-wide float-right ml-3 py-2'
                color={editMode ? 'success' : 'primary'}>
                <IconSet icon='fa-regular fa-text-size' />
              </Button>
            )}
            {checkPermissionToEditPermission() && (
              <Button
                onClick={() => {
                  dispatch(editPermissionMode(!editPermissionByPage))
                }}
                outline={true}
                className='btn btn-shadow  btn-wide float-right ml-3 py-2'
                color={editPermissionByPage ? 'success' : 'primary'}>
                <IconSet icon='fa-solid fa-user-lock' />
              </Button>
            )}

            {!isPageFull && (
              <Button
                color='primary'
                className='ladda-button btn btn-shadow btn-pill btn-wide btn-focus float-right ml-2'
                onClick={() => {
                  dispatch(clearPageTab())
                  dispatch(clearCurrentRowData())
                  dispatch(clearParentTableAllData())
                  dispatch(clearCurrentRowAllData())

                  const updatedCoreData = {
                    ...coreData,
                    currentFormData: {},
                    currentFormFullData: {},
                  }
                  dispatch(setCoreData(updatedCoreData))
                }}>
                <IconSet icon={'far fa-arrow-left'} /> Back To List
              </Button>
            )}
          </div>
          <div className='app-header-right'>
            <Button
              className='mr-2 subtitle1'
              style={{ background: primary_color, border: 'none' }}
              onClick={() => {
                goToPublicPage()
              }}>
              Go to Public
            </Button>
            <UncontrolledButtonDropdown>
              <DropdownToggle
                color={Object.keys(queue.grid).length > 0 || hasQueue.queues.length > 0 ? 'success' : 'light'}
                style={{ borderRadius: '0.25rem' }}>
                <IconSet
                  color={Object.keys(queue.grid).length > 0 || hasQueue.queues.length > 0 ? 'light' : 'dark'}
                  icon={Object.keys(queue.grid).length > 0 || hasQueue.queues.length > 0 ? 'fa-solid fa-upload fa-bounce' : 'fa-solid fa-upload'}
                />
              </DropdownToggle>
              {(Object.keys(queue.grid).length > 0 || hasQueue.queues.length > 0) && (
                <DropdownMenu className='dropdown-menu-xl' style={{ minWidth: '35rem' }}>
                  <div className='dropdown-menu-header'>
                    <div className='dropdown-menu-header-inner bg-light'>
                      <div
                        className='menu-header-image opacity-5'
                        style={{
                          backgroundImage: 'url(' + bg4 + ')',
                        }}></div>
                      <div className='menu-header-content text-dark'>
                        <h5 className='menu-header-title'>{hasQueue.loading ? 'Synchronizing' : 'Synchronize'}</h5>
                      </div>
                    </div>
                  </div>
                  <Nav vertical>
                    <Nav vertical>
                      <NavItem className='nav-item-header'>Activity</NavItem>
                      <NavItem className='nav-item-divider' />
                      {hasQueue.loading ? (
                        <a className='nav-link'>
                          <span>Grid is updaing...</span>
                          <div className='ml-auto badge badge-pill badge-info'>updaing..</div>
                        </a>
                      ) : (
                        <RenderLink queue={queue} hasQueue={hasQueue} />
                      )}
                      <NavItem className='nav-item-divider' />
                    </Nav>
                  </Nav>
                </DropdownMenu>
              )}
            </UncontrolledButtonDropdown>

            <TopNavButtons />
            <HeaderRightDrawer />
          </div>
        </div>
      </CSSTransitionGroup>

    </Fragment>
  )
}

export default connect(select)(AppHeader)
