import moment from 'moment'

import { getTabNameForLogs } from '../../../../Pages/Logs/logHelper'
import { primary_color } from '../../../../Helper/uiHelper'

const getValue = (data) => {
  return data?.name || data?.location?.name
}

const ShowDifference = ({ data, original_data, updated_data, onExpand, difference }) => {
  return (
    <div>
      <div className='permission-table'>
        <div className='mb-2 cursor-pointer' onClick={() => onExpand()}>
          <i class='fa-sharp fa-solid fa-arrow-left  m-3'></i>
          Go Back
        </div>
        <div className={`permission-header`} style={{ color: primary_color }}>
          {' '}
          <div className='d-flex align-items-center'>
            <div style={{ flex: 1 }}>
              <span className='font-weight-bold'>{data?.updated_by}</span>
              {` has generated refund request`}
            </div>
            {data?.updated_at && <div>{moment.utc(data?.updated_at).format('MMM D  YYYY, HH:mm')}</div>}
          </div>
        </div>

        <div className='px-5 py-4'>
          <div className='mb-3'>
            <div className='font-weight-bold body2' style={{ color: primary_color }}>
              Total Amount
            </div>
            <div className='subtitle1'>{original_data?.amount}</div>
          </div>
          <div className='mb-3'>
            <div className='font-weight-bold body2' style={{ color: primary_color }}>
              Refund Amount
            </div>
            <div className='subtitle1'>{updated_data?.amount}</div>
          </div>
          <div className='mb-3'>
            <div className='font-weight-bold body2' style={{ color: primary_color }}>
              Reason
            </div>
            <div className='subtitle1' dangerouslySetInnerHTML={{ __html: updated_data?.reason }}></div>
          </div>
        </div>
      </div>
    </div>
  )
}

const RefundCreateLog = ({ data, onExpand, expandedId, parentPageName }) => {
  let updated_data = data?.updated_data && JSON.parse(data?.updated_data)
  let original_data = data?.original_data && JSON.parse(data?.original_data)

  let tab_name = getTabNameForLogs(parentPageName, data?.tab_name)

  if (expandedId) {
    return <ShowDifference data={data} onExpand={onExpand} updated_data={updated_data} original_data={original_data}></ShowDifference>
  }
  return (
    <div className='d-flex align-items-center p-3' style={{ borderBottom: '1px solid #e0e0e0' }}>
      <div>
        <i
          class='fa-solid fa-arrow-up-right-from-square mr-2 cursor-pointer'
          style={{ color: primary_color }}
          onClick={() => {
            onExpand(data?.id)
          }}></i>
      </div>
      <div style={{ flex: 1 }}>
        <span className='font-weight-bold'>{data?.updated_by}</span>
        {` has generated refund request`}
      </div>
      {data?.updated_at && <div>{moment.utc(data?.updated_at).format('MMM D  YYYY, HH:mm')}</div>}
    </div>
  )
}

export default RefundCreateLog
