import React, { useEffect } from 'react'
import { Button } from 'reactstrap'

import { cms } from '../../../helper'

const CMSInput = ({ data, id }) => {
  useEffect(() => {}, [data])
  return (
    <Button color='primary' className='mt-1 subtitle1' name={data.field_id} id={id}>
      {cms(data.cms_key)}
    </Button>
  )
}

export default CMSInput
