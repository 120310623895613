import Tabs, { TabPane } from 'rc-tabs'
import TabContent from 'rc-tabs/lib/TabContent'
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar'
import 'rc-tabs/assets/index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { cms, tidyName } from '../../helper'
import { useLockerYearContext } from '../../Context/lockerYearContext'

// const CustomPrevIcon = () => <div style={{ fontSize: '24px', display: 'flex', alignItems: 'center', marginTop:'19px' }}>⬅️</div>
const CustomPrevIcon = () => <FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: '24px', marginTop: '19px' }} />

// Custom right icon component
// const CustomNextIcon = () => <div style={{ fontSize: '24px', display: 'flex', alignItems: 'center', marginTop: '15px' }}>➡️</div>
const CustomNextIcon = () => <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: '24px', marginTop: '15px' }} />
const getCurrentRowName = (item) => {
  if (!item) return
  return (
    item['name'] ||
    item?.template_name ||
    item['domain_name'] ||
    item['full_name'] ||
    item['group_name'] ||
    item?.booking_id ||
    `${item['first_name']}`
  )
}

const RCTabs = ({ items, selectedTabKey, onChange: onChangeTab, activeTab, pageData, hideBreadcrumb }) => {
  const { state: lockerYearState, updateState: updateLockerYearState } = useLockerYearContext()

  const currentRow = useSelector((state) => state.CMS.currentRow)
  const name = getCurrentRowName(currentRow?.[0])
  // <div id='child-tab-1'></div>
  return (
    <Tabs
      defaultActiveKey='0'
      activeKey={activeTab?.toString() || '0'}
      onChange={(e) => onChangeTab(e)}
      renderTabBar={(props) => {
        return (
          <ScrollableInkTabBar
            inkBarStyle={{ backgroundColor: 'blue', height: '2px' }}
            {...props}
            prevIcon={<CustomPrevIcon />}
            nextIcon={<CustomNextIcon />}
          />
        )
      }}
      renderTabContent={() => <TabContent />}>
      {items?.map((tab, index) => {
        return (
          <TabPane tab={tab?.title} key={index} style={{ minHeight: '60vh' }}>
            {activeTab === index && (
              <div>
                {!hideBreadcrumb && !lockerYearState?.isLockerYearsPage && (
                  <div className='font-weight-bold mb-2 ml-3 mt-4 subtitle1' id={'tab-breadcrumb'}>
                    {tidyName(window.location.pathname.replace('-list', '').replace('/', ''))}&nbsp; &gt;&nbsp;
                    {cms(tab?.cmsTabKey)}&nbsp;&gt;&nbsp;{name}&nbsp;
                  </div>
                )}

                <div key={index}>{tab?.getContent()}</div>
              </div>
            )}
          </TabPane>
        )
      })}
    </Tabs>
  )
}

export default RCTabs
