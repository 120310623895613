import React, { createContext, useContext, useState } from 'react'

const LockerYearContext = createContext()

export const LockerYearContextProvider = ({ children }) => {
  const [state, setState] = useState({
    count: 0,
  })
  const updateState = (newState) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }))
  }

  return <LockerYearContext.Provider value={{ state, updateState }}>{children}</LockerYearContext.Provider>
}

export const useLockerYearContext = () => {
  return useContext(LockerYearContext)
}
