import { tidyName } from '../../../helper'
import PermissionOverviewPage from './permissionOverviewPage'

const PermissionOverviewRole = ({ permissions }) => {
  const pages = permissions?.page
  return (
    <div className='p-3' style={{ borderLeft: '1px solid rgba(0,0,0,.125)', borderRight: '1px solid rgba(0,0,0,.125)' }}>
      <div className='px-4'>
        {permissions?.cmsSetting &&
          Object.keys(permissions?.cmsSetting)?.map((key) => {
            return (
              <div className='d-flex '>
                <div className='flex-1 subtitle1' style={{ flex: 1 }}>
                  {tidyName(key)}
                </div>
                <div className='flex-1'>
                  {permissions?.cmsSetting?.[key]?.[0]?.enabled ? (
                    <span class='badge badge-success '>Yes</span>
                  ) : (
                    <span class='badge badge-danger '>No</span>
                  )}
                </div>
              </div>
            )
          })}
      </div>
      <div>
        <PermissionOverviewPage pages={pages}></PermissionOverviewPage>
      </div>
    </div>
  )
}

export default PermissionOverviewRole
