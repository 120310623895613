import { useSelector } from 'react-redux'

import { useState } from 'react'
import { useFetchLogsByTable } from '../../Queries/Logs/useFetchLogsByTable'
import UpdateLog from './updateLogs'
import MergedUserLogs from './mergedUserLog'
import CreateLog from './createLog'
import DeleteLog from './deleteLog'
import Loader from 'react-loaders'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

const renderLog = (type, data, onExpand, expandedId, parentPageName) => {
  switch (type) {
    case 'create':
      return <CreateLog data={data} onExpand={onExpand} expandedId={expandedId} parentPageName={parentPageName}></CreateLog>
    case 'update':
      return <UpdateLog data={data} onExpand={onExpand} expandedId={expandedId} parentPageName={parentPageName}></UpdateLog>
    case 'delete':
      return <DeleteLog data={data} onExpand={onExpand} expandedId={expandedId} parentPageName={parentPageName}></DeleteLog>
    case 'merge_update':
      return <MergedUserLogs data={data} onExpand={onExpand} expandedId={expandedId} parentPageName={parentPageName}></MergedUserLogs>
    default:
      return
  }
}

const TableLogs = ({ parentPageName }) => {
  const currentRow = useSelector((state) => state.CMS.currentRow)
  let role_id = currentRow?.[currentRow?.length - 1]?.uuid
  const [page,setPage] = useState()
  const { data, isLoading } = useFetchLogsByTable(role_id, 'users',page)

  const [expandedId, setExpandedId] = useState()

  const onExpand = (id) => {
    setExpandedId(id)
  }

  if (!isLoading && data?.data?.length === 0)
    return (
      <div className='d-flex align-items-center justify-content-center' style={{ marginTop: '3rem' }}>
        <img src='not_found.png' style={{ width: '20rem' }}></img>
      </div>
    )
  if (data?.data?.length !== 0 && isLoading)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', height: '10rem', alignItems: 'center' }}>
        <Loader type='ball-pulse' color='blue' />
      </div>
    )

  return (
    <div>
      <div style={{ minHeight: '55vh' }}>
        {data?.data?.map((item, index) => {
          if (!expandedId) {
            return <div key={index}>{renderLog(item?.type, item, onExpand, null, parentPageName)}</div>
          } else {
            if (expandedId === item?.id) {
              return (
                <div key={index} className='p-3'>
                  {renderLog(item?.type, item, onExpand, expandedId, parentPageName)}
                </div>
              )
            } else {
              return <></>
            }
          }
        })}
      </div>

      <div className='d-flex justify-content-center mt-4'>
        <Pagination aria-label='Page navigation example' style={{ flexWrap: 'wrap' }}>
          {data?.links?.map((item, key) => {
            return (
              <PaginationItem active={item.active} disabled={item.url === null} key={key}>
                <PaginationLink
                  first
                  className='subtitle2'
                  onClick={() => {
                    if (item?.label?.toLowerCase()?.includes('previous')) {
                      setPage(parseInt(data?.current_page) - 1)
                    } else if (item?.label?.toLowerCase()?.includes('next')) {
                      setPage(parseInt(data?.current_page) + 1)
                    } else {
                      setPage(parseInt(item?.label))
                    }
                  }}>
                  {item.label.replace('&laquo;', '').replace('&raquo;', '')}
                </PaginationLink>
              </PaginationItem>
            )
          })}
        </Pagination>
      </div>
    </div>
  )
}

export default TableLogs
