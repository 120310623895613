import moment from 'moment'
import { primary_color } from '../../../Helper/uiHelper'
import { cms } from '../../../helper'

const ShowDifference = ({ data, original_data, updated_data, onExpand }) => {
  return (
    <div>
      <div className='permission-table'>
        <div className='mb-2 cursor-pointer' onClick={() => onExpand()}>
          <i class='fa-sharp fa-solid fa-arrow-left  m-3'></i>
          Go Back
        </div>
        <div className={`permission-header`} style={{ color: primary_color }}>
          <div className='d-flex align-items-center'>
            <div style={{ flex: 1 }}>
              <span className='font-weight-bold'>{data?.updated_by}</span>
              {` has updated the value of `} <span className='font-weight-bold'>{cms(updated_data?.[0]?.cms_key)}</span> from{' '}
              <span className='font-weight-bold'>{original_data?.[0]?.Value}</span> to{' '}
              <span className='font-weight-bold'>{updated_data?.[0]?.Value}</span> {' in '}
              <span className='font-weight-bold'>Manage Tags</span>
            </div>
            {data?.updated_at && <div>{moment.utc(data?.updated_at).format('MMM D  YYYY, HH:mm')}</div>}
          </div>
        </div>
        <div className='p-5 mb-2'>
          <div className='d-flex mb-2 align-items-center' style={{ width: '100%' }}>
            <div className='font-weight-bold mb-2' style={{ width: '40%' }}>
              {cms(updated_data?.[0]?.cms_key)}:
            </div>

            <div style={{ width: '30%' }}>{updated_data?.[0]?.Value}</div>
            <div style={{ width: '30%' }} className={'p-2 bg-danger text-light'}>
              {original_data?.[0]?.Value}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const TagUpdateLog = ({ data, onExpand, expandedId }) => {
  let updated_data = data?.updated_data && JSON.parse(data?.updated_data)
  let original_data = data?.original_data && JSON.parse(data?.original_data)

  if (expandedId) {
    return <ShowDifference data={data} updated_data={updated_data} original_data={original_data} onExpand={onExpand}></ShowDifference>
  }
  return (
    <div className='d-flex align-items-center'>
      <div>
        <i
          class='fa-solid fa-arrow-up-right-from-square mr-2 cursor-pointer'
          style={{ color: primary_color }}
          onClick={() => {
            onExpand(data?.id)
          }}></i>
      </div>
      <div style={{ flex: 1 }}>
        <span className='font-weight-bold'>{data?.updated_by}</span>
        {` has updated the value of `} <span className='font-weight-bold'>{cms(updated_data?.[0]?.cms_key)}</span> from{' '}
        <span className='font-weight-bold'>{original_data?.[0]?.Value}</span> to <span className='font-weight-bold'>{updated_data?.[0]?.Value}</span>{' '}
        {' in '}
        <span className='font-weight-bold'>Manage Tags</span>
      </div>
      {data?.updated_at && <div>{moment.utc(data?.updated_at).format('MMM D  YYYY, HH:mm')}</div>}
    </div>
  )
}

export default TagUpdateLog
