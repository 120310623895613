import { primary_color } from '../../../Helper/uiHelper'

const AdminDirectEditSuccess = () => {
  return (
    <div className='d-flex justify-content-center align-items-center' style={{ minHeight: '20rem' }}>
      <div style={{ width: '30rem' }}>
        <div className='success-pulse mb-5'>
          <i className={'position-absolute ' + 'fa-solid fa-check'} style={{ color: 'white', scale: '0.45' }}></i>
        </div>
        <div className='font-weight-bold body1 text-center mb-3' style={{ color: primary_color }}>
          Successful !
        </div>
        <div className='text-muted text-center subtitle1'>
          Locker edited successfully. Please check booking logs.
        </div>  
      </div>
    </div>
  )
}

export default AdminDirectEditSuccess
