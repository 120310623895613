import { useSelector } from 'react-redux'
import { useFetchLogsByTable } from '../../Queries/Logs/useFetchLogsByTable'
import { useState } from 'react'
import WidgetDesginUpdate from './logs/widgetUpdate'
import EmailUpdateLog from './logs/emailUpdate'
import TagUpdateLog from './logs/tagUpdateLog'
import Loader from 'react-loaders'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

const renderLog = (type, data, onExpand, expandedId) => {
  switch (type) {
    case 'create':
      return
    case 'widget_update':
      return <WidgetDesginUpdate data={data} onExpand={onExpand} expandedId={expandedId}></WidgetDesginUpdate>
    case 'update':
      return <EmailUpdateLog data={data} onExpand={onExpand} expandedId={expandedId}></EmailUpdateLog>
    case 'tag_update':
      return <TagUpdateLog data={data} onExpand={onExpand} expandedId={expandedId}></TagUpdateLog>
    case 'role_update':
      return
    default:
      return
  }
}

const EmailLogs = () => {
  const [page, setPage] = useState()
  const currentRow = useSelector((state) => state.CMS.currentRow)
  let role_id = currentRow?.[currentRow?.length - 1]?.uuid
  const { data, isLoading } = useFetchLogsByTable(role_id, 'email_templates', page)

  const [expandedId, setExpandedId] = useState()

  const onExpand = (id) => {
    setExpandedId(id)
  }

  if (!isLoading && data?.data?.length === 0)
    return (
      <div className='d-flex align-items-center justify-content-center' style={{ marginTop: '3rem' }}>
        <img src='not_found.png' style={{ width: '20rem' }}></img>
      </div>
    )
  if (data?.data?.length !== 0 && isLoading)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', height: '10rem', alignItems: 'center' }}>
        <Loader type='ball-pulse' color='blue' />
      </div>
    )

  return (
    <div>
      <div style={{ minHeight: '55vh' }}>
        {' '}
        {data?.data?.map((item, index) => {
          if (!expandedId) {
            return (
              <div key={index} className='p-3' style={{ borderBottom: '1px solid #e0e0e0' }}>
                {renderLog(item?.type, item, onExpand, null)}
              </div>
            )
          } else {
            if (expandedId === item?.id) {
              return (
                <div key={index} className='p-3'>
                  {renderLog(item?.type, item, onExpand, expandedId)}
                </div>
              )
            } else {
              return <></>
            }
          }
        })}
      </div>

      <div className='d-flex justify-content-center mt-4'>
        <Pagination aria-label='Page navigation example' style={{ flexWrap: 'wrap' }}>
          {data?.links?.map((item, key) => {
            return (
              <PaginationItem active={item.active} disabled={item.url === null} key={key}>
                <PaginationLink
                  first
                  className='subtitle2'
                  onClick={() => {
                    if (item?.label?.toLowerCase()?.includes('previous')) {
                      setPage(parseInt(data?.current_page) - 1)
                    } else if (item?.label?.toLowerCase()?.includes('next')) {
                      setPage(parseInt(data?.current_page) + 1)
                    } else {
                      setPage(parseInt(item?.label))
                    }
                  }}>
                  {item.label.replace('&laquo;', '').replace('&raquo;', '')}
                </PaginationLink>
              </PaginationItem>
            )
          })}
        </Pagination>
      </div>
    </div>
  )
}

export default EmailLogs
