import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import HeaderNavButton from './navHeaderButton'
import { getFromRedux } from '../../helper'
import RemoveTopNavButton from './removeTopNavButton'
import { PROFILE_MODAL_DEFAULT_DATA } from './profileModalData'
import { FaUserCircle } from 'react-icons/fa'
import { primary_color } from '../../Helper/uiHelper'
import { reduxUnload } from '../../Redux/Reducers'
import { setPublicUser } from '../../Redux/Reducers/user'

const select = (state) => ({
  CMS: state.CMS,
  coreData: state.CMS.coreData,
  editPageMode: state.CMS.editPageMode,
  page: state.CMS.coreData.page,
  user: state.User.user,
})

const NavHeaderBtn = (props) => {
  const { navHeader, CMS, editPageMode, coreData, user } = props
  const history = useHistory()
  const dispatch = useDispatch()

  let isProfileNavAvailable = false
  navHeader?.forEach((nav) => {
    if (nav?.name === 'profile_form' || nav?.name === 'profiles' || nav?.name === 'profile') {
      isProfileNavAvailable = true
    }
  })

  return (
    <>
      {navHeader.map((nav, key) => {
        // if (!nav.allowed) return null
        return (
          <div key={key} className={nav.break_left ? 'header-btn-lg pr-0' : ''}>
            <div className='widget-content p-0'>
              <div className='widget-content-wrapper'>
                <div className='widget-content-left'>
                  {nav.name === 'profile_form' && !user?.photo && (
                    <FaUserCircle
                      style={{ color: primary_color, fontSize: '2rem' }}
                      className='cursor-pointer'
                      onClick={() => {
                        dispatch(setPublicUser(user))
                        dispatch(reduxUnload())
                        history.push('/profile-update?isFromDashboard=true')
                      }}
                    />
                  )}
                  {nav?.name === 'profile_form' && user?.photo && (
                    <img
                      src={process.env.REACT_APP_STORAGE + user?.photo}
                      height={'40px'}
                      width={'40px'}
                      style={{ borderRadius: '100%', objectFit: 'cover', cursor: 'pointer' }}
                      onClick={() => {
                        dispatch(setPublicUser(user))
                        dispatch(reduxUnload())
                        history.push('/profile-update?isFromDashboard=true')
                      }}></img>
                  )}
                  {nav?.name !== 'profile_form' && <HeaderNavButton nav={nav} coreData={coreData} />}
                  {editPageMode && <RemoveTopNavButton nav={nav} />}
                </div>

                {nav?.name === 'profile_form' && (
                  <div className='widget-content-left  ml-3 '>
                    <div className='widget-heading subtitle1'>{user?.first_name}</div>
                    <div className='widget-subheading subtitle1'>{user?.role?.name}</div>
                  </div>
                )}
                {nav.component_call_data_connection && nav.text_right_header && nav?.name !== 'profile_form' ? (
                  <div className='widget-content-left  ml-3 header-user-info'>
                    <div className='widget-heading'>{getFromRedux(nav.component_call_data_connection.name + '.' + nav.text_right_header)}</div>
                    <div className='widget-subheading'>{getFromRedux(nav.component_call_data_connection.name + '.' + nav.text_right_sub_header)}</div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )
      })}
      {!isProfileNavAvailable && (
        <div key={'profile'} className={PROFILE_MODAL_DEFAULT_DATA.break_left ? 'header-btn-lg pr-0' : ''}>
          <div className='widget-content p-0'>
            <div className='widget-content-wrapper'>
              <div className='widget-content-left'>
                <HeaderNavButton nav={PROFILE_MODAL_DEFAULT_DATA} coreData={coreData} isProfileDefaultModal />
              </div>
              {/* {nav.component_call_data_connection && nav.text_right_header ? (
                <div className='widget-content-left  ml-3 header-user-info'>
                  <div className='widget-heading'>{getFromRedux(nav.component_call_data_connection.name + '.' + nav.text_right_header)}</div>
                  <div className='widget-subheading'>{getFromRedux(nav.component_call_data_connection.name + '.' + nav.text_right_sub_header)}</div>
                </div>
              ) : null} */}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default connect(select)(NavHeaderBtn)
