import { useDispatch, useSelector } from 'react-redux'
import { primary_color } from '../../../Helper/uiHelper'
import { setForgotPassword } from '../../../Redux/Reducers/user'
import { postForgotPassword } from '../../../Redux/Sagas/rootSaga'
import { request } from '../../../Redux/Sagas/requests/api'
import { isSuccessResponse } from '../../../helper'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'

const SendPasswordLinkButton = () => {
  const dispatch = useDispatch()
  const currentRow = useSelector((state) => state.CMS.currentRow)
  const onSend = () => {
    request('forgot-password', 'post', { email: currentRow?.[0]?.email }).then((res) => {
      isSuccessResponse(res, 'Link send successfully!')
    })
  }
  return (
    <div
      className='body2 cursor-pointer'
      style={{ color: primary_color }}
      onClick={() => {
        onSend()
      }}>
      Click here to send resend password link
    </div>
  )
}
export default SendPasswordLinkButton
