import { useEffect, useState } from 'react'
import useGetEmailTemplateResources from '../../../Queries/EmailTemplate/useGetEmailTemplateResource'
import { useDispatch, useSelector } from 'react-redux'
import { AvForm } from 'availity-reactstrap-validation'
import TagField from './tagField'
import { primary_color } from '../../../Helper/uiHelper'
import useUpdateEmailTemplateTags from '../../../Queries/EmailTemplate/useUpdateEmailTemplateTags'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import TagFieldDropdown from './tagFieldDropdown'
import { ticketResponseTimeOptions } from './helper'

const ManageEmailTemplateTag = () => {
  const dispatch = useDispatch()
  const [editableField, setEditableField] = useState([])
  const [nonEditableField, setNonEditableField] = useState([])
  const [allFields, setAllFields] = useState([])

  const currentRow = useSelector((state) => state.CMS.currentRow)
  let data = currentRow?.[currentRow?.length - 1]

  const { data: tagsNWidgets } = useGetEmailTemplateResources(data?.uuid)
  const updateTags = useUpdateEmailTemplateTags()
  useEffect(() => {
    const editable = []
    const nonEditable = []
    tagsNWidgets?.emailTags?.map((item) => {
      let isEditable = false
      if (item?.common_setting) {
        const setting = JSON.parse(item?.common_setting)?.[0]
        if (setting?.enable) {
          isEditable = true
        }
      }
      if (isEditable) {
        editable.push(item)
      } else {
        nonEditable?.push(item)
      }
    })
    setEditableField(editable)
    setNonEditableField(nonEditable)
    setAllFields(tagsNWidgets?.emailTags)
  }, [JSON.stringify(tagsNWidgets?.emailTags)])

  const onChangeTagField = (setting, uuid) => {
    const newAllFields = allFields?.map((item) => {
      if (item?.uuid === uuid) {
        return {
          ...item,
          common_setting: [setting],
        }
      } else {
        return item
      }
    })
    setAllFields(newAllFields)
  }

  const onSaveTags = () => {
    let fieldsToSend = allFields?.map((item) => {
      return {
        ...item,
        common_setting: typeof item?.common_setting === 'string' ? JSON.parse(item?.common_setting) : item?.common_setting,
      }
    })
    updateTags.mutate(
      { email_tags: fieldsToSend, type: 'tags' },
      {
        onSuccess: (data) => {
          //add failed scenario also
          if (data?.success) {
            dispatch(setNotification({ type: 'success', message: 'Saved Successfully!' }))
          } else {
            dispatch(setNotification({ type: 'error', message: 'Something went wrong' }))
          }
        },
        onError: (error) => {
          dispatch(setNotification({ type: 'error', message: 'Something went wrong' }))
        },
      }
    )
  }

  return (
    <div>
      <div className='d-flex'>
        {nonEditableField?.length > 0 && (
          <div style={{ flex: 1 }} className='mr-3'>
            <AvForm>
              {nonEditableField?.map((field, index) => (
                <TagField key={index} data={field} onChange={onChangeTagField} isDisabled={true} />
              ))}
            </AvForm>
          </div>
        )}
        {editableField?.length > 0 && (
          <div style={{ flex: 1 }}>
            <AvForm>
              {editableField?.map((field, index) => {
                if (['Ticket Response Time']?.includes(field?.name)) {
                  return <TagFieldDropdown key={index} data={field} onChange={onChangeTagField} options={ticketResponseTimeOptions} />
                }
                return <TagField key={index} data={field} onChange={onChangeTagField} />
              })}
            </AvForm>
          </div>
        )}
      </div>

      <div className='d-flex justify-content-end my-4'>
        <button
          className='btn btn-primary rounded-pill'
          style={{ background: primary_color, paddingInline: '8rem' }}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            onSaveTags()
          }}>
          Save
        </button>
      </div>
    </div>
  )
}

export default ManageEmailTemplateTag
