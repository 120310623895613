import Loader from 'react-loaders'
import LoadingOverlay from 'react-loading-overlay'
import Main from "../../src/Pages/index"
import { useSelector } from 'react-redux'


const LoadingOverlayWrapper = ({ children }) => {
  const loader = useSelector((state) => state.Loader.loader)
  return (
    <LoadingOverlay
      tag='div'
      active={loader}
      styles={{
        overlay: (base) => ({
          ...base,
          zIndex: 99999999,
          background: '#fff',
          opacity: 0.5,
        }),
      }}
      spinner={<Loader active color='#000' type='ball-pulse' />}>
      <Main>{children}</Main>
    </LoadingOverlay>
  )
}

export default LoadingOverlayWrapper
