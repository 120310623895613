import moment from 'moment'
import { primary_color } from '../../Helper/uiHelper'
import { getObjectDifference, tidyName } from '../../helper'

const logText = (data, updated_data, original_data) => {
  if (updated_data?.length === 0) {
    return (
      <div>
        {' '}
        <span className='font-weight-bold'>{data?.updated_by}</span>
        {` has removed all merge user `}
      </div>
    )
  } else if (original_data?.length === 0) {
    let addeduser = updated_data?.map((item) => item?.merged_user?.full_name)
    return (
      <div>
        {' '}
        <span className='font-weight-bold'>{data?.updated_by}</span>
        {` has collaborated with ${addeduser?.join(', ')} `}
      </div>
    )
  } else {
    let old_user_id = original_data?.map((item) => item?.merged_user?.id)
    let new_user_id = updated_data?.map((item) => item?.merged_user?.id)

    let newUser = []
    let deletedUser = []
    new_user_id?.map((item) => {
      if (!old_user_id?.includes(item)) {
        let user = updated_data?.find((i) => i?.merged_user?.id === item)
        newUser?.push(user?.merged_user?.full_name)
      }
    })
    old_user_id?.map((item) => {
      if (!new_user_id?.includes(item)) {
        let user = original_data?.find((i) => i?.merged_user?.id == item)
        let user2 = original_data?.map((item) => item?.merged_user?.id)
        deletedUser?.push(user?.merged_user?.full_name)
      }
    })
    if (newUser?.length === 0 && deletedUser?.length === 0) {
      return null
    }
    if (newUser?.length === 0) {
      return (
        <div>
          {' '}
          <span className='font-weight-bold'>{data?.updated_by}</span>
          {` has removed collaboration with ${deletedUser?.join(', ')} `}
        </div>
      )
    } else if (deletedUser?.length === 0) {
      return (
        <div>
          {' '}
          <span className='font-weight-bold'>{data?.updated_by}</span>
          {` has collaborated with ${newUser?.join(', ')} `}
        </div>
      )
    } else {
      return (
        <div>
          {' '}
          <span className='font-weight-bold'>{data?.updated_by}</span>
          {` has removed collaboration with ${deletedUser?.join(', ')} and collaborated with ${newUser?.join(', ')}`}
        </div>
      )
    }
  }
}

const ShowDifference = ({ data, original_data, updated_data, onExpand, difference }) => {
  return (
    <div>
      <div className='permission-table'>
        <div className='mb-2 cursor-pointer' onClick={() => onExpand()}>
          <i class='fa-sharp fa-solid fa-arrow-left  m-3'></i>
          Go Back
        </div>
        <div className={`permission-header`} style={{ color: primary_color }}>
          Updated User
        </div>
        <div className='p-4'>
          <div className='d-flex mb-2' style={{ width: '100%' }}>
            <div className='font-weight-bold' style={{ width: '40%' }}>
              Current User
            </div>
            <div>{updated_data?.map((item) => item?.merged_user?.full_name)?.join(', ')}</div>
          </div>
        </div>

        <div className={`permission-header`} style={{ color: primary_color }}>
          Old user
        </div>
        <div className='p-4'>
          <div className='d-flex mb-2' style={{ width: '100%' }}>
            <div className='font-weight-bold' style={{ width: '40%' }}>
              Previous User
            </div>
            <div>{original_data?.map((item) => item?.merged_user?.full_name)?.join(', ')}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

const MergedUserLogs = ({ data, onExpand, expandedId }) => {
  let updated_data = data?.updated_data && JSON.parse(data?.updated_data)
  let original_data = data?.original_data && JSON.parse(data?.original_data)

  const differenceAll = getObjectDifference(updated_data, original_data)

  const { updated_at, role_id, full_name, ...difference } = differenceAll
  const updatedDataKeys = Object.keys(difference)

  if (!logText(data, updated_data, original_data)) return

  if (expandedId) {
    return (
      <ShowDifference
        data={data}
        difference={difference}
        onExpand={onExpand}
        updated_data={updated_data}
        original_data={original_data}></ShowDifference>
    )
  }
  return (
    <div className='d-flex align-items-center p-3' style={{ borderBottom: '1px solid #e0e0e0' }}>
      <div>
        <i
          class='fa-solid fa-arrow-up-right-from-square mr-2 cursor-pointer'
          style={{ color: primary_color }}
          onClick={() => {
            onExpand(data?.id)
          }}></i>
      </div>
      <div style={{ flex: 1 }}>{logText(data, updated_data, original_data)}</div>
      {data?.updated_at && <div>{moment.utc(data?.updated_at).format('MMM D  YYYY, HH:mm')}</div>}
    </div>
  )
}

export default MergedUserLogs
