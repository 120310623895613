import React from 'react'
import { Link } from 'react-router-dom'
import './metisMenu.scss' // Import SCSS file for styling

import { Fragment, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'

import { clearPageTab } from '../../../Redux/Reducers/page'
// ../../Redux/Reducers/CMS
import {
  clearCurrentRowAllData,
  clearCurrentRowData,
  clearParentTableAllData,
  clearParentTableData,
  setCoreData,
  setCurrentUrl,
} from '../../../Redux/Reducers/CMS'
import { checkPermission, cms, editCMS, getFullNavListCms, getNavFilterList } from '../../../helper'

const menuItems = [
  {
    icon: 'fa-solid fa-building',
    label: 'Home',
    to: '/',
  },
  {
    icon: 'fa-solid fa-building',
    label: 'Profile',
    to: '/profile',
  },
  {
    icon: 'fa-solid fa-building',
    label: 'Settings',
    children: [
      {
        icon: 'fa-solid fa-building',
        label: 'Appearance',
        to: '/settings/appearance',
      },
      {
        icon: 'fa-solid fa-building',
        label: 'Account',
        to: '/settings/account',
      },
    ],
  },
  // Add more menu items as needed
]

const MenuItem = ({ item, enableMobileMenu, toggleMobileSidebar }) => {
  const dispatch = useDispatch()
  const editMode = useSelector((state) => state.CMS.editMode)
  const navigation = useSelector((state) => state.Navigations)

  const navMenu = navigation.InnerSidebarNav
  const coreData = useSelector((state) => state.CMS.coreData)

  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    const currentPath = window.location.pathname.slice(1)

    if (item?.children?.length > 0) {
      let isPathExistInSubMenu = false
      item?.children?.map((menu) => {
        if (menu?.to === currentPath) {
          isPathExistInSubMenu = true
        }
      })
      if (isPathExistInSubMenu) {
        setIsOpen(true)
      }
    }
  }, [item, window.location.pathname])

  const toggleOpen = () => {
    if (enableMobileMenu) {
      // toggleMobileSidebar()
    }
    onClick()
    const currentPath = window.location.pathname.slice(1)

    if (item?.children?.length > 0) {
      let isPathExistInSubMenu = false
      item?.children?.map((menu) => {
        if (menu?.to === currentPath) {
          isPathExistInSubMenu = true
        }
      })
      if (!isPathExistInSubMenu) {
        setIsOpen(!isOpen)
      }
    }
  }

  const onClick = (e) => {
    if (item?.children) {
      if (editMode) {
        const navList = getFullNavListCms(navMenu)
        navList.map((i) => {
          if (cms(i.cms) === item?.label) {
            editCMS(i.cms)
          }
        })
      }
      //  props.toggleSubMenu(e)
    } else {
      if (editMode) {
        const navList = getNavFilterList(navMenu)
        navList.map((i) => {
          if (i.path === item.to.replace('#/', '')) {
            editCMS(i.cms)
          }
        })
      } else {
        dispatch(clearPageTab())

        dispatch(clearParentTableAllData())
        dispatch(clearCurrentRowAllData())

        const updatedCoreData = {
          ...coreData,
          currentFormData: {},
          currentFormFullData: {},
        }
        dispatch(setCoreData(updatedCoreData))

        dispatch(setCurrentUrl(item.to.replace('#/', '')))
        //  history.push(props.to.replace('#/', ''))
      }
    }
  }

  if (typeof item !== 'object')
    return (
      <></>
      // <h5 className='app-sidebar__heading hide-on-collapse body2' onClick={() => editCMS(item)}>
      //   {cms(item)}
      // </h5>
    )
  return (
    <li className='nav-item'>
      {item.children ? (
        <>
          <button className={`nav-link ${isOpen ? 'active' : ''}`} onClick={toggleOpen}>
            <span>
              <i className={`${item.icon} mr-2 subtitle1`}></i>{' '}
              {(!enableMobileMenu || true) && <span className='hide-on-collapse subtitle1'>{item.label}</span>}
            </span>

            {(!enableMobileMenu || true) && <i className={`pe-7s-angle-down caret-left hide-on-collapse`}></i>}
          </button>
          <ul className={`nav flex-column hide-on-collapse-list submenu ${isOpen && !enableMobileMenu ? 'show' : ''}`}>
            {item.children.map((childItem, index) => (
              <MenuItem key={index} item={childItem} />
            ))}
          </ul>
        </>
      ) : (
        <Link
          className={`nav-link sub-item ${item.to === window.location.pathname?.replace('/', '') && 'selected-nav-link font-weight-bold'}`}
          to={item.to}
          onClick={() => onClick()}>
          <span className={`subtitle1 line-clamp-1 ${item.to === window.location.pathname?.replace('/', '') && 'font-weight-bold'}`}>
            <i className={`${item.icon} mr-2`}></i> {item.label?.replaceAll('-', ' ')}
          </span>
        </Link>
      )}
    </li>
  )
}

const DynamicMenu = ({ enableMobileMenu, toggleMobileSidebar }) => {
  const dispatch = useDispatch()
  // const navMenu = useSelector((state) => state.CMS.coreData.nav)
  const navMenu = useSelector((state) => state.Navigations.InnerSidebarNav)
  const cmsData = useSelector((state) => state.CMS.cmsData)
  const [navData, setNavData] = useState([])
  const [selected, setSelected] = useState('')

  const handleSelected = (item) => {
    setSelected(item.label)
  }

  useEffect(() => {
    let data = []
    navMenu?.map((item) => {
      if (typeof item !== 'object') {
        data?.push(item)
      } else {
        let children = []
        if (item?.items?.length > 0) {
          item?.items?.forEach((sub_item) => {
            children?.push({
              icon: sub_item?.icon,
              label: cms(sub_item?.cms),
              to: sub_item?.path,
            })
          })
        }
        let item_to_add = {
          icon: item?.icon,
          label: cms(item?.name),
        }
        if (children?.length > 0) {
          item_to_add = {
            ...item_to_add,
            children,
          }
          data?.push(item_to_add)
        }
      }
    })
    setNavData(data)
  }, [navMenu, cmsData])

  useEffect(() => {
    dispatch(clearPageTab())
    dispatch(clearCurrentRowData())
    dispatch(clearParentTableData())
    dispatch(clearParentTableAllData())
    dispatch(clearCurrentRowAllData())
  }, [])

  return (
    <div className='metis-menu-dynamic mt-4'>
      <ul className='nav flex-column'>
        {navData?.map((item, index) => (
          <MenuItem
            key={index}
            item={item}
            handleSelected={handleSelected}
            enableMobileMenu={false}
            toggleMobileSidebar={toggleMobileSidebar}
          />
        ))}
      </ul>
    </div>
  )
}

export default DynamicMenu
