import { useEffect, useState } from 'react'
import { Button, Table } from 'reactstrap'
import { primary_color } from '../../../Helper/uiHelper'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { request } from '../../../Redux/Sagas/requests/api'
import { useDispatch, useSelector } from 'react-redux'
import { isSuccessResponse, tidyName } from '../../../helper'
import Loader from 'react-loaders'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
const TEMPLATE = [
  {
    title: 'Jan',
    key: 'jan',
  },
  {
    title: 'Feb',
    key: 'feb',
  },
  {
    title: 'Mar',
    key: 'mar',
  },
  {
    title: 'Apr',
    key: 'apr',
  },
  {
    title: 'May',
    key: 'may',
  },
  {
    title: 'June',
    key: 'jun',
  },
  {
    title: 'July',
    key: 'july',
  },
  {
    title: 'Aug',
    key: 'aug',
  },
  {
    title: 'Sep',
    key: 'sept',
  },
  {
    title: 'Oct',
    key: 'oct',
  },
  {
    title: 'Nov',
    key: 'nov',
  },
  {
    title: 'Dec',
    key: 'dec',
  },
]
const getTemplateData = (data) => {
  const templateData = TEMPLATE?.map((item) => {
    return {
      ...item,
      value: data[item.key],
    }
  })
  return templateData
}

const EditRateTemplate = ({ setEditRateTemplateOpen, isRateApproved }) => {
  const currentRow = useSelector((state) => state.CMS.currentRow)
  const dispatch = useDispatch()

  const [submitting, setSubmitting] = useState(false)
  const [loading, setLoading] = useState(false)
  const [templateData, setTemplateData] = useState([])
  const [editableCell, setEditableCell] = useState({})
  const [note, setNote] = useState('')
  const handleDoubleClick = (rowIndex, colIndex) => {
    setEditableCell({ row: rowIndex, col: colIndex })
  }

  useEffect(() => {
    setLoading(true)

    request('renewal-locations/get-discount-data?item_date_time_id=' + currentRow?.[0]?.id, 'GET').then((res) => {
      if (res?.data) {
        const templates = getTemplateData(res?.data?.discountData)
        setTemplateData(templates)
      }
      setLoading(false)
    })
  }, [])
  const handleChange = (index, value) => {
    if (parseFloat(value) > 100) return
    setTemplateData((oldData) => {
      const newData = oldData?.map((item, i) => {
        if (index === i) {
          return {
            ...item,
            value,
          }
        } else {
          return item
        }
      })

      return newData
    })
  }

  const onSubmitForm = (close) => {
    let isEmptyFound = false
    let data = templateData?.map((item) => {
      if (!item.value) {
        isEmptyFound = true
      }
      return {
        month: item.key,
        amount: parseFloat(item.value),
      }
    })
    if (isEmptyFound) {
      dispatch(setNotification({ type: 'error', message: 'Value cant be empty' }))
      return
    }
    let payload = { note, data, item_date_time_id: currentRow?.[0]?.id }
    setSubmitting(true)
    request('renewal-locations/update-discount-data', 'POST', payload).then((res) => {
      isSuccessResponse(res, 'Rate updated successfully!')
      setSubmitting(false)
      if (close) {
        setEditRateTemplateOpen(false)
      }
    })
  }
  if (loading)
    return (
      <div className='d-flex align-items-center justify-content-center' style={{ height: '10rem' }}>
        <Loader type='ball-pulse' color={primary_color} />
      </div>
    )
  return (
    <div>
      <Button
        color='dark'
        className='mb-4 mt-4'
        onClick={() => {
          setEditRateTemplateOpen(false)
        }}>
        {' '}
        <i class='fa-sharp fa-regular fa-arrow-left'></i>
      </Button>
      <div style={{ width: '20rem' }}>
        <Table bordered>
          <thead>
            <tr>
              <th className='subtitle1 font-weight-bold py-3' style={{ color: primary_color }}>
                Month
              </th>
              <th className='subtitle1 font-weight-bold py-3' style={{ color: primary_color }}>
                Discount (%)
              </th>
            </tr>
          </thead>
          <tbody>
            {templateData?.map((item, rowIndex) => {
              return (
                <tr key={item.title}>
                  <td className='subtitle1' style={{ width: '5rem' }}>
                    {tidyName(item.title)}
                  </td>
                  <td
                    className=' subtitle1'
                    onClick={() => handleDoubleClick(rowIndex, 0)}
                    style={{
                      backgroundColor: editableCell.row === rowIndex && editableCell.col === 0 ? '#049FDB1A' : 'transparent',
                      width: '5rem',
                    }}>
                    {editableCell.row === rowIndex && editableCell.col === 0 && !isRateApproved ? (
                      <input
                        type='text'
                        value={item.value}
                        onChange={(e) => handleChange(rowIndex, e.target.value)}
                        onBlur={() => setEditableCell({ row: null, col: null })}
                        // disabled={isBlockEdit}
                        autoFocus
                        style={{
                          // backgroundColor: '#049FDB1A',
                          border: 'none',
                          outline: 'none',
                          width: '100%',
                        }}
                      />
                    ) : (
                      item?.value
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
      {!isRateApproved && (
        <AvForm>
          <label className='subtitle1 font-weight-bold'>Note</label>
          <AvField
            type='textarea'
            name='note'
            value={note}
            onChange={(e) => {
              setNote(e.target.value)
            }}></AvField>
        </AvForm>
      )}

      {!isRateApproved && (
        <div className='d-flex justify-content-end mt-5'>
          {' '}
          <button
            className='btn rounded-pill mt-2 text-danger border-danger mr-3'
            type='button'
            id='close-btn'
            onClick={(e) => {
              setEditRateTemplateOpen(false)
              e.preventDefault()
              e.stopPropagation()
            }}
            style={{ fontWeight: 700, width: '12rem' }}>
            Close
          </button>
          <button
            className='btn text-white rounded-pill mt-2 mr-3'
            disabled={!!submitting}
            type='submit'
            id='save-button'
            onClick={() => {
              onSubmitForm()
            }}
            style={{ background: primary_color, fontWeight: 700, width: '14rem' }}>
            {submitting ? 'Saving...' : 'Save'}
          </button>
          <button
            className='btn text-white rounded-pill mt-2'
            disabled={!!submitting}
            onClick={() => {
              onSubmitForm(true)
            }}
            type='submit'
            style={{ background: primary_color, fontWeight: 700, width: '14rem' }}>
            Save & Close
          </button>
        </div>
      )}
    </div>
  )
}

const checkUserSelected = (users, uuid) => {
  if (users?.includes(uuid)) {
    return true
  } else {
    return false
  }
}

export default EditRateTemplate
