import { useEffect, useState } from 'react'
import MassRateChange from './massRateChange'
import RateAction from './rateAction'
import { useSelector } from 'react-redux'
import { request } from '../../../Redux/Sagas/requests/api'
import { useLockerYearContext } from '../../../Context/lockerYearContext'

const LockerYearRates = ({ gridRelatedData }) => {
  const currentRow = useSelector((state) => state.CMS.currentRow)

  const { state: lockerYearState, updateState: updateLockerYearState } = useLockerYearContext()

  useEffect(() => {
    gridRelatedData?.setIsRateApproved(lockerYearState?.is_approve_rate)
  }, [lockerYearState?.is_approve_rate])

  const fetchRateStatus = () => {
    request('renewal-locations/check-block-status', 'POST', { item_date_time_id: currentRow?.[0]?.id }).then((res) => {
      updateLockerYearState({
        is_block_created: res?.data?.is_block_created,
        is_approve_rate: res?.data?.is_approve_rate,
      })
    })
  }
  useEffect(() => {
    fetchRateStatus()
  }, [])
  return (
    <div>
      <MassRateChange isRateApproved={gridRelatedData?.isRateApproved}></MassRateChange>
      <hr></hr>
      <RateAction
        isRateApproved={gridRelatedData?.isRateApproved}
        setEditRateTemplateOpen={gridRelatedData?.setEditRateTemplateOpen}
        fetchRateStatus={fetchRateStatus}></RateAction>
    </div>
  )
}

export default LockerYearRates
