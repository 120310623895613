import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { request } from '../../Redux/Sagas/requests/api'

const fetchLogsByTable = async (id, table,page) => {
  const response = await request(`/get-log-data`, 'POST', { source_table: table, row_id: id, page })
  return response.data?.data
}

export const useFetchLogsByTable = (id, table,page) => {
  const dispatch = useDispatch()

  const query = useQuery(['permission-log', id,table,page], () => fetchLogsByTable(id, table,page), {
    onSuccess: (data) => {},
    enabled: !!id && !!table,
  })

  return {
    data: query.data,
    error: query.error,
    isLoading: query.isLoading,
    isSuccess: query.isSuccess,
    isError: query.isError,
  }
}
