import moment from 'moment'
import { primary_color } from '../../Helper/uiHelper'
import { getObjectDifference, tidyName } from '../../helper'
import { getTabNameForLogs } from './logHelper'

const getValue = (data) => {
  return data?.name || data?.location?.name
}

const ShowDifference = ({ data, original_data, updated_data, onExpand, difference, tab_name }) => {
  if (!tab_name) {
    return (
      <div>
        <div className='permission-table'>
          <div className='mb-2 cursor-pointer' onClick={() => onExpand()}>
            <i class='fa-sharp fa-solid fa-arrow-left  m-3'></i>
            Go Back
          </div>
          <div className={`permission-header`} style={{ color: primary_color }}>
            <div className='d-flex align-items-center '>
              <div style={{ flex: 1 }}>
                <span className='font-weight-bold'>{data?.updated_by}</span>
                {` has created user  `}
                {/* <span className='font-weight-bold'>{getValue(updated_data)}</span> {' in '} <span className='font-weight-bold'>{tab_name}</span> */}
              </div>
              {data?.updated_at && <div>{moment.utc(data?.updated_at).format('MMM D  YYYY, HH:mm')}</div>}
            </div>
          </div>
          <div className='p-4'>
            <div className='mb-3'>
              <div className='font-weight-bold body2' style={{ color: primary_color }}>
                Username
              </div>
              <div className='subtitle1'>{updated_data?.username}</div>
            </div>
            <div className='mb-3'>
              <div className='font-weight-bold body2' style={{ color: primary_color }}>
                Email
              </div>
              <div className='subtitle1'>{updated_data?.email}</div>
            </div>
            <div className='mb-3'>
              <div className='font-weight-bold body2' style={{ color: primary_color }}>
                Full Name
              </div>
              <div className='subtitle1'>{updated_data?.full_name}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div>
      <div className='permission-table'>
        <div className='mb-2 cursor-pointer' onClick={() => onExpand()}>
          <i class='fa-sharp fa-solid fa-arrow-left  m-3'></i>
          Go Back
        </div>
        <div className={`permission-header`} style={{ color: primary_color }}>
          <div className='d-flex align-items-center '>
            <div style={{ flex: 1 }}>
              <span className='font-weight-bold'>{data?.updated_by}</span>
              {` has added  `}
              <span className='font-weight-bold'>{getValue(updated_data)}</span> {' in '} <span className='font-weight-bold'>{tab_name}</span>
            </div>
            {data?.updated_at && <div>{moment.utc(data?.updated_at).format('MMM D  YYYY, HH:mm')}</div>}
          </div>
        </div>
        <div className='p-4'>{getValue(updated_data)}</div>
      </div>
    </div>
  )
}

const CreateLog = ({ data, onExpand, expandedId, parentPageName }) => {
  let updated_data = data?.updated_data && JSON.parse(data?.updated_data)
  let original_data = data?.original_data && JSON.parse(data?.original_data)

  let tab_name = getTabNameForLogs(parentPageName, data?.tab_name)

  if (expandedId) {
    return <ShowDifference data={data} updated_data={updated_data} onExpand={onExpand} tab_name={tab_name}></ShowDifference>
  }
  if (!tab_name) {
    return (
      <div className='d-flex align-items-center p-3' style={{ borderBottom: '1px solid #e0e0e0' }}>
        <div>
          <i
            class='fa-solid fa-arrow-up-right-from-square mr-2 cursor-pointer'
            style={{ color: primary_color }}
            onClick={() => {
              onExpand(data?.id)
            }}></i>
        </div>
        <div style={{ flex: 1 }}>
          <span className='font-weight-bold'>{data?.updated_by}</span>
          {` has created user  `}
          {/* <span className='font-weight-bold'>{getValue(updated_data)}</span> {' in '} <span className='font-weight-bold'>{tab_name}</span> */}
        </div>
        {data?.updated_at && <div>{moment.utc(data?.updated_at).format('MMM D  YYYY, HH:mm')}</div>}
      </div>
    )
  }
  return (
    <div className='d-flex align-items-center p-3' style={{ borderBottom: '1px solid #e0e0e0' }}>
      <div className='disabled-pill'>
        <i
          class='fa-solid fa-arrow-up-right-from-square mr-2 cursor-pointer'
          style={{ color: primary_color }}
          onClick={() => {
            onExpand(data?.id)
          }}></i>
      </div>
      <div style={{ flex: 1 }}>
        <span className='font-weight-bold'>{data?.updated_by}</span>
        {` has added  `}
        <span className='font-weight-bold'>{getValue(updated_data)}</span> {' in '} <span className='font-weight-bold'>{tab_name}</span>
      </div>
      {data?.updated_at && <div>{moment.utc(data?.updated_at).format('MMM D  YYYY, HH:mm')}</div>}
    </div>
  )
}

export default CreateLog
