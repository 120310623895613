import { useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { primary_color } from '../../../Helper/uiHelper'
import { useDispatch, useSelector } from 'react-redux'
import { request } from '../../../Redux/Sagas/requests/api'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import { fetchUpdateApiData } from '../../../Redux/Reducers/CMS'

const DesclineRefundButton = ({ gridRelatedData }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector((state) => state.User.user.full_name)
  const role = useSelector((state) => state.User.user.role.name)

  const [openModal, setOpenModal] = useState(false)
  const [loader, setLoader] = useState(false)
  const refundRequestRow = gridRelatedData?.gridSelectedRow?.filter((row) => {
    if (row?.original?.status?.toLowerCase()?.includes('waiting')) {
      return true
    } else {
      return false
    }
  })
  const refundIds = refundRequestRow?.map((item) => item?.original?.id)
  const nonRefundRow = gridRelatedData?.gridSelectedRow?.filter((row) => {
    if (row?.original?.status?.toLowerCase()?.includes('waiting')) {
      return false
    } else {
      return true
    }
  })

  const onDeclineRequest = () => {
    setLoader(true)
    request('reject-refund', 'POST', { id: refundIds?.[0] }).then((res) => {
      if (res?.status === 200 || res?.status === 202) {
      } else {
        dispatch(setNotification({ type: 'error', message: 'Something went wrong!' }))
      }
      setLoader(false)
      dispatch(fetchUpdateApiData())
    })
  }
  let refundList = gridRelatedData?.gridData?.data?.filter((item) => item.status?.toLowerCase() === 'waiting for approval')
  if (refundList?.length === 0) return
  if (role?.toLowerCase() === 'superadmin' || role?.toLowerCase() === 'super admin' || role?.toLowerCase() === 'admin') {
    return (
      <>
        <Button
          className='bg-danger px-3 rounded text-white mr-2'
          disabled={refundIds?.length !== 1 || nonRefundRow?.length > 0 ? true : false}
          style={{ border: 'none', paddingTop: '0.3rem', paddingBottom: '0.3rem' }}
          onClick={() => {
            onDeclineRequest()
          }}>
          {!loader ? 'Decline' : 'Declining..'}
        </Button>
        <Modal
          isOpen={openModal}
          centered={true}
          toggle={() => {
            setOpenModal(false)
          }}
          style={{ width: '50rem', boxShadow: 'none', maxWidth: '45rem' }}>
          <ModalHeader style={{ backgroundColor: '#FFF', border: 'none', padding: '2rem', textAlign: 'center' }}>
            <div className='p font-weight-bold text-center' style={{ color: primary_color }}>
              {refundRequestRow?.length === 0 && 'Please select atleast one row of refund request'}
              {refundRequestRow?.length > 0 && 'Are you sure you want to approve the refund request for Ids: ' + refundIds?.join(', ') + ' ?'}
            </div>
          </ModalHeader>
          <ModalBody>
            <div className='d-flex justify-content-between'>
              <button
                className='btn text-white rounded-pill mt-2'
                type='button'
                onClick={() => {
                  history.push(`/refund-approve?refund_id=${refundIds?.[0]}`)
                  // onApproveRefund()
                }}
                disabled={refundRequestRow?.length === 0 ? true : false}
                style={{ background: primary_color, fontWeight: 700, width: '48%' }}>
                {loader ? 'Approving...' : 'Yes'}
              </button>
              <button
                className='btn rounded-pill mt-2 text-danger border-danger'
                type='button'
                onClick={() => {
                  setOpenModal(false)
                }}
                style={{ fontWeight: 700, width: '48%' }}>
                Cancel
              </button>
            </div>
          </ModalBody>
        </Modal>
      </>
    )
  } else {
    return
  }
}

export default DesclineRefundButton
