// Routes.js
import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Login from '../Auth/login'
import HandleLogout from '../Auth/logout'
import Register from '../Auth/register'
import ForgotPassword from '../Auth/forgotPassword'
import ResetPassword from '../Auth/resetPassword'
import AuthRoute from './authRoute'

import PublicPageWrapper from '../../Components/publicPageWrapper'
import Profile from '../../Components/Profile/profile'
import UnSubscribe from '../Unsubscribe/unsubscribe'
import Wrapper from '../../Components/wrapper'
import ApproveRefund from '../../Components/PageSpecificComponents/Refund/approveRefund'

import RedirectToPublic from '../../Components/PageSpecificComponents/RedirectToPublic/redirectToPublic'

import RedirectDirectToDashboard from '../../Components/PageSpecificComponents/RedirectToPublic/redirectToDashboard'
import AdminDirectEditSuccess from '../../Components/Widget/PaymentForm/adminDirectEditSuccess'
import AdminDirectEditFail from '../../Components/Widget/PaymentForm/adminDirectEditFail'

import DynamicRoute from './dynamicRoute'
import MaintainanceBanner from '../../Components/PageSpecificComponents/MaintainanceBanner/maintainananceBanner'
import PaymentRejected from './paymentDeclined'

const Routes = () => {
  const cmsData = useSelector((state) => state.cmsData)

  return (
    <Fragment>
      <Switch>
        {/* Auth Routes */}
        <Route exact path='/welcome/login' component={Login} />
        <Route exact path='/welcome/register' component={Register} />
        <Route exact path='/forgot-password' component={ForgotPassword} />
        <Route exact path='/reset/:token' component={ResetPassword} />
        <Route exact path='/unsubscribe' component={UnSubscribe} />
        <Route
          exact
          path='/profile-update'
          component={() => (
            <PublicPageWrapper>
              <Profile />
            </PublicPageWrapper>
          )}
        />
        <Route exact path='/admin-direct-edit-success' component={() => <AdminDirectEditSuccess />} />
        <Route exact path='/admin-direct-edit-fail' component={() => <AdminDirectEditFail />} />
        <Route
          exact
          path='/redirect-to-public'
          component={() => {
            return <RedirectToPublic />
          }}
        />
        <Route
          exact
          path='/redirect-to-dashboard'
          component={() => {
            return <RedirectDirectToDashboard />
          }}
        />
        <Route
          path='/payment-rejected'
          exact
          component={() => {
            return <PaymentRejected />
          }}></Route>

        {/* User Route */}
        <AuthRoute path='/logout' component={HandleLogout} />
        <Route
          path='/maintainance'
          component={() => (
            <PublicPageWrapper>
              <MaintainanceBanner />
            </PublicPageWrapper>
          )}></Route>
        <Route
          path='/link-expired'
          component={() => (
            <PublicPageWrapper>
              <div>Link expired...</div>
            </PublicPageWrapper>
          )}></Route>
        <AuthRoute
          exact
          path={'/refund-approve'}
          component={() => (
            <Wrapper cmsData={cmsData}>
              <ApproveRefund />
            </Wrapper>
          )}
        />
        <Route
          path='/'
          exact
          component={() => {
            return <DynamicRoute />
          }}></Route>
        <Route
          path='/:id'
          component={() => {
            return <DynamicRoute />
          }}></Route>
      </Switch>
      <ToastContainer />
    </Fragment>
  )
}

export default Routes
