import React, { Fragment, useEffect, useRef, useState } from 'react'
import { FaBars, FaList, FaTh } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'

import { setNotification } from '../../Redux/Reducers/notificationHandling'
import { request } from '../../Redux/Sagas/requests/api'
import useMediaQuery from '../../CustomHook/useMediaQuery'
import Loader from 'react-loaders'
import { primary_color } from '../../Helper/uiHelper'
import { checkFilterApplied } from '../../Helper/publicPageHelper'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import NoDataSection from './NoDataSection'
import CMSWidgetModal from '../CMSWidget/cmsWidgetModal'
import CMSSearchResultx from '../CMSWidget/SearchResult/CMSSearchResult'
import './searchResults.scss'

const default_image = 'https://test-api.ionicbox.com/storage/files/1/GyexiOYukHj8.png'

const ListItem = ({ item, activePage, setActivePage, data, parentData, setParentData }) => {
  const isDesktop = useMediaQuery('(min-width:768px)')
  const history = useHistory()
  const publicPageData = useSelector((state) => state.publicPageData)
  const dispatch = useDispatch()
  return (
    <div
      className=' mb-4'
      style={
        isDesktop
          ? { display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }
          : { display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }
      }>
      <div className='d-flex align-items-center mb-4'>
        {item?.is_image ? (
          <img
            src={item.image ? item?.image : default_image}
            width='60'
            height='60'
            className='me-3'
            style={{ borderRadius: item?.is_image ? '50%' : '50%' }}
          />
        ) : (
          <div style={{ padding: '6px', borderRadius: '50%', border: '1px solid #495057' }}>
            <img src={default_image} width='50' height='50' className='me-3' style={{}} />
          </div>
        )}

        <div className='ml-3 pt-1 color-black'>
          <h5 className='mb-0 body1' style={isDesktop ? { fontWeight: '700' } : { fontWeight: '600', color: '#495057' }}>
            {item.title}
          </h5>
          <span className='subtitle1'>{item.sub_title}</span>
        </div>
      </div>
      <div className='d-flex justify-content-end' style={{ marginRight: isDesktop ? '3rem' : '0', marginBlock: 'auto' }}>
        <button
          className={`btn text-white booking-btn subtitle1`}
          style={{ borderRadius: '30px', background: primary_color }}
          onClick={() => {
            history.push(`?parent_id=${item?.id}&page_id=${activePage?.child_pages[0]?.uuid}`)
          }}>
          {data?.button_text}
        </button>
      </div>
    </div>
  )
}

const GridItem = ({ item, activePage, setActivePage, data, setParentData }) => {
  const isDesktop = useMediaQuery('(min-width:768px)')
  const history = useHistory()

  return (
    <div className='card mb-3 px-3 py-4 color-black mx-3' style={{ borderRadius: '10px', height: 'fit-content', width: '20rem' }}>
      <div>
        <div className='d-flex justify-content-center align-items-center'>
          <img src={item.image} className='mr-2' height={'150px'} style={{ borderRadius: '10px 10px 0 0' }} />
        </div>

        <div style={{ margin: '0 1rem 0rem 1rem' }}>
          <div className='font-weight-bold mt-3 text-left body1 mb-0 line-clamp-1'>{item.title}</div>
          <p className='card-text text-left line-clamp-1 subtitle1'>{item.sub_title}</p>
          <button
            className='btn text-white rounded-pill subtitle1'
            style={{ borderRadius: '30px', width: '100%', background: primary_color }}
            onClick={() => {
              history.push(`?parent_id=${item?.id}&page_id=${activePage?.child_pages[0]?.uuid}`)
            }}>
            {data?.button_text}
          </button>
        </div>
      </div>
    </div>
  )
}

var controller

const SearchResults = ({
  data,
  searchData,
  filterData,
  setActivePage,
  activePage,
  parentData,
  setParentData,
  clearSearchData,
  setClearSearchData,
  searchResultsAvailable,
  setSearchResultsAvailable,
  searchResults,
  setSearchResults,
  widget_uuid,
}) => {
  const [isListView, setListView] = useState(true)
  // const [results, setResults] = useState(null)
  const [currentPageData, setCurrentPageData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery('(min-width:768px)')

  const editMode = useSelector((state) => state.CMS.editMode)

  const widgetRef = useRef(null)

  const [modalPosition, setModalPosition] = useState()

  const handleSectionClick = () => {
    if (!editMode) return

    if (widgetRef.current) {
      const rect = widgetRef.current.getBoundingClientRect()
      setModalPosition({
        top: rect.height + 150,
        // left: `${rect.left + window.scrollX + rect.width / 2 - 10}px` , // Position below the parent element
        //  left: rect.left + window.scrollX, // Align left edge with parent element
        width: '60vw',
      })
    }
  }

  useEffect(() => {
    controller = new AbortController()
    let payload = {}
    if (!checkFilterApplied(searchData, filterData) || clearSearchData?.length > 0) {
      setSearchResults([])
      setIsLoading(false)
      return
    }

    payload['uuid'] = data?.form?.[0]?.uuid
    payload['fields'] = data?.form?.[0]?.component_call_fields?.map((field) => field?.field_id)

    Object.keys(searchData)?.map((key) => {
      if (searchData[key]) {
        payload[`-filter-${key}`] = searchData[key]
      }
    })
    Object.keys(filterData)?.map((key) => {
      if (filterData[key]) {
        payload[`-filter-${key}`] = filterData[key]
      }
    })
    setIsLoading(true)
    request('get-form-data', 'POST', payload, null, null, null, null, controller)
      .then((res) => {
        const data = []

        if (res?.status === 200) {
          res?.data?.data?.map((item) => {
            data?.push({
              title: item?.name || item?.domain_name,
              sub_title: item?.address?.city,
              image: item?.logo ? process.env.REACT_APP_STORAGE + item?.logo : default_image,
              is_image: item?.logo ? true : false,
              ...item,
            })
          })

          const currentPage = {
            current_page: res?.data?.meta?.current_page,
            hasMore: res?.data?.meta?.current_page !== res?.data?.meta?.last_page,
          }
          setCurrentPageData(currentPage)
          setSearchResults(data)
        } else {
          setSearchResults([])
          if (res?.status) {
            dispatch(setNotification({ type: 'error', message: 'Something went wrong' }))
          }
        }
        setIsLoading(false)
      })
      .catch((e) => console.error('Error in get-form-data: ', e))

    return () => {
      setIsLoading(false)
      controller?.abort()
    }
  }, [searchData, filterData, clearSearchData])

  useEffect(() => {
    if (searchResults?.length === 0 || !searchResults) {
      setSearchResultsAvailable(false)
    } else {
      setSearchResultsAvailable(true)
    }
  }, [searchResults])

  const switchToListView = () => {
    setListView(false)
  }

  const switchToGridView = () => {
    setListView(true)
  }

  // if (searchResults === null || isLoading) {
  //   return (
  //     <div className='d-flex align-items-center justify-content-center' style={{ minHeight: '60vh' }}>
  //       <Loader type='ball-pulse' color={primary_color} />
  //     </div>
  //   )
  // }

  // if (searchResults?.length === 0 && !isLoading && searchResults) return
  const fetchMoreData = () => {
    let payload = {}
    payload['uuid'] = data?.form?.[0]?.uuid
    payload['fields'] = data?.form?.[0]?.component_call_fields?.map((field) => field?.field_id)

    Object.keys(searchData)?.map((key) => {
      if (searchData[key]) {
        payload[`-filter-${key}`] = searchData[key]
      }
    })
    Object.keys(filterData)?.map((key) => {
      if (filterData[key]) {
        payload[`-filter-${key}`] = filterData[key]
      }
    })

    request(`get-form-data?page=${currentPageData?.current_page + 1}`, 'POST', payload)
      .then((res) => {
        const data = []

        if (res?.status === 200) {
          res?.data?.data?.map((item) => {
            data?.push({
              title: item?.name || item?.domain_name,
              sub_title:  item?.address?.city,
              image: item?.uploads?.[0]?.upload_files?.[0]?.file_name
                ? process.env.REACT_APP_PUBLIC_STORAGE + item?.uploads?.[0]?.upload_files?.[0]?.file_name
                : default_image,
              ...item,
            })
          })

          const currentPage = {
            current_page: res?.data?.meta?.current_page,
            hasMore: res?.data?.meta?.current_page !== res?.data?.meta?.last_page,
          }
          setCurrentPageData(currentPage)
          setSearchResults((old_data) => [...old_data, ...data])
        } else {
          dispatch(setNotification({ type: 'error', message: 'Something went wrong' }))
        }
      })
      .catch((e) => console.error('Error in get-form-data: ', e))
  }
  const onCloseModal = () => {
    let element = widgetRef?.current
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' })
    }
    setModalPosition(null)
  }

  let isSearch = false
  Object.keys(searchData)?.map((key) => {
    if (searchData[key]) {
      isSearch = true
    }
  })

  if (!isSearch) return

  return (
    <Fragment>
      <div className={`${modalPosition && 'active-cms-edit'}`} onClick={handleSectionClick} ref={widgetRef}>
        <div className='d-flex flex-column justify-content-between mb-5 col-md-12 ml-auto mr-auto'>
          <div
            className='fs-4 d-flex align-items-center justify-content-between'
            style={{ margin: isDesktop ? '3rem 3rem 0.5rem 0.25rem' : '3rem 0.25rem' }}>
            <div
              // className='me-2 h5 text-black'
              className='content-widget body1'
              style={{ color: '#000', fontWeight: '400' }}
              dangerouslySetInnerHTML={{ __html: data?.heading?.replaceAll('h5', 'div') }}></div>
            {/* <span className='me-2 h5 text-black' style={{ color: '#000', fontWeight: '400' }}>
              Search results
            </span> */}
            <div className=''>
              {data?.grid_view && parseInt(data?.grid_view) && (
                <FaTh className='mr-3 cursor-pointer body1' style={{ opacity: isListView ? 0.5 : 1 }} onClick={() => switchToListView()} />
              )}
              {data?.list_view && parseInt(data?.list_view) && (
                <FaBars className='cursor-pointer body1' style={{ opacity: !isListView ? 0.5 : 1 }} onClick={() => switchToGridView()} />
              )}
            </div>
          </div>
          <div className='my-4'>
            <hr></hr>
          </div>
          <div style={{ maxHeight: 'calc(100vh - 15rem)', overflow: 'auto', flexWrap: isListView ? 'nowrap' : 'wrap' }} id='scrollableSearchDiv'>
            {searchResults?.length > 0 && (
              <InfiniteScroll
                dataLength={searchResults?.length}
                next={fetchMoreData}
                style={
                  {
                    // display: isListView ? 'flex' : 'grid',
                    // flexDirection: isListView ? 'column' : '',
                    // flexWrap: isListView ? 'nowrap' : '',
                    // gap: isListView ? '' : '2%',
                  }
                } //To put endMessage and loader to the top.
                // inverse={true} //
                hasMore={currentPageData?.hasMore}
                loader={<h4>Loading...</h4>}
                scrollableTarget='scrollableSearchDiv'>
                {isListView ? (
                  // List View
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexWrap: 'nowrap',
                    }}>
                    {searchResults?.slice(0.5).map((item, index) => (
                      <ListItem
                        key={index}
                        item={item}
                        activePage={activePage}
                        setActivePage={setActivePage}
                        data={data}
                        parentData={parentData}
                        setParentData={setParentData}
                      />
                    ))}
                  </div>
                ) : (
                  // Grid View
                  <div className='d-flex flex-wrap'>
                    {searchResults?.slice(0.5).map((item, index) => (
                      <GridItem
                        key={index}
                        item={item}
                        activePage={activePage}
                        setActivePage={setActivePage}
                        data={data}
                        parentData={parentData}
                        setParentData={setParentData}
                      />
                    ))}
                  </div>
                )}
              </InfiniteScroll>
            )}
            {isLoading && (
              <div className='d-flex align-items-center justify-content-center' style={{ minHeight: '10rem' }}>
                <Loader type='ball-pulse' color={primary_color} />
              </div>
            )}
            {searchResults?.length === 0 && !isLoading && (
              <div className='d-flex align-items-center justify-content-center'>
                <img src='not_found.png' style={{ width: '20rem' }}></img>
              </div>
            )}
          </div>
        </div>
        {modalPosition && (
          <CMSWidgetModal sx={{ ...modalPosition }} toggleModal={() => onCloseModal()}>
            <CMSSearchResultx
              data={data}
              toggleModal={() => {
                onCloseModal()
              }}
              widget_uuid={widget_uuid}
            />
          </CMSWidgetModal>
        )}
      </div>
    </Fragment>
  )
}

export default SearchResults
