import { AvField, AvForm } from 'availity-reactstrap-validation'
import { Button, Collapse, ListGroup, ListGroupItem, UncontrolledCollapse } from 'reactstrap'

import CMSRichText from '../richText'
import '../cmsWidget.scss'
import { useEffect, useState } from 'react'
import { cms } from '../../../helper'
import { request } from '../../../Redux/Sagas/requests/api'
import { updatePublicPageCMS } from '../../../Helper/publicPageHelper'

const CMSPaymentSuccessAction = ({ data, toggleModal, widget_uuid }) => {
  const [state, updateState] = useState({})
  const [formData, setFormData] = useState({})
  const [isCollapseOpen, setIsCollapseOpen] = useState('edit_text')

  useEffect(() => {
    let obj = {
      heading: data?.heading,
      text: data?.text,
    }

    setFormData({
      ...obj,
    })
  }, [data])

  const onSave = () => {
    let { forms, ...rest } = formData
    let payload = {
      uuid: widget_uuid,
      data: rest,
      forms,
    }
    updatePublicPageCMS(payload, toggleModal)
  }


  return (
    <div>
      <div className='d-flex justify-conent-between'>
        <div className='font-weight-bold'>Edit Content</div>
      </div>
      <hr></hr>
      <AvForm>
        <ListGroup>
          <ListGroupItem id='edit_text' className='editCMSMainTitle font-weight-bold'>
            Edit Text
          </ListGroupItem>
          <Collapse isOpen={isCollapseOpen === 'edit_text'}>
            <div className='p-1 mt-2 mb-2'>
              <div>
                <CMSRichText
                  data={{ field_id: 'heading' }}
                  id='heading'
                  title={'heading'}
                  value={formData?.heading}
                  onChange={(v) => {
                    setFormData((val) => ({
                      ...val,
                      heading: v,
                    }))
                  }}
                />
              </div>
            </div>

            <div className='p-1 mt-2 mb-2'>
              <div>
                <CMSRichText
                  data={{ field_id: 'text' }}
                  id='text'
                  title={'text'}
                  value={formData?.text}
                  onChange={(v) => {
                    setFormData((val) => ({
                      ...val,
                      text: v,
                    }))
                  }}
                />
              </div>
            </div>
          </Collapse>
        </ListGroup>
      </AvForm>
      <div className='d-flex justify-content-end mt-4'>
        <Button
          outline
          color='danger'
          className='mr-2'
          onClick={(e) => {
            e.stopPropagation()
            toggleModal()
          }}>
          Discard
        </Button>
        <Button
          color='info'
          onClick={() => {
            onSave()
          }}>
          Save
        </Button>
      </div>
    </div>
  )
}

export default CMSPaymentSuccessAction
