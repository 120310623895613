import { AvField, AvForm } from 'availity-reactstrap-validation'
import { Button, Collapse, ListGroup, ListGroupItem, UncontrolledCollapse } from 'reactstrap'

import CMSRichText from '../richText'
import '../cmsWidget.scss'
import { useEffect, useState } from 'react'
import { cms } from '../../../helper'
import { updatePublicPageCMS } from '../../../Helper/publicPageHelper'

const CMSReservation = ({ data, toggleModal, widget_uuid }) => {
  const [state, updateState] = useState({})
  const [formData, setFormData] = useState({})
  const [isCollapseOpen, setIsCollapseOpen] = useState('edit_cms_reservation_expire')

  useEffect(() => {
    let obj = {}
    obj['heading'] = data?.heading

    obj['sub_heading'] = data?.sub_heading
    obj['button_text'] = data?.button_text

    setFormData({
      ...obj,
      // search_limit_error: data?.search_limit_error,
    })
  }, [data])

  const onSave = () => {
    let payload = {
      uuid: widget_uuid,
      data: formData,
    }
    updatePublicPageCMS(payload, toggleModal)
  }


  return (
    <div>
      <div className='d-flex justify-conent-between'>
        <div className='font-weight-bold'>Edit Content</div>
      </div>
      <hr></hr>
      <AvForm>
        <ListGroup>
          <ListGroupItem id='edit_cms_reservation_expire' className='editCMSMainTitle font-weight-bold'>
            Edit Text
          </ListGroupItem>
          <Collapse isOpen={isCollapseOpen === 'edit_cms_reservation_expire'}>
            <div className='p-1 mt-2 mb-2'>
              <div>
                {/* <AvField
                  name='heading'
                  label='Heading'
                  value={formData[data?.heading]}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      [data?.heading]: e.target.value,
                    })
                  }}></AvField> */}
                <CMSRichText
                  data={{ field_id: 'reservation_heading' }}
                  id='reservation_heading'
                  title={'Heading'}
                  value={formData?.heading}
                  onChange={(v) => {
                    setFormData((val) => ({
                      ...val,
                      heading: v,
                    }))
                  }}
                />
              </div>
            </div>
            <div className='p-1 mt-2 mb-2'>
              <div>
                <CMSRichText
                  data={{ field_id: 'reservation_sub_heading' }}
                  id='reservation_sub_heading'
                  title={'Sub Heading'}
                  value={formData?.sub_heading}
                  onChange={(v) => {
                    setFormData((val) => ({
                      ...val,
                      sub_heading: v,
                    }))
                  }}
                />
              </div>
            </div>
            <div className='p-1 mt-2 mb-2'>
              <div>
                <AvField
                  name='button_text'
                  label='Button Text'
                  value={formData?.button_text}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      button_text: e.target.value,
                    })
                  }}></AvField>
              </div>
            </div>
          </Collapse>
        </ListGroup>
      </AvForm>
      <div className='d-flex justify-content-end mt-4'>
        <Button
          outline
          color='danger'
          className='mr-2'
          onClick={(e) => {
            e.stopPropagation()
            toggleModal()
          }}>
          Discard
        </Button>
        <Button color='info' onClick={() => onSave()}>
          Save
        </Button>
      </div>
    </div>
  )
}

export default CMSReservation
