  

import {
  DOWNLOAD_REF_CHAR_DOC,
  DOWNLOAD_REF_CHAR_PDF,
  DOWNLOAD_REF_CHAR_TIME_DOC,
  DOWNLOAD_REF_CHAR_TIME_PDF,
  DOWNLOAD_REF_CHAR_TIME_XLSX,
  DOWNLOAD_REF_CHAR_XLSX,
} from './constant'

export const getDownloadRefValue = (row) => {
  let char_count, char_time

  if (row?.header?.includes('pdf')) {
    char_count = DOWNLOAD_REF_CHAR_PDF
    char_time = DOWNLOAD_REF_CHAR_TIME_PDF
  } else if (row?.header?.includes('doc') || row?.header?.includes('docx')) {
    char_count = DOWNLOAD_REF_CHAR_DOC
    char_time = DOWNLOAD_REF_CHAR_TIME_DOC
  } else if (row?.header?.includes('xlsx') || row?.header?.includes('xls')) {
    char_count = DOWNLOAD_REF_CHAR_XLSX
    char_time = DOWNLOAD_REF_CHAR_TIME_XLSX
  } else {
    char_count = DOWNLOAD_REF_CHAR_PDF
    char_time = DOWNLOAD_REF_CHAR_TIME_PDF
  }

  return {
    char_count,
    char_time,
  }
}


