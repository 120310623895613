import PageHeader from './PageHeader'
import '../../assets/permissions/permissions.scss'
import PermissionTab from './PermissionTab'
import { checkPermissionToEditPermission } from '../../helper'

const PermissionTable = () => {
  if (!checkPermissionToEditPermission()) return <div className='text-center body1'>Permission Denied</div>
  return (
    <>
      {/* <PageHeader text={'Permissions'} /> */}
      <div className='mt-5'>
        <PermissionTab />
      </div>

    </>
  )
}

export default PermissionTable
