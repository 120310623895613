import moment from 'moment'
import { get } from 'lodash'

import { checkIsDataUpdated, getTabNameForLogs } from '../../../../Pages/Logs/logHelper'
import { primary_color } from '../../../../Helper/uiHelper'
import { tidyName } from '../../../../helper'

const getValue = (data) => {
  return data?.name || data?.location?.name
}

const ShowDifference = ({ data, original_data, updated_data, onExpand, difference, parentPageName, fields }) => {
  return (
    <div>
      <div className='permission-table'>
        <div className='mb-2 cursor-pointer' onClick={() => onExpand()}>
          <i class='fa-sharp fa-solid fa-arrow-left  m-3'></i>
          Go Back
        </div>
        <div className={`permission-header`} style={{ color: primary_color }}>
          <div className='d-flex align-items-center'>
            <div style={{ flex: 1 }}>
              <span className='font-weight-bold'>{data?.updated_by}</span>
              {` has booked the locker`}
            </div>
            {data?.updated_at && <div>{moment.utc(data?.updated_at).format('MMM D  YYYY, HH:mm')}</div>}
          </div>
        </div>
        {updated_data?.map((i) => {
          return (
            <>
              <div className='p-4'>
                {fields?.map((item) => {
                  return (
                    <div className='d-flex mb-2 align-items-center' style={{ width: '100%' }}>
                      <div className='font-weight-bold mb-2' style={{ width: '40%' }}>
                        {item?.label}:
                      </div>
                      <div style={{ width: '30%' }}>{get(i, item?.field_id)}</div>
                    </div>
                  )
                })}
                {i?.questions_and_answers &&
                  Object.keys(i?.questions_and_answers)?.map((key) => {
                    return (
                      <div className='d-flex mb-2 align-items-center' style={{ width: '100%' }}>
                        <div className='font-weight-bold mb-2' style={{ width: '40%' }}>
                          {tidyName(key)}:
                        </div>
                        <div style={{ width: '30%' }}>{i?.questions_and_answers[key]}</div>
                      </div>
                    )
                  })}
              </div>
              <hr></hr>
            </>
          )
        })}
      </div>
    </div>
  )
}

const PaymentCreated = ({ data, onExpand, expandedId, parentPageName }) => {
  let updated_data = data?.updated_data && JSON.parse(data?.updated_data)
  let original_data = data?.original_data && JSON.parse(data?.original_data)

  const fields = [
    {
      field_id: 'item_id_label',
      label: 'Locker Name',
    },
    {
      field_id: 'location_id_label',
      label: 'Block Name',
    },
    {
      field_id: 'item_date_time_id_label',
      label: 'Academic Year',
    },
  ]

  if (expandedId) {
    return (
      <ShowDifference
        data={data}
        onExpand={onExpand}
        updated_data={Array.isArray(updated_data) ? updated_data : [updated_data]}
        original_data={Array.isArray(original_data) ? original_data : [original_data]}
        fields={fields}></ShowDifference>
    )
  }
  return (
    <div className='d-flex align-items-center p-3' style={{ borderBottom: '1px solid #e0e0e0' }}>
      <div>
        <i
          class='fa-solid fa-arrow-up-right-from-square mr-2 cursor-pointer'
          style={{ color: primary_color }}
          onClick={() => {
            onExpand(data?.id)
          }}></i>
      </div>
      <div style={{ flex: 1 }}>
        <span className='font-weight-bold'>{data?.updated_by}</span>
        {` has booked the locker`}
      </div>
      {data?.updated_at && <div>{moment.utc(data?.updated_at).format('MMM D  YYYY, HH:mm')}</div>}
    </div>
  )
}

export default PaymentCreated
