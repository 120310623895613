import React, { useEffect, useMemo, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Button as ReactButton, Container } from 'reactstrap'

import { useHistory, useLocation, withRouter } from 'react-router-dom'

import {
  can,
  checkIsAdminOrManager,
  clearCompletedQuestionnaireByGridId,
  clearDeletedIdsRedux,
  cms,
  dataConnect,
  getExtendArea,
  getFileteredRowAfterRemovingDeletedRow,
  getTableColumns,
  isDirectUpdateConnectTemplate,
} from '../../../../helper'

import Table from '../../../Table/table'
import CMSTabs from '../../../tabs'

import { SetFullPage, setQuestionnaire, setTabFormData, triggerGlobalSearch } from '../../../../Redux/Reducers/page'
import {
  addDataStatusClear,
  addDataStatusSet,
  addGridDataModalOpen,
  callConnectApi,
  callFreeApi,
  clearCurrentRowData,
  clearLastDataConnectionData,
  clearParentTableData,
  clearTableDropDown,
  saveFilter,
  setConnectTemplateDataRedux,
  setCurrentRow,
  setDeletedRowIdInRedux,
  setFilterBy,
  setGridNote,
  setGridSetting,
  setTableDropDown,
  setTableDropDownFullData,
  submitRequest,
  updatePageDataRedux,
} from '../../../../Redux/Reducers/CMS'
import { addGridDataModalClose, fetchScheduleApiData } from '../../../../Redux/Reducers/CMS'
import RepeatingForm from '../repeatingForm'
import GridPrintPage from '../Print/gridPrintPage'
import usePrevious from '../../../../CustomHook/usePrevious'
import { request } from '../../../../Redux/Sagas/requests/api'
import { setNotification } from '../../../../Redux/Reducers/notificationHandling'
import GridNote from './gridNotes'
import moment from 'moment'
import { setQueue } from '../../../../Redux/Reducers/queue'
import ExpandableRow from '../ExpandableRow/expandableRow'
import GridButton from './gridButtons'
import GridAction from './gridAction'
import {
  clearConnectTemplateReduxData,
  getChildTableColumn,
  getColorPillByPageName,
  getColumns,
  getDataByPageInGrid,
  getExportPayload,
  getGridDataBySearch,
  getGridDataBySort,
  getPayloadToLoadGridData,
  triggerButtonActionsInGrid,
  updateColorPill,
} from './gridHelper'
import { setLoader } from '../../../../Redux/Reducers/loader'
import { reduxLoad } from '../../../../Redux/Reducers'
import { updateProgressDataByTabame } from '../../../../progressHelper'

const getDefaultRowOption = () => {
  const options = []
  for (let i = 10; i <= 1000; i = i + 10) {
    let option = {
      label: i,
      value: i,
    }
    options.push(option)
  }
  return options
}

const select = (state) => ({
  queue: state.Queue,
  coreData: state.CMS.coreData,
  pages: state.CMS.coreData.page,
  cmsData: state.CMS.cmsData,
  triggerGlobalSearchValue: state.Page.triggerGlobalSearch,
  globalSearchValue: state.Page.globalSearchValue,
  savedFilters: state.CMS.savedFilters,
  lastDataConnectionData: state.CMS.lastDataConnectionData,
  navMenu: state.CMS.coreData.nav,
  currentRow: state.CMS.currentRow,
  addGridDataModal: state.CMS.addGridDataModal,
  addMainGridData: state.CMS.addMainGridData,
  primaryColor: state.ThemeOptions.primaryColor,
  loader: state.Loader.loader,
  gridSettingRedux: state.CMS.gridSetting,
  parentTableDataInRedux: state.CMS.parentTableData,
  gridNote: state.CMS.gridNote,
  updateApiData: state.CMS?.updateApiData?.updated_data,
  connectTemplateDataRedux: state.CMS.connectTemplateDataRedux,
  deletedRowIdRedux: state.CMS.deletedRowIdRedux,
  domain_id: state.CMS.selected_domain_id,
  permissions: state.Permissions,
  filterByRedux: state.CMS.filterBy,
  gridAdvancedFilter: state.CMS.gridAdvancedFilter,
})

const MainGrid = ({
  // tableGrid,
  queue,
  // triggerButtonActions,

  triggerGlobalSearchValue,
  globalSearchValue,
  // setting,
  // gridInitialPayload,
  cmsData,
  actionGridData = {},
  refreshGrid,
  clearFilters,
  updateRefreshGrid,
  updateClearFilters,
  selectedGrid,
  savedFilters,
  pages,
  currentRow,
  addGridDataModal,
  mainPageName,
  // isRepeatingForm,
  loader,
  updateSelectedAdvancedGrid,
  gridSettingRedux,
  content,
  addMainGridData,
  isModel,
  parentTableDataInRedux,
  currentTabData,
  parentPageName,
  pageName,
  addDataDetail,
  setAddDataDetail,
  setQuestionnaireAction,
  questionnaireAction,
  activeTabNumber,
  isTab,
  gridNote,
  updateApiData = {},
  connectTemplateDataRedux,
  deletedRowIdRedux,
  domain_id,
  permissions,
  filterByRedux,
  setGlobalUIFilter,
  globalUIFilter,
  editedReportsData,
  setEditedReportsData,
  customAddFormOpen,
  setCustomAddFormOpen,
  updateAdnvacedFilter,
  gridAdvancedFilter,
  adnvacedFilter,
  setEditRateTemplateOpen,
  isRateApproved,
  setIsRateApproved
}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const query = new URLSearchParams(location.search)
  const isPrintView = query.get('print')
  const selected_ids = query.get('selected_ids') && parseInt(query.get('selected_ids'))
  const search_field = query.get('search_field') && query.get('search_field')

  const [tableGrid, setTableGrid] = useState({})
  const lastDataConnectionData = useSelector((state) => state.CMS.lastDataConnectionData)
  const user = useSelector((state) => state.User.user)

  const [visiblePreference, updatePreferenceModal] = useState(false)
  const [preferenceColumns, updatePreferenceColumns] = useState()
  const [recPerPage, setRecPerPage] = useState()
  const [tempPreferenceColumns, updateTempPreferenceColumns] = useState(preferenceColumns)
  const [mainGrid, updateMainGrid] = useState()
  const [grid, updateGrid] = useState({})
  const [gridType, updateSelectedGrid] = useState(selectedGrid)
  const [gridUpdated, updateGridUpdated] = useState()
  const [gridData, updateGridData] = useState({ data: [], main: true })
  const [controllerNotFound, updateControllerNotFound] = useState({
    message: [],
  })
  const [gridFilters, updateGridFilters] = useState({})
  const [gridSelectedRow, updategridSelectedRow] = useState([])
  const [gridInitialPayload, updateGridInitialPayload] = useState()
  const [initialPayload, updateInitialPayload] = useState(gridInitialPayload)
  const [gridColumn, updateGridColumn] = useState({
    checkbox: false,
    columns: [],
    sortBy: [],
  })
  const [hasQueue, setHasQueue] = useState({
    grid: false,
    queues: [],
    loading: false,
  })
  const [repeated_column_found, set_repeated_column_found] = useState(false)
  const [liveRefresh, setLiveRefresh] = useState(gridSettingRedux[mainGrid?.id]?.live_refresh)
  const [editableColumn, setEditableColumn] = useState(false)
  const [selectedColorBadge, setSelectedColorBadge] = useState(
    getColorPillByPageName(currentTabData?.uuid) || content?.main_grid?.grid_colors?.filter((color) => color?.default_active)?.map((item) => item?.id)
  )
  const [colorBadgeClicked, setColorBadgeClicked] = useState(false)
  const [filterOn, setFilterOn] = useState(false)
  const [setting, setSetting] = useState()

  const [groupRow, setGroupRow] = useState(false)
  const [gridNotSyncWithDB, setGridNotSyncWithDB] = useState(false)
  const [activeFilterBy, setActiveFilterBy] = useState()
  const [filterManage, setFilterManage] = useState({
    selected: null,
    filter_group_data: '',
    filter_in: false,
    filter_out: false,
  })
  const [templateApiCalled, setTemplateApiCalled] = useState({
    currentRow: false,
  })
  const [connectTemplateInitialCallDone, setConnectTemplateInitialCallDone] = useState(false)
  const [connectTemplateLoading, setConnectTemplateLoading] = useState(false)
  const [expandAllRow, setExpandAllRow] = useState(false)


  const tableOptions = useState((state) => state?.CMS?.tableOptions)

  const isRepeatingForm = tableGrid?.type === 'REPEATING_FORM' ? true : false

  const previousSelectedGrid = usePrevious(selectedGrid)

  const addDataStatus = useSelector((state) => state.CMS.addDataAdded)
  const page_data = pages[parentPageName]

  const getDataByPage = (page) => {
    getDataByPageInGrid(gridRelatedData, page, updateTableData)
  }

  const getBySearch = (search, sort) => {
    getGridDataBySearch(gridRelatedData, search, sort, updateGridFilters, selected_ids, currentTabData, updateInitialPayload)
  }

  const getBySort = (sort) => {
    if (!sort) return null
    getGridDataBySort(gridRelatedData, sort, selected_ids, currentTabData, updateInitialPayload, updateMainGrid)
  }
  const gridRelatedData = {
    page_data,
    content,
    tableGrid,
    mainGrid,
    selectedGrid,
    currentTabData,
    isModel,
    selectedColorBadge,
    filterManage,
    // defaultSortColumn,
    selected_ids,
    refreshGrid,
    clearFilters,
    gridInitialPayload,
    triggerGlobalSearchValue,
    initialPayload,
    globalSearchValue,
    parentPageName,
    pageName,
    gridFilters,
    savedFilters,
    currentTabData,
    pages,
    mainPageName,
    isTab,
    setAddDataDetail,
    activeTabNumber,
    updateRefreshGrid,
    updateClearFilters,
    updateSelectedAdvancedGrid,
    domain_id,
    setQuestionnaireAction,
    recPerPage,
    activeFilterBy,
    setActiveFilterBy,
    currentTabData,
    sortBy: gridColumn.sortBy,
    getBySearch,
    setGlobalUIFilter,
    globalUIFilter,
    editedReportsData,
    setEditedReportsData: () => {},
    customAddFormOpen,
    setCustomAddFormOpen,
    gridSelectedRow,
    updateAdnvacedFilter,
    gridAdvancedFilter,
    adnvacedFilter,
    gridData,
    search_field,
    setEditRateTemplateOpen,
    setIsRateApproved,
    isRateApproved
  }
  //

  // TO check table data is updated or not
  useEffect(() => {
    if (updateApiData[content?.source_table] && updateApiData[content?.source_table]?.length > 0 && gridUpdated) {
      const gridUpdatedDate = gridUpdated && new Date(gridUpdated)
      var offset = gridUpdatedDate.getTimezoneOffset()
      const utcGridUpdated = new Date(gridUpdatedDate.getTime() + offset * 60000)
      const lastSyncDate = gridUpdated && new Date(utcGridUpdated)
      const lastUpdatedDateInDB = new Date(updateApiData[content?.source_table])
      if (lastSyncDate - lastUpdatedDateInDB < 0) {
        setGridNotSyncWithDB(true)
        clearConnectTemplateReduxData(gridRelatedData)
        clearDeletedIdsRedux(parentPageName, pageName, content?.source_table, currentRow && currentRow?.length > 0 && !isModel)
      } else {
        setGridNotSyncWithDB(false)
      }
    }
  }, [updateApiData, gridUpdated])

  //To clear redux data if selected ids filter available
  const prevSelectedIds = usePrevious(selected_ids)
  useEffect(() => {
    if (prevSelectedIds !== selected_ids && (prevSelectedIds || selected_ids)) {
      clearConnectTemplateReduxData(gridRelatedData)
    }
  }, [selected_ids, mainGrid])

  //to re-call api if data added or deleted if not stored in redux
  useEffect(() => {
    if (addDataStatus) {
      setTimeout(() => {
        dispatch(addDataStatusClear())
      }, 2000)
    }
  }, [addDataStatus])

  //if data not available in redux in case of rerender, it will call connect-template api again
  useEffect(() => {
    if (connectTemplateDataRedux && !connectTemplateDataRedux[parentPageName]?.[pageName]) {
      updateRefreshGrid(true)
    }
  }, [connectTemplateDataRedux])

  useEffect(() => {
    setTableGrid(content)
    setSetting(content?.grid_settings)

    updateGridInitialPayload({ ...content[selectedGrid].initial_search_payload, ...content[selectedGrid].initial_filter_payload })
    if (filterByRedux[content?.name]) {
      setActiveFilterBy(filterByRedux[content?.name])
    } else {
      setActiveFilterBy(content?.main_grid?.grid_filter_cols?.[0]?.field_id)
      dispatch(
        setFilterBy({
          ...filterByRedux,
          [content?.name]: content?.main_grid?.grid_filter_cols?.[0]?.field_id,
        })
      )
    }
  }, [JSON.stringify(content)])
  const [render, ReRender] = useState(false)

  const selected_row_id = gridSelectedRow?.map((row) => {
    return row?.original?.id
  })
  useEffect(() => {
    ReRender(!render)
  }, [JSON.stringify(selected_row_id), permissions?.length])

  useEffect(() => {
    if (JSON.stringify(tableGrid)?.length > 2) {
      updateMainGrid(tableGrid[selectedGrid])
      updatePreferenceColumns(tableGrid[selectedGrid].component_call_preferences_form?.fields_order)
      updateTempPreferenceColumns(tableGrid[selectedGrid].component_call_preferences_form?.fields_order)
      setRecPerPage(tableGrid[selectedGrid].component_call_preferences_form?.rec_per_page)
      //rec_per_page
    }
  }, [tableGrid])

  const getPayloadForConnectTemplate = () => {
    return getPayloadToLoadGridData(gridRelatedData, updateInitialPayload)
  }

  useEffect(() => {
    gridColumn?.columns?.map((column) => {
      if (column.editable === 1 && !editableColumn) {
        setEditableColumn(true)
      }
    })
  }, [gridColumn?.columns])

  useEffect(() => {
    if (gridSettingRedux[mainGrid?.id]?.live_refresh) {
      const source_table = page_data?.component_call_buttons?.filter((item) => item.component_name === 'hidden_tab')
      const methods = page_data?.component_call_buttons
        ?.filter((item) => item.component_name !== 'hidden_tab' && item.source_table !== source_table[0]?.source_table)
        .map((item) => item.source_table)

      setTimeout(() => {
        setLiveRefresh(!liveRefresh)
        if (gridSettingRedux[mainGrid?.id]?.live_refresh) {
          if (currentRow[currentRow?.length - 1]?.id) {
            const currentTabData = page_data?.component_call_buttons
              ?.filter((tab) => tab.is_grid === 1 || tab.is_grid == true)
              .filter((item) => item.component_call_form[0]?.main_grid === mainGrid || item.component_call_form[0]?.restore_grid === mainGrid)
            let payload = getPayloadForConnectTemplate()
            if (JSON.stringify(actionGridData) !== '{}' && actionGridData) {
              setConnectTemplateLoading(true)
              dataConnect(actionGridData, '', mainGrid.name, null, true)
            } else {
              setConnectTemplateLoading(true)
              dataConnect(mainGrid.component_call_data_connection, payload, mainGrid.name, null, true)
            }
            clearCompletedQuestionnaireByGridId(mainGrid?.id)
          } else {
            if (source_table && source_table.length > 0) {
              let payload = getPayloadForConnectTemplate()
              setConnectTemplateLoading(true)
              dataConnect({ action: 'connect_template' }, payload, mainGrid.name, gridSettingRedux[mainGrid?.id]?.live_refresh ? true : false, true)
              clearCompletedQuestionnaireByGridId(mainGrid?.id)
            }
          }
          // updateRefreshGrid(true);
        }
      }, 5000)
    }
  }, [liveRefresh, gridSettingRedux[mainGrid?.id]?.live_refresh])

  useEffect(() => {
    const column = mainGrid?.component_call_cols
    const unique_column = []
    column?.forEach((item) => {
      if (!unique_column.includes(JSON.stringify(item))) {
        unique_column.push(JSON.stringify(item))
      }
    })
    if (unique_column?.length < column?.length) {
      set_repeated_column_found(true)
    } else {
      set_repeated_column_found(false)
    }

    let gridSetting = {}
    gridSetting[mainGrid?.id] = {
      live_refresh: false,
      inline_edit_button: false,
    }
    if (!Object.keys(gridSettingRedux)?.includes(mainGrid?.id?.toString())) {
      dispatch(setGridSetting(gridSetting))
    }
  }, [mainGrid])

  const toggleInlineEditGridSetting = () => {
    if (!gridSettingRedux[mainGrid?.id]?.inline_edit_button) {
      let newGridSetting = {}
      newGridSetting[mainGrid?.id] = {
        ...gridSettingRedux[mainGrid?.id],
        inline_edit_button: !gridSettingRedux[mainGrid?.id]?.inline_edit_button,
        live_refresh: false,
      }
      dispatch(setGridSetting(newGridSetting))
    } else {
      let newGridSetting = {}
      newGridSetting[mainGrid?.id] = {
        ...gridSettingRedux[mainGrid?.id],
        inline_edit_button: !gridSettingRedux[mainGrid?.id]?.inline_edit_button,
      }

      dispatch(setGridSetting(newGridSetting))
    }
  }
  const toggleLiveRefreshGridSetting = () => {
    let newGridSetting = {}
    if (!gridSettingRedux[mainGrid?.id]?.live_refresh) {
      newGridSetting[mainGrid?.id] = {
        ...gridSettingRedux[mainGrid?.id],
        live_refresh: !gridSettingRedux[mainGrid?.id]?.live_refresh,
        inline_edit_button: false,
      }
    } else {
      newGridSetting[mainGrid?.id] = {
        ...gridSettingRedux[mainGrid?.id],
        live_refresh: !gridSettingRedux[mainGrid?.id]?.live_refresh,
      }
    }

    dispatch(setGridSetting(newGridSetting))
  }

  const UpdatePreferenceColumn = () => {
    updatePreferenceColumns(tempPreferenceColumns)
    const updatedMainGrid = {
      ...mainGrid,
      component_call_preferences_form: {
        ...mainGrid.component_call_preferences_form,
        fields_order: tempPreferenceColumns,
        rec_per_page: recPerPage,
      },
    }
    let preference_column_payload = Object.keys(tempPreferenceColumns || {})?.map((key) => {
      return {
        ...tempPreferenceColumns[key],
        field_id: key,
      }
    })
    const payload = {
      grid_id: mainGrid?.id,
      preference: {
        columns: preference_column_payload,
        rec_per_page: recPerPage?.value,
      },
    }

    const hide_column = []
    preference_column_payload?.map((item) => {
      if (!item?.display) {
        hide_column?.push(item?.field_id)
      }
    })

    request('save-user-grid-preference', 'POST', payload).then((res) => {
      if (res.status === 200 || res.status === 202) {
        updateMainGrid(updatedMainGrid)
        dispatch(reduxLoad())
        updateGridColumn(
          getTableColumns(
            updatedMainGrid.component_call_cols?.filter((col) => {
              if (hide_column?.includes(col?.field_id)) {
                return false
              } else {
                return true
              }
            }),
            updatedMainGrid.component_call_expand_fields,
            (data, data1, data2, data3) => triggerButtonActions(data, data1, data2, tableGrid.source_table, tableGrid?.main_grid),
            tempPreferenceColumns,
            refreshGrid || clearFilters ? null : savedFilters[tableGrid.name] || {},
            gridData?.data || [],
            tableGrid,
            pageName,
            parentPageName,
            isTab,
            isModel,
            gridRelatedData
          )
        )
      } else {
        dispatch(setNotification({ type: 'error', message: 'Something went wrong!' }))
      }
    })
  }

  const renderRowSubComponent = (row, editMode) => {
    const columns = getColumns(gridRelatedData)

    if (columns?.includes('questionnaireExpand') || tableGrid?.main_grid?.grid_expand_table?.length > 0) {
      return (
        <>
          {getExtendArea(row, mainGrid.component_call_expand_fields, editMode, tableOptions, tableGrid?.source_table)}
          <ExpandableRow
            tableGrid={tableGrid}
            row={row}
            isQuestionnaireExpand={columns?.includes('questionnaireExpand')}
            isGridExpandTable={tableGrid?.main_grid?.grid_expand_table?.length > 0}
          />
        </>
      )
    }

    return getExtendArea(row, mainGrid.component_call_expand_fields, editMode, tableOptions, tableGrid?.source_table)
  }

  const changeRowsPerPage = (total) => {
    if (total === 0 || !total) return null
    const source_table = page_data?.component_call_buttons?.filter((item) => item.component_name === 'hidden_tab')
    const page_main_table = source_table[0]?.component_call_form[0]?.main_grid
    const methods = page_data?.component_call_buttons
      ?.filter((item) => item.component_name !== 'hidden_tab' && item?.source_table)
      .map((item) => item?.source_table)
    let columns
    if (page_main_table?.component_call_cols && page_main_table?.component_call_cols.length > 0) {
      // columns = page_main_table?.component_call_cols?.filter((item) => item.field_id).map((item) => item.field_id)
    }
    if (page_main_table?.component_call_expand_fields && page_main_table?.component_call_expand_fields.length > 0) {
      // columns = [...columns, ...page_main_table?.component_call_expand_fields?.filter((item) => item.field_id).map((item) => item.field_id)]
    }
    let payload = {
      ...initialPayload,
      rec_per_page: total,
      grid_id: gridRelatedData?.content?.[gridRelatedData?.selectedGrid]?.id || gridRelatedData?.content?.main_grid?.id,
      // source_table: source_table[0]?.source_table,
      // columns: columns,
      // methods: methods,
    }
    if (selectedColorBadge?.length > 0 && selectedGrid !== 'restore_grid') {
      payload = { ...payload, 'filter-group-color': selectedColorBadge }
    }
    if (filterManage?.filter_in || filterManage?.filter_out) {
      payload = { ...payload, 'filter-group-data': filterManage?.filter_in ? 'in' : 'out', grid_id: mainGrid?.id }
    }
    if (mainGrid?.where_clause) {
      payload = { ...payload, clause_data: mainGrid?.where_clause }
    }
    // payload = { ...payload, clause_data: mainGrid?.where_clause }
    if (selected_ids) {
      payload[`-filter-${search_field}`] = selected_ids
    }
    setConnectTemplateLoading(true)
    dataConnect(mainGrid.component_call_data_connection, payload, mainGrid.name, null, true)
    clearCompletedQuestionnaireByGridId(mainGrid?.id)
  }

  const loadTable = (isQueue = false) => {
    const source_table = page_data?.component_call_buttons?.filter((item) => item.component_name === 'hidden_tab')
    const methods = page_data?.component_call_buttons
      ?.filter((item) => item.component_name !== 'hidden_tab' && item.source_table)
      .map((item) => item?.source_table)
    const columns = getColumns(gridRelatedData)
    let payload = {
      source_table: source_table[0]?.source_table,
      columns: columns,
      methods: methods,
      ...initialPayload,
    }
    if (selectedColorBadge?.length > 0 && selectedGrid !== 'restore_grid') {
      payload = { ...payload, 'filter-group-color': selectedColorBadge }
    }
    if (filterManage?.filter_in || filterManage?.filter_out) {
      payload = { ...payload, 'filter-group-data': filterManage?.filter_in ? 'in' : 'out', grid_id: mainGrid?.id }
    }
    if (mainGrid?.where_clause) {
      payload = { ...payload, clause_data: mainGrid?.where_clause }
    }
    // payload = { ...payload, clause_data: mainGrid?.where_clause }
    if (selected_ids) {
      payload[`-filter-${search_field}`] = selected_ids
    }
    setConnectTemplateLoading(true)
    dataConnect(mainGrid.component_call_data_connection, payload, mainGrid.name, isQueue, true)
    clearCompletedQuestionnaireByGridId(mainGrid?.id)
  }

  const triggerButtonActions = (actions, row_data, gridSelectedRows, mainSourceTable, mainGrid) => {
    triggerButtonActionsInGrid(actions, row_data, gridSelectedRows, mainSourceTable, mainGrid, gridRelatedData)
  }

  const prevAddDataStatus = usePrevious(addDataStatus)

  const defaultSortColumn = []

  content?.main_grid?.component_call_cols?.map((col) => {
    if (col?.sort) {
      if (col.sort === 'ASC') {
        defaultSortColumn?.push({ id: col?.field_id, type: 'ASC' })
      } else if (col.sort === 'DESC') {
        defaultSortColumn?.push({ id: col?.field_id, type: 'DESC' })
      }
    }
  })

  useEffect(() => {
    if (actionGridData) {
      dispatch(clearLastDataConnectionData())
    }

    setHasQueue(queue)
    const mainGrid = tableGrid[selectedGrid]

    updateGrid(mainGrid)
    if (!mainGrid) return
    if (
      (mainGrid?.component_call_data_connection && (gridData?.data.length === 0 || colorBadgeClicked) && gridData?.main) ||
      colorBadgeClicked ||
      filterOn ||
      refreshGrid ||
      clearFilters ||
      gridType !== selectedGrid ||
      triggerGlobalSearchValue ||
      (addDataStatus !== prevAddDataStatus && prevAddDataStatus !== undefined) ||
      (prevSelectedIds !== selected_ids && prevSelectedIds) ||
      !connectTemplateInitialCallDone ||
      gridNotSyncWithDB
    ) {
      setColorBadgeClicked(false)

      if (refreshGrid) {
        clearConnectTemplateReduxData(gridRelatedData)
      }
      if (
        mainGrid?.component_call_data_connection.action === 'connect_template' ||
        mainGrid?.component_call_data_connection.action === 'connect_redux' ||
        true
      ) {
        setTemplateApiCalled({
          ...templateApiCalled,
          currentRow: true,
        })
        const source_table = page_data?.component_call_buttons?.filter((item) => item.component_name === 'hidden_tab')

        if (currentRow[currentRow?.length - 1]?.id && !isModel) {
          // const currentTabData = page_data?.component_call_buttons
          //   ?.filter((tab) => tab.is_grid === 1 || tab.is_grid == true)
          //   .filter((item) => item.component_call_form[0]?.main_grid === mainGrid || item.component_call_form[0]?.restore_grid === mainGrid)
          const payload = getPayloadForConnectTemplate()

          if (
            !templateApiCalled?.currentRow ||
            previousSelectedGrid !== selectedGrid ||
            (addDataStatus !== prevAddDataStatus && prevAddDataStatus !== undefined && addDataStatus) ||
            refreshGrid ||
            !connectTemplateInitialCallDone ||
            gridNotSyncWithDB
          ) {
            if (JSON.stringify(actionGridData) !== '{}' && actionGridData) {
              let noLoad = false

              if (
                connectTemplateDataRedux &&
                connectTemplateDataRedux[parentPageName]?.[pageName]?.[`${content?.source_table}-${currentRow[currentRow?.length - 1]?.id}`]?.[1] &&
                !gridNotSyncWithDB
              ) {
                noLoad = true
                updateTableData(
                  connectTemplateDataRedux[parentPageName]?.[pageName]?.[`${content?.source_table}-${currentRow[currentRow?.length - 1]?.id}`]?.[1],
                  false
                )
                // setConnectTemplateLoading(true)
                // dataConnect(actionGridData, payload, mainGrid.name, false, noLoad)
              } else {
                if (gridNotSyncWithDB) {
                  noLoad = true
                }
                setConnectTemplateLoading(true)
                dataConnect(actionGridData, payload, mainGrid.name, false, true)
                clearCompletedQuestionnaireByGridId(mainGrid?.id)
              }
            } else {
              let noLoad = false

              if (
                connectTemplateDataRedux &&
                connectTemplateDataRedux[parentPageName]?.[pageName]?.[`${content?.source_table}-${currentRow[currentRow?.length - 1]?.id}`]?.[1] &&
                !gridNotSyncWithDB
              ) {
                noLoad = true
                updateTableData(
                  connectTemplateDataRedux[parentPageName]?.[pageName]?.[`${content?.source_table}-${currentRow[currentRow?.length - 1]?.id}`]?.[1],
                  false
                )
              } else {
                if (gridNotSyncWithDB) {
                  noLoad = true
                }
                setConnectTemplateLoading(true)
                dataConnect(actionGridData, payload, mainGrid.name, false, true)
                clearCompletedQuestionnaireByGridId(mainGrid?.id)
              }
            }
            setConnectTemplateInitialCallDone(true)
          }
        } else if (!currentRow[currentRow?.length - 1]?.id && currentRow[currentRow?.length - 1]?.temporary_id && !isModel) {
          //   .filter((item) => item.component_call_form[0]?.main_grid === mainGrid || item.component_call_form[0]?.restore_grid === mainGrid)
          const payload = getPayloadForConnectTemplate()
          if (
            !templateApiCalled?.currentRow ||
            previousSelectedGrid !== selectedGrid ||
            (addDataStatus !== prevAddDataStatus && prevAddDataStatus !== undefined && addDataStatus) ||
            refreshGrid ||
            !connectTemplateInitialCallDone ||
            gridNotSyncWithDB
          ) {
            if (JSON.stringify(actionGridData) !== '{}' && actionGridData) {
              let noLoad = false

              if (
                connectTemplateDataRedux &&
                connectTemplateDataRedux[parentPageName]?.[pageName]?.[
                  `${content?.source_table}-${currentRow[currentRow?.length - 1]?.temporary_id}`
                ]?.[1] &&
                !gridNotSyncWithDB
              ) {
                noLoad = true
                updateTableData(
                  connectTemplateDataRedux[parentPageName]?.[pageName]?.[
                    `${content?.source_table}-${currentRow[currentRow?.length - 1]?.temporary_id}`
                  ]?.[1],
                  false
                )
                // setConnectTemplateLoading(true)
                // dataConnect(actionGridData, payload, mainGrid.name, false, noLoad)
              } else {
                if (gridNotSyncWithDB) {
                  noLoad = true
                }
                setConnectTemplateLoading(true)
                dataConnect(actionGridData, payload, mainGrid.name, false, true)
                clearCompletedQuestionnaireByGridId(mainGrid?.id)
              }
            } else {
              let noLoad = false

              if (
                connectTemplateDataRedux &&
                connectTemplateDataRedux[parentPageName]?.[pageName]?.[
                  `${content?.source_table}-${currentRow[currentRow?.length - 1]?.temporary_id}`
                ]?.[1] &&
                !gridNotSyncWithDB
              ) {
                noLoad = true
                updateTableData(
                  connectTemplateDataRedux[parentPageName]?.[pageName]?.[
                    `${content?.source_table}-${currentRow[currentRow?.length - 1]?.temporary_id}`
                  ]?.[1],
                  false
                )
              } else {
                if (gridNotSyncWithDB) {
                  noLoad = true
                }
                setConnectTemplateLoading(true)
                dataConnect(actionGridData, payload, mainGrid.name, false, true)
                clearCompletedQuestionnaireByGridId(mainGrid?.id)
              }
            }
            setConnectTemplateInitialCallDone(true)
          }
        } else {
          if (source_table && source_table.length > 0) {
            if (
              !templateApiCalled?.currentRow ||
              previousSelectedGrid !== selectedGrid ||
              (addDataStatus !== prevAddDataStatus && prevAddDataStatus !== undefined && addDataStatus) ||
              (prevSelectedIds && !selected_ids) ||
              filterOn ||
              refreshGrid ||
              gridNotSyncWithDB
            ) {
              let noLoad = false
              const payload = getPayloadForConnectTemplate()

              if (connectTemplateDataRedux && connectTemplateDataRedux[parentPageName]?.[pageName]?.[1] && !gridNotSyncWithDB) {
                updateTableData(connectTemplateDataRedux[parentPageName]?.[pageName][1], false)
              } else {
                if (gridNotSyncWithDB) {
                  noLoad = true
                }
                setConnectTemplateLoading(true)

                dataConnect({ action: 'connect_template' }, payload, mainGrid.name, gridSettingRedux[mainGrid?.id]?.live_refresh ? true : false, true)
                clearCompletedQuestionnaireByGridId(mainGrid?.id)
                setConnectTemplateInitialCallDone(true)
              }
            }
            setFilterOn(false)
          }
        }
      }

      if (refreshGrid || clearFilters) {
        updateGridFilters({})
        updateGridColumn({
          checkbox: false,
          columns: [],
          sortBy: [],
        })
        updateInitialPayload({ ...content[selectedGrid].initial_search_payload, ...content[selectedGrid].initial_filter_payload })
        updategridSelectedRow([])
        updateMainGrid(tableGrid[selectedGrid])
      }
    }
    if (clearFilters) {
      let newFilters = { ...savedFilters }
      delete newFilters[tableGrid?.name]

      dispatch(saveFilter(newFilters))
    }
    if (lastDataConnectionData.notFound && lastDataConnectionData.name === mainGrid.name && lastDataConnectionData.notFoundMessage) {
      updateControllerNotFound({
        message: lastDataConnectionData.notFoundMessage,
      })
    }
    updateGridColumn(
      getTableColumns(
        mainGrid?.component_call_cols,
        mainGrid?.component_call_expand_fields,
        (data, data1, data2, data3) => triggerButtonActions(data, data1, data2, tableGrid.source_table, tableGrid?.main_grid),
        mainGrid.component_call_preferences_form.fields_order,
        // refreshGrid || clearFilters ? mainGrid.component_call_preferences_form.fields_order : preferenceColumns,
        refreshGrid || clearFilters ? null : savedFilters[tableGrid.name] || {},
        gridData?.data || [],
        tableGrid,
        pageName,
        parentPageName,
        isTab,
        isModel,
        gridRelatedData
      )
    )
    updateRefreshGrid(false)
    if (!gridSettingRedux[mainGrid?.id]?.live_refresh) {
      updateClearFilters(false)
    }

    updateMainGrid(mainGrid)
    updateSelectedGrid(selectedGrid)
    dispatch(triggerGlobalSearch(false))
    if (queue.loading !== hasQueue.loading) {
      loadTable(true)
    }
  }, [
    refreshGrid,
    clearFilters,
    tableGrid,
    selectedGrid,
    triggerGlobalSearchValue,
    loader,
    selectedColorBadge,
    filterManage?.filter_in,
    filterManage?.filter_out,
    addDataStatus,
    selected_ids,
    gridNotSyncWithDB,
  ])
  const prevActiveFilterBy = usePrevious(activeFilterBy)
  useEffect(() => {
    if (prevActiveFilterBy && prevActiveFilterBy !== activeFilterBy && activeFilterBy) {
      updateGridColumn(
        getTableColumns(
          mainGrid?.component_call_cols,
          mainGrid?.component_call_expand_fields,
          (data, data1, data2, data3) => triggerButtonActions(data, data1, data2, tableGrid.source_table, tableGrid?.main_grid),
          mainGrid.component_call_preferences_form.fields_order,
          // refreshGrid || clearFilters ? mainGrid.component_call_preferences_form.fields_order : preferenceColumns,
          refreshGrid || clearFilters ? null : savedFilters[tableGrid.name] || {},
          gridData?.data || [],
          tableGrid,
          pageName,
          parentPageName,
          isTab,
          isModel,
          gridRelatedData
        )
      )
    }
  }, [activeFilterBy])
  const updateTableData = (lastDataConnectionData, needToUpdateInRedux = true) => {
    if (
      lastDataConnectionData &&
      // lastDataConnectionData.updatedAt !== gridUpdated &&
      lastDataConnectionData.data &&
      lastDataConnectionData.data.meta &&
      (lastDataConnectionData.name === mainGrid?.name || lastDataConnectionData.name === content?.main_grid?.name)
    ) {
      const { note, reportsEdit, ...tableRow } = lastDataConnectionData?.data?.data
      setEditedReportsData(reportsEdit)

      const tableRowData = {
        ...lastDataConnectionData?.data,
      }

      tableRowData['data'] = Object.values(tableRow || [{}])
      if (!needToUpdateInRedux) {
        setConnectTemplateLoading(false)
      }

      if (needToUpdateInRedux) {
        let connectTemplateDataReduxToUpdate = {
          ...connectTemplateDataRedux,
        }
        connectTemplateDataReduxToUpdate[parentPageName] = { ...connectTemplateDataReduxToUpdate[parentPageName] }

        let newData = {}
        if (connectTemplateDataRedux[parentPageName]?.[pageName]) {
          newData = {
            ...connectTemplateDataRedux[parentPageName]?.[pageName],
          }
        }
        if (currentRow && currentRow?.length > 0 && !isModel) {
          if (currentRow[currentRow?.length - 1]?.id) {
            newData[`${content?.source_table}-${currentRow[currentRow?.length - 1]?.id}`] = {
              ...newData[`${content?.source_table}-${currentRow[currentRow?.length - 1]?.id}`],
            }
            // ;[`${content?.source_table}-${currentRow[currentRow?.length - 1]?.id}`]
            newData[`${content?.source_table}-${currentRow[currentRow?.length - 1]?.id}`][lastDataConnectionData?.data?.meta?.current_page] = {
              ...lastDataConnectionData,
              reduxUpdatedTime: new Date().toUTCString(),
            }
          } else if (!currentRow[currentRow?.length - 1]?.id && currentRow[currentRow?.length - 1]?.temporary_id) {
            newData[`${content?.source_table}-${currentRow[currentRow?.length - 1]?.temporary_id}`] = {
              ...newData[`${content?.source_table}-${currentRow[currentRow?.length - 1]?.temporary_id}`],
            }
            // ;[`${content?.source_table}-${currentRow[currentRow?.length - 1]?.id}`]
            newData[`${content?.source_table}-${currentRow[currentRow?.length - 1]?.temporary_id}`][
              lastDataConnectionData?.data?.meta?.current_page
            ] = {
              ...lastDataConnectionData,
              reduxUpdatedTime: new Date().toUTCString(),
            }
          }
          updateProgressDataByTabame(currentTabData?.name, lastDataConnectionData?.data?.meta?.total)
        } else {
          newData[lastDataConnectionData?.data?.meta?.current_page] = {
            ...lastDataConnectionData,
            reduxUpdatedTime: new Date().toUTCString(),
          }
        }

        connectTemplateDataReduxToUpdate[parentPageName][pageName] = newData

        if (!selected_ids && selectedGrid !== 'restore_grid') {
          dispatch(setConnectTemplateDataRedux(connectTemplateDataReduxToUpdate))
        }
      }
      dispatch(setGridNote(note))
      setConnectTemplateLoading(false)

      updateGridData(tableRowData)
      updateGridUpdated(lastDataConnectionData.updatedAt)
      updateInitialPayload({
        ...initialPayload,
        rec_per_page: lastDataConnectionData.data ? lastDataConnectionData.data.meta.per_page : 10,
      })
    }
  }

  useEffect(() => {
    updateTableData(lastDataConnectionData)
  }, [lastDataConnectionData])

  const prevRecPerPage = usePrevious(content?.main_grid?.component_call_preferences_form?.rec_per_page?.value)
  useEffect(() => {
    if (
      content?.main_grid?.component_call_preferences_form?.rec_per_page?.value !== prevRecPerPage &&
      content?.main_grid?.component_call_preferences_form?.rec_per_page?.value &&
      prevRecPerPage
    ) {
      updateRefreshGrid(true)
    }
  }, [content?.main_grid?.component_call_preferences_form?.rec_per_page?.value])

  const onPrintClick = () => {
    if (gridSelectedRow.length > 0) {
      const params = new URLSearchParams({ print: true })
      history.replace({
        pathname: location.pathname,
        search: params.toString(),
      })
    }
  }

  const onClickColorBadge = (id) => {
    let data = []
    if (selectedColorBadge?.includes(id)) {
      const badge_array = [...selectedColorBadge]
      const index = badge_array?.indexOf(id)
      badge_array?.splice(index, 1)
      data = [...badge_array]
    } else {
      data = [...selectedColorBadge, id]
    }
    setSelectedColorBadge(data)
    setTemplateApiCalled({
      currentRow: false,
    })
    setColorBadgeClicked(true)
    clearConnectTemplateReduxData(gridRelatedData)
    updateColorPill(currentTabData?.uuid, data)
  }

  useEffect(() => {
    if (isPrintView && gridSelectedRow.length == 0) {
      history.replace({ pathname: location.pathname, search: '' })
    }
  }, [])

  // const { allowed, permission } = grid;
  const grid_colors = mainGrid?.grid_colors
  const grid_filters = mainGrid?.grid_filters
  const grid_filters_in = grid_filters?.filter((filter) => filter?.is_filter_out === 0)
  const grid_filters_out = grid_filters?.filter((filter) => filter?.is_filter_out === 1)

  const filteredTableRow = useMemo(
    () =>
      getFileteredRowAfterRemovingDeletedRow(
        gridData?.data || [],
        parentPageName,
        pageName,
        content?.source_table,
        currentRow && currentRow?.length > 0 && !isModel
      ),
    [gridData?.data, JSON.stringify(gridData?.data || {})]
  )

  if (!grid?.allowed || !can(grid?.permission)) return null

  if (isPrintView) return <GridPrintPage gridSelectedRow={gridSelectedRow} isPrintView={isPrintView} page_data_main={page_data} mainGrid={mainGrid} />

  if (repeated_column_found) {
    return <div>Repeated column found.</div>
  } else {
    return (
      <React.Fragment key={selectedGrid}>
        <Container fluid>
          {(content?.name === 'bookings_booking_reservations' || content?.name === 'bookings_units') &&
            !checkIsAdminOrManager(user?.role?.name) &&
            (currentRow?.[0]?.past_booking || currentRow?.[0]?.is_code_issued || true) && (
              <div class='alert alert-warning' role='alert'>
                Currently, you can't make any changes to your booking. If you need any support we discussed this booking, please raise a ticket.
              </div>
            )}
          <GridAction
            grid_buttons={grid.component_call_grid_buttons}
            getDefaultRowOption={getDefaultRowOption}
            mainGrid={mainGrid}
            tableGrid={tableGrid}
            gridSelectedRow={gridSelectedRow}
            setting={setting}
            triggerButtonActions={triggerButtonActions}
            gridFilters={gridFilters}
            currentTabData={currentTabData}
            updatePreferenceModal={updatePreferenceModal}
            updateTempPreferenceColumns={updateTempPreferenceColumns}
            tempPreferenceColumns={tempPreferenceColumns}
            visiblePreference={visiblePreference}
            preferenceColumns={preferenceColumns}
            UpdatePreferenceColumn={UpdatePreferenceColumn}
            recPerPage={recPerPage}
            setRecPerPage={setRecPerPage}
            clearConnectTemplateReduxData={clearConnectTemplateReduxData}
            gridRelatedData={gridRelatedData}
            parentPageName={parentPageName}
            pageName={pageName}
            isTab={isTab}
            isModel={isModel}
            filteredTableRow={filteredTableRow}
            actionProps={{
              updateRefreshGrid,
              selectedColorBadge,
              onClickColorBadge,
              onPrintClick,
              toggleLiveRefreshGridSetting,
              toggleInlineEditGridSetting,
              setGroupRow,
              setFilterManage,
              setFilterOn,
              connectTemplateLoading,
              editableColumn,
              groupRow,
              grid_colors,
              grid_filters_in,
              filterManage,
              grid_filters_out,
              gridRelatedData,
              expandAllRow,
              setExpandAllRow,
            }}
            isRepeatingForm={isRepeatingForm}
            gridSettingRedux={gridSettingRedux}
            gridNote={gridNote}
            gridData={gridData}
            grid={grid}
            gridNotSyncWithDB={gridNotSyncWithDB}
          />
        </Container>

        <div className='clearfix'></div>

        {isRepeatingForm ? (
          <RepeatingForm
            mainGrid={mainGrid}
            selectedGrid={selectedGrid}
            page_data={page_data}
            setting={setting}
            meta={gridData?.meta}
            getByUrl={(page) => getDataByPage(page)}
            changeRowsPerPage={(total) => changeRowsPerPage(total)}
            initialPayload={initialPayload}
            updategridSelectedRow={updategridSelectedRow}
            gridSelectedRow={gridSelectedRow}
            data={gridData?.data}
            source_table={tableGrid?.source_table}
            childTabs={tableGrid?.child_tabs}
            pageName={pageName}
            parentPageName={parentPageName}
            isTab={isTab}
            tableGrid={tableGrid}
          />
        ) : (
          <Table
            columns={gridColumn.columns}
            sourceTable={tableGrid?.source_table}
            search={(search, sort) => getBySearch(search, sort)}
            sortColumn={(sort) => getBySort(sort)}
            getByUrl={(page) => getDataByPage(page)}
            changeRowsPerPage={(total) => changeRowsPerPage(total)}
            selectedRows={updategridSelectedRow}
            sortBy={gridColumn.sortBy}
            meta={gridData?.meta}
            links={gridData?.links || {}}
            filters={gridColumn.filters}
            data={filteredTableRow}
            setting={setting}
            initialPayload={initialPayload}
            refreshGrid={refreshGrid}
            clearFilters={clearFilters}
            pagination={grid.component_call_pagination}
            className='-striped -highlight'
            updateMyData={(updateMyData) => {}}
            renderRowSubComponent={renderRowSubComponent}
            loadTable={loadTable}
            mainGrid={mainGrid}
            inlineEditActive={gridSettingRedux[mainGrid?.id]?.inline_edit_button}
            groupRow={groupRow}
            pageName={pageName}
            parentPageName={parentPageName}
            isModel={isModel}
            expandAllRow={expandAllRow}
            recPerPage={recPerPage}
            data_validations={tableGrid?.data_validations}
            gridRelatedData={gridRelatedData}
          />
        )}
      </React.Fragment>
    )
  }
}
export default connect(select)(withRouter(MainGrid))
