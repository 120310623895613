import { primary_color } from '../../../Helper/uiHelper'
import { useHistory } from 'react-router-dom'

const AdminDirectEditFail = () => {
  const history = useHistory()
  return (
    <div className='d-flex justify-content-center align-items-center' style={{ minHeight: '20rem' }}>
      <div className='d-flex justify-content-center align-items-center' style={{ minHeight: '20rem' }}>
        <div style={{ width: '30rem' }}>
          <div className='rejected-pulse mb-5'>
            <i className={'position-absolute ' + 'fa-solid fa-check'} style={{ color: 'white', scale: '0.45' }}></i>
          </div>
          <div className='font-weight-bold body1 text-center mb-3 text-danger'>Edit Failed !</div>
          <div className='text-muted text-center subtitle1'>Something went wrong while editing the locker. Please try again later</div>
          <div className='d-flex justify-content-center align-items-center mt-4'>
            <button
              className='btn text-white rounded-pill mt-2'
              type='submit'
              style={{ background: primary_color, fontWeight: 700, width: '14rem' }}
              onClick={() => {
                history.goBack()
              }}>
              Try Again
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminDirectEditFail
