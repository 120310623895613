import { useEffect, useState } from 'react'
import { updatePublicPageCMS } from '../../../Helper/publicPageHelper'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { Button, Collapse, ListGroup, ListGroupItem } from 'reactstrap'

import CMSRichText from '../richText'

const CMSNumberPoint = ({ data, toggleModal, widget_uuid }) => {
  const [formData, setFormData] = useState([])
  const [isCollapseOpen, setIsCollapseOpen] = useState('edit_text')

  useEffect(() => {
    const number_list = []
    data?.map((item) => {
      if (item?.child_widget_data) {
        item?.child_widget_data?.map((i) => {
          number_list?.push({
            uuid: i?.uuid,
            value: i?.value,
            cms_key: i?.cms_key,
            type: i?.component_name,
          })
        })

        if (item?.child_widget_data?.length > 0) {
          number_list?.push({
            type: 'divider',
          })
        }
      }
    })
    setFormData(number_list)
  }, [data])

  const onSave = () => {
    let payload = {
      uuid: widget_uuid,
      data: JSON.parse(JSON.stringify(formData?.filter((item) => item?.type !== 'divider'))),
    }

    updatePublicPageCMS(payload, toggleModal)
    toggleModal()
  }

  const onChangeValue = (uuid, v) => {
    setFormData((oldData) => {
      const newFormData = oldData?.map((item) => {
        if (item?.uuid === uuid) {
          return {
            ...item,
            value: v,
          }
        } else {
          return item
        }
      })

      return newFormData
    })

    // setFormData(newFormData)
  }

  return (
    <div>
      <div className='d-flex justify-conent-between'>
        <div className='font-weight-bold'>Edit Content</div>
      </div>
      <hr></hr>
      <AvForm>
        <ListGroup>
          <ListGroupItem id='edit_text' className='editCMSMainTitle font-weight-bold mb-4'>
            Edit Text
          </ListGroupItem>
          <Collapse isOpen={isCollapseOpen === 'edit_text'}>
            {formData?.map((item, index) => {
              if (item.type === 'divider') {
                return <hr className='mt-5 mb-5' style={{ marginRight: '10vw', marginLeft: '10vw' }}></hr>
              }
              if (item.type === 'heading') {
                return (
                  <AvField
                    name={item?.uuid}
                    value={item?.value}
                    label='Heading'
                    // className='mt-5'
                    onChange={(e) => {
                      onChangeValue(item?.uuid, e.target.value)
                    }}></AvField>
                )
              }
              return (
                <div className='p-1 mt-2 mb-2'>
                  <div>
                    <CMSRichText
                      data={{ field_id: item?.uuid }}
                      id={item?.uuid}
                      title={`Text`}
                      value={item?.value}
                      onChange={(v) => {
                        onChangeValue(item?.uuid, v)
                      }}
                    />
                  </div>
                </div>
              )
            })}
          </Collapse>
        </ListGroup>
      </AvForm>
      <div className='d-flex justify-content-end mt-4'>
        <Button
          outline
          color='danger'
          className='mr-2'
          onClick={(e) => {
            e.stopPropagation()
            toggleModal()
          }}>
          Discard
        </Button>
        <Button color='info' onClick={() => onSave()}>
          Save
        </Button>
      </div>
    </div>
  )
}

export default CMSNumberPoint
