
import { cms, tidyName } from '../../../helper'

import { primary_color } from '../../../Helper/uiHelper'
import { useLocation } from 'react-router-dom'

const PublicToggle = ({
  onChange,
  field_id,
  disabled,
  search_keyword_exclude = [],
  search_limit_error = 'Please search proper keyword',
  setFormFieldValue,
  formFieldValue,
  data,
  inputPlaceholder,
  blackBorder,
  validationError,
  setValidationError,
  setFormData = () => {},
  formIndex,
  isMoveBooking,
}) => {
  // edit_booking
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const isEditing = searchParams.get('edit_booking')
  const onChangeValue = (value) => {
    if (validationError && setValidationError) setValidationError(false)

    // let updatedValue = {
    //   ...formFieldValue,
    // }
    // updatedValue[data?.field_id] = value
    // setFormFieldValue(updatedValue)
    setFormData((prevFormsData) => {
      return prevFormsData.map((item, i) => {
        if (formIndex === i) {
          let newdata = {
            ...item,
          }
          newdata[data?.field_id] = value
          return newdata
        } else {
          return item
        }
      })
    })
  }

  const value = formFieldValue?.[data?.field_id]
  const placeholder = inputPlaceholder ? inputPlaceholder : `search ${data?.cms_key ? cms(data?.cms_key) : 'something'}`

  if (data?.field_id === 'off_sale' && !formFieldValue?.previous_locker_maintenance) return
  if (data?.field_id === 'previous_locker_maintenance' && !isEditing && !isMoveBooking) return
  return (
    <>
      <div className='my-4'>
        <div style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
          <input
            checked={value}
            type='checkbox'
            id={data?.field_id}
            className='subtitle1'
            onChange={(e) => {
              onChangeValue(e.target.checked)
            }}
          />
          <label for={data?.field_id} className=' mt-2 body2' style={{ color: primary_color }}>
            {cms(data?.cms_key)}
          </label>
        </div>
      </div>
      {/* {validationError && !value && <div className='text-danger subtitle1 mt-1'>This field is required.</div>} */}
    </>
  )
}

export default PublicToggle
