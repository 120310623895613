import React, { Children } from 'react'
import { connect } from 'react-redux'
import LoadingOverlay from 'react-loading-overlay'
import Loader from 'react-loaders'
import cx from 'classnames'
import { withRouter } from 'react-router-dom'
import 'loaders.css/loaders.css'
import Routes from './Routes/routes'
import ErrorAlert from '../CMSComponent/errorAlert'
import useWindowSize from '../CustomHook/useWindowSize'
import './typography.scss'

const MainWrapper = (props) => {
  // const windowSize = useWindowSize()
  return <Main {...props}  />
}

class Main extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      closedSmallerSidebar: false,
    }
  }

  componentDidMount() {
    document.body.className = this.props.fontFamily
  }

  componentDidUpdate() {
    document.body.className = this.props.fontFamily
  }

  render() {
    const {
      // loader,
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu,
      enablePageTabsAlt,
      windowSize,
      children,
    } = this.props

    return (
      <div
        style={{ height: '10rem !important' }}
        className={cx(
          'app-container app-theme-' + colorScheme,
          { 'fixed-header': enableFixedHeader },
          { 'fixed-sidebar': enableFixedSidebar || windowSize?.width < 960 },
          { 'fixed-footer': enableFixedFooter },
          { 'closed-sidebar': enableClosedSidebar },
          { 'closed-sidebar-mobile': closedSmallerSidebar },
          { 'sidebar-mobile-open': enableMobileMenu },
          { 'body-tabs-shadow-btn': enablePageTabsAlt }
        )}>
        {children}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  // loader: state.Loader.loader,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.StaticThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
  fontFamily: state.ThemeOptions.fontFamily,
})

export default withRouter(connect(mapStateToProps)(MainWrapper))
