import { tidyName } from '../../../helper'
import moment from 'moment'
import { primary_color } from '../../../Helper/uiHelper'

const getPermissionGroupByPage = (data) => {
  let ans = {}
  data?.map((item) => {
    if (ans[item?.name]) {
      ans[item?.name]?.push(item?.permission)
    } else {
      ans[item?.name] = [item?.permission]
    }
  })
  return ans
}

const ShowDetails = ({ data, onExpand }) => {
  let grantedPermission = getPermissionGroupByPage(data?.add_permission)
  let restrictedPermission = getPermissionGroupByPage(data?.remove_permission)
  return (
    <div className='permission-table'>
      <div className='mb-2 cursor-pointer' onClick={() => onExpand()}>
        <i class='fa-sharp fa-solid fa-arrow-left  m-3'></i>
        Go Back
      </div>
      {data?.add_permission?.length > 0 && (
        <div>
          <div className={`permission-header`} style={{ color: primary_color }}>
            Permission granted
          </div>
          {Object.keys(grantedPermission).map((key) => {
            return (
              <div className='permission-group py-3'>
                <h6 className='mb-4 text text-capitalize'>{tidyName(key)}</h6>
                <div className='d-flex flex-wrap' style={{ gap: '24px' }}>
                  {grantedPermission[key]?.map((permission) => {
                    return (
                      <span
                        className={`badge badge-pill permission-block`}
                        style={{
                          backgroundColor: '#059669',
                          color: '#fff',
                        }}>
                        {permission}
                      </span>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      )}
      {data?.remove_permission?.length > 0 && (
        <div className='mt-4'>
          <div className={`permission-header`} style={{ color: primary_color }}>
            Permission Restricted
          </div>
          {Object.keys(restrictedPermission).map((key) => {
            return (
              <div className='permission-group py-3'>
                <h6 className='mb-4 text text-capitalize'>{tidyName(key)}</h6>
                <div className='d-flex flex-wrap' style={{ gap: '24px' }}>
                  {restrictedPermission[key]?.map((permission) => {
                    return (
                      <span
                        className={`badge badge-pill permission-block`}
                        style={{
                          backgroundColor: '#EAEAEA',
                        }}>
                        {permission}
                      </span>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
const UpdatedLog = ({ data, onExpand = () => {}, expandedId }) => {
  const updatedData = data?.updated_data && JSON.parse(data?.updated_data)

  // const updatedData = {
  //   role_name: 'SuperAdmin (Copy)',
  //   remove_permission: [
  //     {
  //       permisson: 'view',
  //       name: 'xyz',
  //     },
  //     {
  //       permisson: 'view',
  //       name: 'xyz',
  //     },
  //   ],
  //   add_permission: [
  //     {
  //       permisson: 'view',
  //       name: 'xyz',
  //     },
  //   ],
  // }
  if (expandedId) return <ShowDetails onExpand={onExpand} data={updatedData}></ShowDetails>
  const addPermissionPage = []
  const removePermissionPage = []

  updatedData?.add_permission?.map((item) => {
    addPermissionPage?.push(tidyName(item?.name))
  })

  updatedData?.remove_permission?.map((item) => {
    removePermissionPage?.push(tidyName(item?.name))
  })

  let text = ''
  if (addPermissionPage?.length > 0 && removePermissionPage?.length === 0) {
    text = (
      <span>
        <span className='font-weight-bold'>{data?.updated_by}</span> has granted the permission in{' '}
        <span className='font-weight-bold'>{addPermissionPage?.join(', ')}</span>
      </span>
    )
  } else if (addPermissionPage?.length == 0 && removePermissionPage?.length > 0) {
    text = (
      <span>
        <span className='font-weight-bold'>{data?.updated_by}</span> has restricted the permission in{' '}
        <span className='font-weight-bold'>{removePermissionPage?.join(', ')}</span>
      </span>
    )
  } else {
    text = (
      <span>
        <span className='font-weight-bold'>{data?.updated_by}</span> has granted the permission in{' '}
        <span className='font-weight-bold'>{addPermissionPage?.join(', ')}</span> and restricted the permission in{' '}
        <span className='font-weight-bold'>{removePermissionPage?.join(', ')}</span>
      </span>
    )
  }
  return (
    <div className='d-flex align-items-center'>
      <div>
        <i
          class='fa-solid fa-arrow-up-right-from-square mr-2 cursor-pointer'
          style={{ color: primary_color }}
          onClick={() => {
            onExpand(data?.id)
          }}></i>
      </div>
      <div style={{ flex: 1 }}>{text}</div>
      {data?.updated_at && <div className='text-muted'>{moment.utc(data?.updated_at).format('MMM D  YYYY, HH:mm')}</div>}
    </div>
  )
}

export default UpdatedLog
