import { ListGroup, ListGroupItem, Table, UncontrolledCollapse } from 'reactstrap'
import { tidyName } from '../../../helper'

const PageButton = ({ data }) => {
  const items = data
    ?.filter((item) => item?.enabled)
    ?.map((item) => item?.permission)
    ?.join(', ')
  return <div className='subtitle1'>{items}</div>
}

const PermissionOverviewPage = ({ pages }) => {
  return (
    <ListGroup className='my-3'>
      {pages &&
        Object.keys(pages)?.map((key) => {
          return (
            <>
              <ListGroupItem className='mb-1 subtitle1 cursor-pointer d-flex' style={{ background: '#00000010' }} id={key}>
                <div style={{ flex: 1 }}>{tidyName(key)}</div>
                <div>
                  {pages?.[key]?.data?.[0]?.enabled ? <span class='badge badge-success '>View</span> : <span class='badge badge-danger '>Hide</span>}
                </div>
              </ListGroupItem>
              {pages?.[key]?.data?.[0]?.enabled && (
                <UncontrolledCollapse toggler={'#' + key}>
                  <Table className='my-4'>
                    <thead>
                      <th className='subtitle1 font-weight-bold' style={{ width: '20rem' }}>
                        Name
                      </th>
                      <th className='subtitle1 font-weight-bold'>Permissions</th>
                    </thead>
                    <tbody>
                      {pages?.[key]?.pageButtons &&
                        Object.keys(pages?.[key]?.pageButtons)?.map((i) => {
                          return (
                            <tr>
                              <td className='subtitle1'>{tidyName(i)}</td>
                              <td>
                                <PageButton data={pages?.[key]?.pageButtons?.[i]?.data}></PageButton>
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </Table>
                </UncontrolledCollapse>
              )}
            </>
          )
        })}
    </ListGroup>
  )
}
export default PermissionOverviewPage
