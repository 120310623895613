import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Col, FormGroup, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap'
import DatePicker from 'react-datepicker'
import { useEffect } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import BlockReportsGlobalFilter from './blockReportFilter'
import AcademicYearGlobalFilter from './academicYearGlobalFilter'

const RenderFilterByPageName = ({ gridRelatedData }) => {
  const [selectedTimeRange, setSelectedTimeRange] = useState([])
  const savedFilters = useSelector((state) => state.CMS.savedFilters)

  const { sortBy, getBySearch, updateClearFilters, updateRefreshGrid } = gridRelatedData

  useEffect(() => {
    let mainPageName = gridRelatedData?.content?.name
    if (savedFilters && savedFilters[mainPageName] && savedFilters[mainPageName]['-filter-date']) {
      let dateRange = savedFilters[mainPageName]['-filter-date']?.split('-')
      const dateObject1 = moment(dateRange?.[0], 'YYYY/MM/DD').toDate()
      const dateObject2 = moment(dateRange?.[1], 'YYYY/MM/DD').toDate()
      setSelectedTimeRange([dateObject1, dateObject2])
    } else {
      setSelectedTimeRange([])
    }
  }, [JSON.stringify(savedFilters)])

  useEffect(() => {
    if (selectedTimeRange?.length > 1 && selectedTimeRange?.[0] && selectedTimeRange?.[1]) {
      let text = moment(selectedTimeRange?.[0]).format('YYYY/MM/DD') + '-' + moment(selectedTimeRange?.[1]).format('YYYY/MM/DD')
      getBySearch({ ['-filter-' + 'date']: text || '' }, sortBy)
    } else {
      if (gridRelatedData?.pageName === 'sales_reports_grid') {
        // updateClearFilters(true)
        // updateRefreshGrid(true)
      }
    }
  }, [selectedTimeRange])

  switch (gridRelatedData?.pageName) {
    case 'sales_reports_grid':
      return
      return (
        <Row form>
          <Col md={6}>
            <FormGroup className='global-filter'>
              <Label className='subtitle1'>Start Date</Label>
              <InputGroup className='position-relative'>
                <InputGroupAddon addonType='prepend'>
                  <div className='input-group-text'>
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </div>
                </InputGroupAddon>
                <DatePicker
                  selected={selectedTimeRange?.[0] ? new Date(selectedTimeRange?.[0]) : ''}
                  selectsStart
                  className='form-control subtitle1'
                  onChange={(start) => {
                    if (!start) {
                      setSelectedTimeRange([])
                      getBySearch({ ['-filter-' + 'date']: undefined }, sortBy)
                    } else {
                      setSelectedTimeRange([start, selectedTimeRange?.[1]])
                    }
                  }}
                  maxDate={new Date()}
                  placeholderText='Start Date'
                  isClearable={true}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup className='global-filter'>
              <Label className=' subtitle1'>End Date</Label>
              <DatePicker
                selected={selectedTimeRange?.[1] ? new Date(selectedTimeRange?.[1]) : ''}
                selectsEnd
                className='form-control subtitle1 position-relative'
                onChange={(end) => setSelectedTimeRange([selectedTimeRange?.[0], end])}
                maxDate={new Date()}
                minDate={selectedTimeRange?.[0] && new Date(selectedTimeRange?.[0])}
                placeholderText='End Date'
                isClearable={true}
              />
            </FormGroup>
          </Col>
        </Row>
      )
    case 'block_reports_grid':
      return
      return <BlockReportsGlobalFilter gridRelatedData={gridRelatedData}></BlockReportsGlobalFilter>
    // case 'offsale_reports_grid':
    // case 'off_sale_reports_grid':
    // case 'locker_maintanance_reports_grid':
    //   return <AcademicYearGlobalFilter gridRelatedData={gridRelatedData} isOnlyAcademic></AcademicYearGlobalFilter>
  }
}

const RenderGlobalFilter = ({ gridRelatedData }) => {
  return (
    <>
      <RenderFilterByPageName gridRelatedData={gridRelatedData}></RenderFilterByPageName>
    </>
  )
}

export default RenderGlobalFilter
