import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../../Helper/cookieHelper'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import { request } from '../../../Redux/Sagas/requests/api'
import moment from 'moment'
import { RiDoorLine } from 'react-icons/ri'
import { checkIsAdminOrManager } from '../../../helper'

const EditPurchase = () => {
  const [showIframe, setShowIframe] = useState(false)
  const [pageUUID, setPageUUID] = useState()
  const [isPastAcademicYear, setIsPastAcademicYear] = useState(false)

  const dispatch = useDispatch()
  const currentRow = useSelector((state) => state.CMS.currentRow)
  const navigation = useSelector((state) => state.Navigations)
  const user = useSelector((state) => state.User.user)

  let domain_id, payment_id
  if (currentRow?.[0]) {
    domain_id = currentRow?.[0]?.domain_id
    payment_id = currentRow?.[0]?.id
  }

  useEffect(() => {
    let storage_timer = getLocalStorageItem('timer') && JSON.parse(getLocalStorageItem('timer'))
    let current_storage_timer = storage_timer?.[parseInt(domain_id)]
    let bookingPage = navigation?.HeaderNav?.find((item) => item?.route === 'book-my-locker-list')
    setIsPastAcademicYear(false)
    request('get-booking-by-payment-id', 'POST', { payment_id }).then((res) => {
      const units = res?.data?.units
      const formattedDate = moment(units?.[0]?.post_time, 'DD MMM, YYYY') // Parse the input date

      if (!formattedDate.isValid()) {
        const isFutureDate = formattedDate.isAfter(moment()) // Check if the date is greater than today
        if (!isFutureDate) {
          setIsPastAcademicYear(true)
        }
      }
    })
    request(`get-page/${bookingPage?.page_uuid}`).then((res) => {
      let id = res?.data?.child_pages?.[0]?.uuid
      setPageUUID(id)

      if (current_storage_timer?.session_uuid) {
        request(`expire-reservation-session/${current_storage_timer?.session_uuid}`, 'GET').then((res) => {
          createReservation()
        })
      } else {
        createReservation()
      }
    })

    return () => {
      let storage_timer = getLocalStorageItem('timer') && JSON.parse(getLocalStorageItem('timer'))
      let current_storage_timer = storage_timer?.[parseInt(domain_id)]
      request(`expire-reservation-session/${current_storage_timer?.session_uuid}`, 'GET')
      removeLocalStorageItem('timer')
    }
  }, [])

  const createReservation = () => {
    let storage_timer = getLocalStorageItem('timer') && JSON.parse(getLocalStorageItem('timer'))
    let current_storage_timer = storage_timer?.[domain_id]

    let data = {}
    const new_session_id = 'BOOKING-SESSION-' + uuidv4()
    let payload = {
      session_key: new_session_id,
      reservation_widget: 12,
      domain_id: domain_id,
      payment_id,
    }
    let current_row_timer = {
      session_id: new_session_id,
    }
    request('create-reservation', 'POST', payload).then((res) => {
      current_row_timer = {
        ...current_row_timer,
        time: new Date().toString(),
        session_uuid: res?.data?.data?.uuid,
        duration: 1800,
      }
      data[domain_id] = current_row_timer
      setLocalStorageItem('timer', JSON.stringify(data))
      setShowIframe(true)
      // let interval = setInterval(() => {
      //   setTimeLeft((prevTimeLeft) => {
      //     if (prevTimeLeft <= 0) {
      //       request(`expire-reservation-session/${current_storage_timer?.session_uuid}`, 'GET')
      //       clearInterval(interval)
      //       onTimeExpire()
      //       return null
      //     }
      //     return prevTimeLeft - 1
      //   })
      // }, 1000)
    })
  }
  let link = `${window.location.origin}/book-my-locker-list?parent_id=${domain_id}&page_id=${pageUUID}&isShowOnlyContent=true&payment_id=${payment_id}&edit_booking=true`
  // if (currentRow?.[0]?.customer_email !== user?.email && checkIsAdminOrManager(user?.role?.name)) {
  if (checkIsAdminOrManager(user?.role?.name)) {
    link = `${window.location.origin}/book-my-locker-list?parent_id=${domain_id}&page_id=${pageUUID}&isShowOnlyContent=true&payment_id=${payment_id}&edit_booking=true&is_admin=true`
  }
  if (!showIframe) return

  if (isPastAcademicYear) {
    return (
      <div className='d-flex flex-column justify-content-center align-items-center'>
        <div>
          {/* <i class='fa-thin fa-door-closed' style={{ fontSize: '5rem' }}></i> */}
          <div>
            <svg width='169' height='168' viewBox='0 0 169 168' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M166.609 137.671C171.993 147.003 165.235 158.667 154.483 158.667H14.5158C3.74254 158.667 -2.98388 146.985 2.38921 137.671L72.3735 16.3296C77.7597 6.99365 91.2501 7.01057 96.6267 16.3296L166.609 137.671ZM84.5001 112.584C77.0903 112.584 71.0834 118.591 71.0834 126.001C71.0834 133.41 77.0903 139.417 84.5001 139.417C91.9099 139.417 97.9168 133.41 97.9168 126.001C97.9168 118.591 91.9099 112.584 84.5001 112.584ZM71.7621 64.3581L73.9257 104.025C74.0269 105.881 75.5617 107.334 77.4205 107.334H91.5797C93.4385 107.334 94.9733 105.881 95.0745 104.025L97.238 64.3581C97.3474 62.3532 95.7511 60.6673 93.7433 60.6673H75.2566C73.2488 60.6673 71.6528 62.3532 71.7621 64.3581Z'
                fill='#EAEAEA'
              />
            </svg>
          </div>
        </div>
        <div className='body2'>You can't edit the booking for past academic year</div>
      </div>
    )
  }
  return (
    <div className='p-5' style={{ marginLeft: '-2rem' }}>
      <iframe style={{ height: '80vh', width: '100%', border: 'none' }} src={link} title='description'></iframe>
    </div>
  )
}

export default EditPurchase
