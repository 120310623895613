import Loader from 'react-loaders'

import { useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import { request } from '../../../Redux/Sagas/requests/api'
import { Button } from 'reactstrap'

const PaymentRequestPreview = ({ session_key, payment_id, extraPaymentAmount, setIsPaymentRequestPreviewOpen }) => {
  const [templatePreview, setTemplatePreview] = useState()
  const currentRow = useSelector((state) => state.CMS.currentRow)
  let uuid = currentRow?.[currentRow?.length - 1]?.uuid

  const [iframeHeight, setIframeHeight] = useState('0px')

  const iframeRef = useRef(null)
  const updateIframeHeight = () => {
    const iframe = iframeRef.current
    const iframeDoc = iframe.contentDocument || iframe.contentWindow.document
    setIframeHeight(`${iframeDoc.body.scrollHeight}px`)
  }

  useEffect(() => {
    const additionalPayment = extraPaymentAmount?.map((item) => {
      return {
        transaction_type_id: item?.name?.value,
        amount: parseInt(item?.amount),
      }
    })
    request('payment-preview', 'POST', { payment_id, session_key, additional_payment: additionalPayment }).then((res) => {
      if (res.data) {
        setTemplatePreview(res.data)
      }
    })
  }, [])
  useEffect(() => {
    if (iframeRef.current) {
      const iframeDoc = iframeRef.current.contentDocument
      iframeDoc.open()
      iframeDoc.write(templatePreview)
      iframeDoc.close()

      iframeRef.current.addEventListener('load', updateIframeHeight)
      updateIframeHeight()
    }
    return () => {
      if (iframeRef.current) {
        iframeRef.current.removeEventListener('load', updateIframeHeight)
      }
    }
  }, [templatePreview, iframeRef.current])

  return (
    <div>
      <div>
        <Button
          color='dark'
          className='mb-4'
          onClick={() => {
            setIsPaymentRequestPreviewOpen(false)
            // setIsPaymentRequestPreviewOpen(false)
          }}>
          {' '}
          <i class='fa-sharp fa-regular fa-arrow-left'></i>
        </Button>
      </div>
      {templatePreview && <iframe ref={iframeRef} style={{ width: '100%', height: iframeHeight, border: 'none' }} title='HTML Renderer' />}

      {/* <div dangerouslySetInnerHTML={{ __html: html }}></div> */}
    </div>
  )
}

export default PaymentRequestPreview
