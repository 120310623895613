import React, { Fragment } from 'react'
import HeroBanner from '../Widget/HeroBanner/HeroBanner'
import PolicyBlock from '../Widget/NumberList/PolicyBlock'
import useMediaQuery from '../../CustomHook/useMediaQuery'
import './terms.scss'

const TermsConditions = ({ handleClose }) => {
  const isDesktop = useMediaQuery('(min-width:768px)')

  const data = [
    {
      title: 'Lorem Ipsum dolor sit amet',
      policies: [
        'lorem ipsum dolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua lorem ipsumdolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua',
        'lorem ipsum dolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua lorem ipsumdolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua',
        'lorem ipsum dolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua lorem ipsumdolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua',
      ],
    },
    {
      title: 'Lorem Ipsum dolor sit amet',
      policies: [
        'lorem ipsum dolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua lorem ipsumdolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua',
        'lorem ipsum dolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua lorem ipsumdolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua',
        'lorem ipsum dolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua lorem ipsumdolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua',
      ],
    },
  ]

  return (
    <Fragment>
      <div
        className='mt-5 position-relative terms-condition-privacy-policy'
        style={{ border: '1px solid #8E8E8E', borderTopRightRadius: '10px', marginRight: '0.6rem', width: '98%', height: '95%' }}>
        <i
          className='fa fa-times-circle position-absolute'
          onClick={handleClose}
          style={{ color: '#F87171', cursor: 'pointer', fontSize: 18, right: -10, top: -10 }}></i>
        <iframe
          src='/terms-and-condition-list?isShowOnlyContent=true&hideHeroBanner=true'
          style={{ width: '98%', height: '100%', border: '0px', marginTop: '1rem', borderRadius: '10px' }}></iframe>
      </div>
    </Fragment>
  )
}

export default TermsConditions
