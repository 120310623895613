import { useSelector } from 'react-redux'
import IconSet from '../../CMSComponent/icon'
import RoleBadge from './RoleBadge'

const RoleBlock = ({ isActive, text, onClickRole, id, is_guest, is_default, onCopyRole }) => {
  const isManagePermissionRoleEnabled = useSelector((state) => state.User?.user?.cmsSettingPermission?.add_role)?.[0]?.enabled
  return (
    <div
      className={`d-flex justify-content-between w-12 subtitle1 role position-relative ${isActive && 'active-role'}`}
      onClick={() => {
        onClickRole(id)
      }}>
      {!!is_guest && <RoleBadge bgColor={'#059669'} text={'Guest'} isOverLapping={true} />}
      {!!is_default && <RoleBadge bgColor={'#FFCE00'} text={'Default'} isOverLapping={true} />}

      {text}
      {isManagePermissionRoleEnabled && (
        <i
          className='far fa-copy body2 ml-3'
          style={{ color: '#049FDB' }}
          onClick={() => {
            onCopyRole(id)
          }}></i>
      )}

      {/* <IconSet
        icon='far fa-copy'
        color='#049FDB'
        onClick={() => {
          onCopyRole(id)
        }}
      /> */}
    </div>
  )
}

export default RoleBlock
