export const ticketResponseTimeOptions = [
  {
    value: '6 Hrs',
    label: '6 Hrs',
  },
  {
    value: '12 Hrs',
    label: '12 Hrs',
  },
  {
    value: '18 Hrs',
    label: '18 Hrs',
  },
  {
    value: '1 Days',
    label: '1 Days',
  },
  {
    value: '2 Days',
    label: '2 Days',
  },
  {
    value: '3 Days',
    label: '3 Days',
  },
]
