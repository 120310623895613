import moment from 'moment'

export const checkIsFutureDate = (date) => {
  return date.isAfter(moment())
}

export const checkIsPastDate = (date) => {
  return moment().isAfter(date)
}

export const checkCurrentDateBetween = (date1, date2) => {
  return moment().isAfter(date1) && date2.isAfter(moment())
}

export const convertDateInLocalTimezone = (utcDateString) => {
  return moment.utc(utcDateString).local()
}
