import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { request } from '../../Redux/Sagas/requests/api'

const fetchRoleWisePermission = async (role_id) => {
  const response = await request(`/view-all-permission/${role_id}`, 'GET')
  return response.data
}

export const useFetchRoleWisePermission = (role_id) => {
  const dispatch = useDispatch()

  const query = useQuery(['role-wise-permission' + role_id], () => fetchRoleWisePermission(role_id), {
    enabled: !!role_id,
    onSuccess: (data) => {},
  })

  return {
    data: query.data,
    error: query.error,
    isLoading: query.isLoading,
    isSuccess: query.isSuccess,
    isError: query.isError,
    refetchPermission: query.refetch,
  }
}
