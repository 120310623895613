import moment from 'moment'
import { primary_color } from '../../../Helper/uiHelper'

const ShowDifference = ({ data, original_data, updated_data, onExpand }) => {
  return (
    <div>
      <div className='permission-table'>
        <div className='mb-2 cursor-pointer' onClick={() => onExpand()}>
          <i class='fa-sharp fa-solid fa-arrow-left  m-3'></i>
          Go Back
        </div>
        <div className={`permission-header`} style={{ color: primary_color }}>
          New Design
        </div>
        <div className='p-5 mb-2' dangerouslySetInnerHTML={{ __html: updated_data?.html ? updated_data?.html : '' }}></div>
        <div className={`permission-header`} style={{ color: primary_color }}>
          Old design
        </div>
        <div className='p-5 mb-2' dangerouslySetInnerHTML={{ __html: original_data?.html ? original_data?.html : '' }}></div>
      </div>
    </div>
  )
}

const WidgetDesginUpdate = ({ data, onExpand, expandedId }) => {
  let updated_data = data?.updated_data && JSON.parse(data?.updated_data)
  let original_data = data?.original_data && JSON.parse(data?.original_data)

  if (expandedId) {
    return <ShowDifference data={data} updated_data={updated_data} original_data={original_data} onExpand={onExpand}></ShowDifference>
  }
  return (
    <div className='d-flex align-items-center'>
      <div>
        <i
          class='fa-solid fa-arrow-up-right-from-square mr-2 cursor-pointer'
          style={{ color: primary_color }}
          onClick={() => {
            onExpand(data?.id)
          }}></i>
      </div>
      <div style={{ flex: 1 }}>
        <span className='font-weight-bold'>{data?.updated_by}</span>
        {` has updated the widget `}
        <span className='font-weight-bold'>{updated_data?.name}</span>
      </div>
      {data?.updated_at && <div>{moment.utc(data?.updated_at).format('MMM D  YYYY, HH:mm')}</div>}
    </div>
  )
}

export default WidgetDesginUpdate
