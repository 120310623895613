import { Button } from 'reactstrap'
import { useLockerYearContext } from '../../../Context/lockerYearContext'
import { primary_color } from '../../../Helper/uiHelper'
import { useDispatch, useSelector } from 'react-redux'
import { request } from '../../../Redux/Sagas/requests/api'
import { useEffect, useRef, useState } from 'react'
import { fetchUpdateApiData } from '../../../Redux/Reducers/CMS'

const GenerateBlockAndRate = () => {
  const { state: lockerYearState, updateState: updateLockerYearState } = useLockerYearContext()

  const intervalRef = useRef()

  const currentRow = useSelector((state) => state.CMS.currentRow)
  const updateApiData = useSelector((state) => state.CMS?.updateApiData)


  const dispatch = useDispatch()

  const [loading, setLoading] = useState()

  useEffect(() => {
    if (updateApiData?.block_generating && updateApiData?.block_generating?.includes(currentRow?.[0]?.id)) {
      setLoading(true)
      if (!intervalRef.current) {
        callUpdateApi()
      }
    } else if (loading) {
      setLoading(false)
    }
  }, [updateApiData])

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])

  const callUpdateApi = () => {
    intervalRef.current = setInterval(() => {
      dispatch(fetchUpdateApiData())
    }, 30000)
  }

  const generateBlock = () => {
    setLoading(true)
    request('renewal-locations/generate-block-and-rates', 'POST', { id: currentRow?.[0]?.id, regenerate: lockerYearState?.is_block_created }).then(
      (res) => {
        callUpdateApi()
      }
    )
  }

  return (
    <div>
      <Button
        color='primary'
        style={{ background: primary_color }}
        disabled={loading}
        onClick={() => {
          generateBlock()
        }}>
        <>
          {!lockerYearState?.is_block_created ? 'Generate Block and Rates' : 'Regenerate Block and Rates'}{' '}
          {loading && <i class='fa-solid fa-spinner-third fa-spin'></i>}
        </>
      </Button>

      <div className='my-2'>
        <hr></hr>
      </div>

      <div>
        <div className='body1 font-weight-bold mb-2 mt-2' style={{ color: primary_color }}>
          You must complete these actions in order to schedule Renewal
        </div>
        <div className='mt-2 d-flex align-items-center'>
          <div
            style={{ background: lockerYearState?.is_block_created ? primary_color : '#F87171', borderRadius: '5px', padding: '0rem 0.6rem' }}
            className='mr-3'>
            {lockerYearState?.is_block_created ? (
              <i class='fa-solid fa-check text-white subtitle1'></i>
            ) : (
              <i class='fa-solid fa-xmark text-white'></i>
            )}
          </div>
          <label className='mt-2 subtitle1' style={{ color: lockerYearState?.is_block_created ? primary_color : '#F87171' }}>
            Codes Generated
          </label>
        </div>

        <div className='mt-2 d-flex align-items-center'>
          <div
            style={{ background: lockerYearState?.rate_approved ? primary_color : '#F87171', borderRadius: '5px', padding: '0rem 0.6rem' }}
            className='mr-3'>
            {lockerYearState?.rate_approved ? <i class='fa-solid fa-check text-white subtitle1'></i> : <i class='fa-solid fa-xmark text-white'></i>}
          </div>
          <label className='mt-2 subtitle1' style={{ color: lockerYearState?.rate_approved ? primary_color : '#F87171' }}>
            Approve Rates
          </label>
        </div>
      </div>
      <div className='my-2'>
        <hr></hr>
      </div>
    </div>
  )
}

export default GenerateBlockAndRate
