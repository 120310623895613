import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AsyncPaginate } from 'react-select-async-paginate'
import { useLocation } from 'react-router-dom'

import { cms } from '../../../helper'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import { request } from '../../../Redux/Sagas/requests/api'
import './roundedInput.scss'
import { primary_color } from '../../../Helper/uiHelper'
import { getDependDataPayload } from '../../../CMSComponent/Forms/Fields/select'
import usePrevious from '../../../CustomHook/usePrevious'

const checkIsFieldRequired = (data) => {
  if (data?.field_id === 'item_type_id') {
    return false
  } else {
    return true
  }
}

const RoundedSelect = ({
  onChange,
  field_id,
  validationError,
  setValidationError,
  search_keyword_exclude = [],
  search_limit_error = 'Please search proper keyword',
  setFormFieldValue,
  formFieldValue,
  data,
  parentData,
  form,
  setFormData,
  formIndex,
  depend_field_source_table,
}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const parent_id = searchParams.get('parent_id') || parentData?.id

  const dependent_value = formFieldValue?.[data?.component_call_data_connection?.source_field]

  const [paginationData, setPaginationData] = useState({
    pageNo: 1,
    hasMore: true,
  })
  const [selectedOption, setSelectedOption] = useState()
  const prev_dependent_value = usePrevious(dependent_value)
  useEffect(() => {
    if (data?.component_call_data_connection.component_name === 'dependent_data') {
      if (prev_dependent_value !== dependent_value && prev_dependent_value) {
        setSelectedOption(null)
        // let updatedValue = {
        //   ...formFieldValue,
        // }
        // updatedValue[data?.field_id] = null
        // setFormFieldValue(updatedValue)
        setFormData((prevFormsData) => {
          return prevFormsData.map((item, i) => {
            if (formIndex === i) {
              let newdata = {
                ...item,
              }
              newdata[data?.field_id] = null
              return newdata
            } else {
              return item
            }
          })
        })
        setPaginationData({
          pageNo: 1,
          hasMore: true,
        })
      }
    }
  }, [dependent_value])
  useEffect(() => {
    if (data?.cms_key === 'field.year_session' && !formFieldValue[data?.field_id]) {
      loadOptions().then((res) => {
        if (res && res.options) {
          onChangeValue(res?.options[0])
          setSelectedOption(res?.options[0])

          setPaginationData({
            pageNo: 1,
            hasMore: true,
          })
        }
      })
    }
  }, [])
  useEffect(() => {
    if (selectedOption?.value !== formFieldValue[data?.field_id] && formFieldValue[data?.field_id]) {
      let label = formFieldValue[`${data?.field_id}_label`] || formFieldValue[data?.field_id]
      setSelectedOption({ label: label, value: formFieldValue[data?.field_id] })
    }
  }, [JSON.stringify(formFieldValue)])

  const onChangeValue = (selectedValue) => {
    if (validationError && setValidationError) setValidationError(false)
    // let updatedValue = {
    //   ...formFieldValue,
    // }
    // updatedValue[data?.field_id] = selectedValue?.value
    // setFormFieldValue(updatedValue)
    setFormData((prevFormsData) => {
      return prevFormsData.map((item, i) => {
        if (formIndex === i) {
          let newdata = {
            ...item,
          }
          newdata[data?.field_id] = selectedValue?.value
          return newdata
        } else {
          return item
        }
      })
    })
    setSelectedOption(selectedValue)
  }

  async function loadOptions(search, loadedOptions) {
    let payload = {
      page: paginationData?.pageNo ? paginationData?.pageNo : 1,
      fields: data?.component_call_data_connection?.fields,
      // columns: [data?.field_id],
      // uuid: form?.uuid
      source_table: data?.component_call_data_connection?.source_table,
    }
    const parent_required = ['location_id']
    if ((parentData?.id && parent_required?.includes(data?.component_call_data_connection?.source_table)) || true) {
      let parentid
      if (parentData?.id) {
        parentid = parseInt(parentData?.id)
      }
      payload = {
        ...payload,
        top_parent_table_row_id: parentid,
        top_parent_table: 'domains',
      }
    }

    if (data?.component_call_data_connection.component_name === 'dependent_data') {
      if (!dependent_value) {
        // dispatch(setNotification({ type: 'error', message: 'Please select Student Year Group first.' }))
        return {
          options: [],
          hasMore: false,
        }
      }
      let depend_payload = getDependDataPayload(
        form?.component_call_fields,
        data?.component_call_data_connection,
        dependent_value,
        depend_field_source_table
      )

      payload = {
        ...payload,
        ...depend_payload,
      }
    }
    if (payload?.field_data === 'category.blocks') {
      payload = {
        ...payload,
        item_date_time_id: formFieldValue?.item_date_time_id,
      }
    }

    const response = await request('get-template-data', 'POST', { ...payload })
    const options = response?.data?.data?.data?.map((item) => {
      if (item?.active) {
        setSelectedOption({
          value: item?.id,
          label: item?.name,
        })
      }
      return {
        value: item?.id,
        label: item?.name,
      }
    })
    setPaginationData({
      pageNo: response?.data?.data?.current_page + 1,
      hasMore: !response?.data?.data?.next_page_url ? false : true,
    })
    return {
      options: options,
      hasMore: !response?.data?.data?.next_page_url ? false : true,
    }
  }

  const value = formFieldValue?.[data?.field_id]
  const placeholder = `search ${data?.cms_key ? cms(data?.cms_key) : 'something'}`
  if (data?.field_id === 'item_type_id' && !formFieldValue?.previous_locker_maintenance) return
  return (
    <>
      <div className='body2' style={{ color: primary_color, marginBlock: '2rem 0.65rem' }}>
        {data?.cms_key && cms(data?.cms_key)}
      </div>
      <div className='rounded-select form-select' id='validationCustom04' required>
        <AsyncPaginate
          value={selectedOption ? selectedOption : null}
          loadOptions={loadOptions}
          isSearchable={false}
          onChange={(val) => onChangeValue(val)}
          cacheUniqs={[dependent_value]}
          classNamePrefix='rounded-select'
          styles={{
            menu: (base) => ({ ...base, zIndex: 15 }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderRadius: '50px',
              borderColor: validationError && !selectedOption ? 'red' : baseStyles.borderColor,
            }),
            placeholder: (baseStyles, state) => ({
              ...baseStyles,
              color: validationError && !selectedOption ? 'red' : 'rgba(0, 0, 0, 0.2)',
            }),
          }}
          isDisabled={data?.component_call_data_connection.component_name === 'dependent_data' && !dependent_value}
          // isMulti={true}
          placeholder={data?.place_holder || 'Select option'}
        />
      </div>
      {validationError && checkIsFieldRequired(data) && !selectedOption ? (
        <div className=' text-danger subtitle1 mt-1'>This field is required.</div>
      ) : (
        <></>
      )}
      {data?.cms_sub_heading ? <div className=' mt-2 ml-1'>{data?.cms_sub_heading}</div> : <></>}
    </>
  )
}

export default RoundedSelect
